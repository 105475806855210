import { set, get, remove } from 'shared/utils/local-storage/local-storage';
import Cookies from 'js-cookie';

const STORAGE_KEY = 'mybaReduxStore';

export const SET_FROM_STORAGE = 'SET_FROM_STORAGE';
const VERSION = 1;

export const loadState = () => {
  const state = get(STORAGE_KEY);
  if (!state) return undefined;
  const { version, ...rest } = state;
  if (version !== VERSION) {
    remove(STORAGE_KEY);
    return undefined;
  }
  return rest;
};

export const saveState = (state) => {
  let updatedState = {
    version: VERSION
  };

  const { configurations, user } = state;

  if (!Cookies.getJSON('masquerading_as') && configurations.features) {
    const { features } = configurations;
    updatedState = {
      ...updatedState,
      configurations: {
        features
      }
    };
  }

  if (user) {
    const { plan, email, isVegetarian, planApiName } = user;
    updatedState = {
      ...updatedState,
      user: { plan, email, isVegetarian, planApiName }
    };
  }

  set(STORAGE_KEY, updatedState);
};

export const setFromStorage = () => ({
  type: SET_FROM_STORAGE,
  payload: loadState() || {}
});
