import { SET_COPY } from './copy-actions';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COPY: {
      return {
        ...state,
        [action.payload.flow]: action.payload.copy
      };
    }
    default: {
      return state;
    }
  }
};
