import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const initiateCheckoutForAgoraCart = (cartId, setPaymentInfo, setShippingInfo, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client.mutate({
    mutation: gql`
      mutation InitiateCheckoutForMarketCart($input: InitiateCheckoutForCartInput!) {
        initiateCheckoutForCart(input: $input) {
          cart {
            ...MarketCartFields
          }
          clientErrors {
            message
          }
        }
      }
      ${MARKET_CART_FIELDS}
    `,
    variables: {
      input: {
        cartId,
        setPaymentInfo,
        setShippingInfo,
        ownerId
      }
    }
  })
  .then((result) => {
    const { data: { initiateCheckoutForCart } } = result;
    const { clientErrors, cart } = initiateCheckoutForCart;
    return { cart, clientErrors };
  });
  /* eslint-enable prettier/prettier */
};

export default initiateCheckoutForAgoraCart;
