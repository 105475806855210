import { fetch, makeAbsolute } from 'services/fetch';

const fetchConfigurations = (session) => {
  return makeAbsolute(fetch)('/api/configurations?app_type=web', session).then((response) => response.configurations);
};

const fetchRegions = (session) => {
  return fetch('/api/configurations/regions', session).then((response) => response.configurations.regions);
};

export { fetchConfigurations as default, fetchConfigurations, fetchRegions };
