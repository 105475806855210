import Cookies from 'js-cookie';

import amplitudeHander from '../ba-client/handlers/amplitude';
import googleAnalyticsHandler from '../ba-client/handlers/google-analytics';
import optimizelyHandler from '../ba-client/handlers/optimizely';

const getAttributeDefaults = () => ({
  mobile_app: Cookies.get('mobile_app') || 'false',
  mobile_web: Cookies.get('isMobileWeb') || 'false',
  page_name: window.location.pathname,
  page_title: document.title
});

const trackEvent = (event_) => {
  const { exclusionList = [], attributes = {}, ...rest } = event_;

  const event = {
    ...rest,
    attributes: {
      ...getAttributeDefaults(),
      ...attributes
    }
  };

  const ENDPOINT_HANDLERS = {
    GA: googleAnalyticsHandler,
    AMPLITUDE: amplitudeHander,
    OPTIMIZELY: optimizelyHandler
  };

  exclusionList.forEach((exclusionKey) => {
    delete ENDPOINT_HANDLERS[exclusionKey.toUpperCase()];
  });

  Object.values(ENDPOINT_HANDLERS).forEach((handler) => {
    handler(event);
  });
};

export default trackEvent;
