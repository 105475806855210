import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const fetchAgoraCart = (cartId, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client.query({
    query: gql`
      query MarketCartContents($cartId: ID!, $ownerId: CartOwnerIdInput) {
        cart(id: $cartId, ownerId: $ownerId) {
          ...MarketCartFields
        }
      }
      ${MARKET_CART_FIELDS}
    `,
    variables: {
      cartId,
      ownerId
    },
    fetchPolicy: 'no-cache'
  })
  .then((result) => {
    const { data: { cart } } = result;
    return { cart };
  });
  /* eslint-enable prettier/prettier */
};

export default fetchAgoraCart;
