import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const fetchActiveAgoraCarts = (cartContext, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client.query({
    query: gql`
      query ActiveMarketCarts($cartContext: CartContext!, $ownerId: CartOwnerIdInput) {
        activeCarts(cartContext: $cartContext, ownerId: $ownerId) {
          ...MarketCartFields
        }
      }
      ${MARKET_CART_FIELDS}
    `,
    variables: {
      cartContext,
      ownerId
    },
    fetchPolicy: 'no-cache'
  })
  .then((result) => {
    return result.data.activeCarts;
  });
  /* eslint-enable prettier/prettier */
};

export default fetchActiveAgoraCarts;
