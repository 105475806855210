import Cookie from 'js-cookie';

export const SITE_TEST_PARTICIPATED = 'site_test_participated';
export const SITE_TESTS = 'site_tests';

export const getSiteTestParticipated = () => {
  // All the web site tests the user is in before user persisted to BE
  const variations = Cookie.get(SITE_TEST_PARTICIPATED);
  return variations ? variations.split(',') : [];
};

export const getActiveSiteTests = () => {
  // If user persisted to BE, this is all active site tests user is not in
  // else all active site tests
  const siteTests = Cookie.get(SITE_TESTS);
  return siteTests ? siteTests.split('&') : [];
};

export default {
  getSiteTestParticipated,
  getActiveSiteTests,
  SITE_TEST_PARTICIPATED,
  SITE_TESTS
};
