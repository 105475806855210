import Cookies from 'js-cookie';

import fetch from 'services/api/plans';
import camelize from 'shared/utils/camelize';
import parseError from 'shared/utils/error-handling/parse-errors';
import { getPlansByIdWithPreferences } from 'shared/utils/plans';

import { setDefaultPlan, setPlans, setPlansLastFetchedParams } from './plans-actions';

export const PRICING_AB_TEST_NAME = '2023 New User Pricing';

// eslint-disable-next-line import/prefer-default-export
export const fetchPlans = ({ setDefault = true, visibilityLevel } = {}) => {
  return async (dispatch, getState) => {
    const { session, plans, address } = getState();
    let { plansById } = plans;
    const { zip } = address;
    const coupon = Cookies.get('coupon_code');
    const { lastFetchedZip, lastFetchedCoupon } = getState().plans.plansLastFetchedParams;

    // Don't refetch plans unless zip or coupon code change
    if (lastFetchedZip === zip && lastFetchedCoupon === coupon) {
      return Promise.resolve();
    }

    dispatch(setPlansLastFetchedParams(zip, coupon));

    const optimizelyExperiments =
      window.optimizely &&
      typeof window.optimizely.get === 'function' &&
      window.optimizely.get('state').getExperimentStates();
    const pricingExperiement =
      optimizelyExperiments &&
      Object.values(optimizelyExperiments).find((exp) => exp.experimentName === PRICING_AB_TEST_NAME);

    return fetch(session, {
      coupon_code: coupon,
      include: 'display-variations',
      'filter[visibility-level]': visibilityLevel,
      optimizely_variation_id: pricingExperiement?.variation?.id
    })
      .then((response) => {
        plansById = getPlansByIdWithPreferences(camelize(response));
        dispatch(setPlans(plansById));

        if (setDefault) {
          // Usually we only want to do this if we're fetching plans
          // for the first time
          dispatch(setDefaultPlan());
        }
        return Promise.resolve();
      })
      .catch((response) => {
        return Promise.reject(parseError(response));
      });
  };
};
