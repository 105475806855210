import formatEventName from './lib/event-name';

const optimizely = ({ category, action, attributes = {} }) => {
  if (!window.optimizely) return;

  window.optimizely.push({
    type: 'event',
    eventName: formatEventName(category, action),
    tags: {
      ...attributes,
      category
    }
  });
};

export default optimizely;
