import store from 'redux/store';

import { hasFeature } from 'redux/reducers/configurations';

import OptimizelyWrapper from './optimizely-wrapper';

/*
  getSessionServerVariations
  Returns all the site test variations a session-user is in.
  This is only relevant for site tests where a user has not been created yet
*/
const getSessionServerVariations = () => {
  const state = store.getState();
  const siteTests = {};
  const EMAIL_PASSWORD_HOLDBACK_VARIATION = 'email_password_holdback';

  const sessionServerVariations = {
    [EMAIL_PASSWORD_HOLDBACK_VARIATION]: hasFeature(state, EMAIL_PASSWORD_HOLDBACK_VARIATION)
  };
  // With site test variations bucketed on the session
  // we only expose the variation ID
  Object.entries(sessionServerVariations || {}).forEach(([variationId, hasFeatureBool]) => {
    if (hasFeatureBool) {
      siteTests[variationId] = {
        id: variationId,
        name: undefined
      };
    }
  });

  return siteTests;
};

/*
  getUserServerVariations
  Adds the active user site tests to the site tests hash.
  Need to normalize this data to resemble optimizely v2
  since we don't have the experiment ID (which is the key), we just duplicate
  the variation ID. This only works because we only read for the site_test user property
  https://developers.optimizely.com/x/solutions/javascript/reference/index.html#function_getvariationmap
*/
const getUserServerVariations = () => {
  const state = store.getState();
  let siteTests = {};

  const activeUserSiteTestVariations =
    state.configurations.services && state.configurations.services.site_test_variations;

  if (activeUserSiteTestVariations) {
    siteTests = Object.values(activeUserSiteTestVariations || {}).reduce(
      (acc, { variation_id, variation_name }) => ({
        ...acc,
        [variation_id]: {
          id: variation_id,
          name: variation_name
        }
      }),
      {}
    );
  }

  return siteTests;
};

/* getEnabledTestsMap
  Returns all enabled tests, including non-optimizely tests. Essentially
  combines FE and BE powered tests
*/
const getEnabledTestsMap = () => {
  const siteTests = new OptimizelyWrapper().getOptimizelyMap();
  return { ...siteTests, ...getSessionServerVariations(), ...getUserServerVariations() };
};

export default getEnabledTestsMap;
