import { fetch, Session } from 'services/fetch';
import { PLAN_IDS } from 'shared/constants/plans';

const fetchWindows = (zip: String, planId: Number = PLAN_IDS.TWO_PERSON, session: Session) => {
  return fetch(`/api/windows?zip_code=${zip}&plan_id=${planId}`, session).then((response) => ({
    windows: response.windows,
    defaultWindow: response.meta.default_window_id
  }));
};

export default fetchWindows;
