import { fetchConfigurations as fetchConfigurationsApi, fetchRegions } from 'services/api/configurations';

import { setConfigurations, setRegions } from './configurations-actions';

export const fetchConfigurations = () => (dispatch, getState) => {
  const { session } = getState();

  return fetchConfigurationsApi(session)
    .then((configurations) => {
      dispatch(setConfigurations(configurations));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const loadRegions = () => async (dispatch) => {
  const regions = await fetchRegions();
  dispatch(setRegions(regions));
  return regions;
};
