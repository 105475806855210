export const SET_CONFIGURATIONS = 'SET_CONFIGURATIONS';
export const SET_OPTIMIZELY_EXPERIMENTS = 'SET_OPTIMIZELY_EXPERIMENTS';
export const SET_REGIONS = 'SET_REGIONS';

export const setConfigurations = (configurations) => {
  return {
    type: SET_CONFIGURATIONS,
    payload: configurations
  };
};

export const setRegions = (regions) => {
  return {
    type: SET_REGIONS,
    payload: regions
  };
};

export const setOptimizelyExperiments = (experiments) => {
  return {
    type: SET_OPTIMIZELY_EXPERIMENTS,
    payload: experiments
  };
};
