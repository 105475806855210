import * as Cookie from 'js-cookie';

const STORAGE_KEY = 'user_session_id_gtm';

export const getUserSessionIdForGTMCookie = () => {
  const cookie = Cookie.get(STORAGE_KEY);
  return cookie;
};

export const setUserSessionIdForGTMCookie = () => {
  const timeStamp = new Date().getTime();
  Cookie.set('user_session_id_gtm', timeStamp, { expires: 1 / 48, path: '/' }); // expires in 30 minutes
};

export const getOrSetUserSessionIdForGTMCookie = () => {
  const cookie = getUserSessionIdForGTMCookie();
  if (!cookie) {
    setUserSessionIdForGTMCookie();
    return getUserSessionIdForGTMCookie();
  }

  return cookie;
};
