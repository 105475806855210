import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';

// eslint-disable-next-line import/prefer-default-export
const finalizeAgoraCart = (cartId, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client
    .mutate({
      mutation: gql`
        mutation FinalizeCart($input: FinalizeCartInput!) {
          finalizeCart(input: $input) {
            order {
              id
              guestToken
              price {
                discount {
                  displayValue
                }
                estimated
                shipping {
                  displayValue
                }
                subtotal {
                  displayValue
                }
                tax {
                  displayValue
                }
                total {
                  displayValue
                }
              }
              lineItems {
                quantity
                variant {
                  core
                  description
                  displayPrice {
                    formattedPrice
                  }
                  displayPriority
                  name {
                    full
                  }
                  price {
                    original {
                      displayValue
                    }
                  }
                  sku
                  variableValues {
                    displayValue
                    value
                    variable {
                      description
                      displayName
                      name
                      type
                    }
                  }
                }
              }
            }
            clientErrors {
              message
            }
          }
        }
      `,
      variables: {
        input: {
          cartId,
          ownerId
        }
      }
    })
    .then((result) => {
      const {
        data: { finalizeCart }
      } = result;
      const { clientErrors, order } = finalizeCart;
      return { order, clientErrors };
    });
  /* eslint-enable prettier/prettier */
};

export default finalizeAgoraCart;
