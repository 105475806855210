export const screenSmMin = 768;
export const screenMdMin = 962;
export const screenLgMin = 1200;

export const screenSmMax = screenSmMin - 1;
export const screenMdMax = screenMdMin - 1;
export const screenLgMax = screenLgMin - 1;

// this is breakpoint which is not supported by `pomelo`.
// However we use it in `my-blueapron`, `blueapron.com` and `pomegranate-ui`.
// Probably makes sense to add the same breakpoint in `pomelo`
export const screenXsMin = 469;
export const screenXsMax = screenXsMin - 1;
