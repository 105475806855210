import Cookies from 'js-cookie';

import { setToast } from 'components/common/Toast';
import reportToRollbar from 'shared/utils/error-handling/report-to-rollbar/report-to-rollbar';
import segmentTypewriter from '../../../analytics/segment';
import { CLIENT_TYPE } from './constants';

segmentTypewriter.setTypewriterOptions({
  onViolation: (message, violations) => {
    const type = 'Typewriter JSON Schema Validation Error';

    const msg = JSON.stringify(
      {
        type,
        description: `You made an analytics call (${message.event}) using Typewriter that doesn't match the Tracking Plan spec.`,
        errors: violations
      },
      undefined,
      2
    );

    console.error(msg);

    if (window.SEGMENT_VIOLATION_TOAST_ENABLED) {
      setToast(`Segment: ${type}. See console for further details.`, 'warn');
    }
  }
});

const handler = {
  get(target, property) {
    const origMethod = target[property];
    if (typeof origMethod === 'function') {
      return function (...args) {
        try {
          return origMethod.apply(this, args);
        } catch (error) {
          reportToRollbar(error);
          console.error(`Error in method ${property}:`, error);

          return undefined;
        }
      };
    }

    return origMethod;
  }
};

// Proxy that tries and catches for all methods of Segment Typewriter
const typewriter = new Proxy(segmentTypewriter, handler);

export { typewriter };

export const getClientType = () => {
  if (Cookies.get('mobile_view')) return CLIENT_TYPE.mobileApp;

  if (Cookies.get('isMobileWeb')) return CLIENT_TYPE.mobileWeb;

  return CLIENT_TYPE.desktop;
};

export const performTrack = ({ event, properties }) => {
  if (!window.analytics) return new Promise(() => {});

  return window.analytics.track(event, {
    client: getClientType(),
    ...properties
  });
};

export const performIdentify = ({ userId, traits }) => {
  if (!window.analytics) return;

  window.analytics.identify(userId, {
    ...traits
  });
};

export const reset = () => {
  if (!window.analytics) return;

  window.analytics.reset();
};
