import { v4 as uuidv4 } from 'uuid';
import { startRequest, completeRequest, failRequest } from 'redux/reducers/requests';
import { showError, DISPLAY_METHODS } from 'redux/reducers/notices';
import parseError from 'shared/utils/error-handling/parse-errors';
import { performTrack } from 'shared/utils/segment/utils';
import { TRACK_EVENTS, CHECKOUT_TYPES } from 'shared/utils/segment/constants';
import { GIFT_CART_CONTEXT, MARKET_CONTEXT } from 'shared/constants/constants';
import { getCartFromCookies, setCartInCookies, deleteCartCookies } from 'shared/utils/cookie/cart';
import { set } from 'shared/utils/local-storage/local-storage';
import { MARKET_ORDER_KEY } from 'components/market/common/constants';
import createAgoraCart from 'services/api/graphql/cart/gifts/create-agora-cart';

import {
  addVariantsToAgoraCart,
  advanceCheckoutForAgoraCart,
  applyCouponToAgoraCart,
  claimAgoraCart,
  finalizeAgoraCart,
  fetchActiveAgoraCarts,
  fetchAgoraCart,
  initiateCheckoutForAgoraCart,
  removeVariantsFromAgoraCart,
  setGiftInfoForAgoraCart,
  setPaymentInfoForAgoraCart,
  setShippingInfoForAgoraCart,
  reduxSelectShippingRatesForCart,
  formatCartForState
} from './utilities';

export const SET_CART = 'SET_CART';
// INFO: used on upcoming page with nori
// export const RESET_CART = 'RESET_CART';

export const initialState = {
  [GIFT_CART_CONTEXT]: {},
  [MARKET_CONTEXT]: {}
};

export default (state: {} = initialState, action) => {
  switch (action.type) {
    case SET_CART: {
      return {
        ...state,
        [action.payload.context]: action.payload.cart
      };
    }
    default: {
      return state;
    }
  }
};

export const setCart = (context, cart) => ({
  type: SET_CART,
  payload: {
    context,
    cart
  }
});

// INFO: used on upcoming page with nori
// export const resetCart = (context) => ({
//   type: RESET_CART,
//   payload: {
//     context
//   }
// });

export const fetchActiveCarts = (context) => {
  const key = 'fetchActiveCarts';

  return (dispatch, getState) => {
    dispatch(startRequest(key));

    // Don't include guestToken if request is already authenticated
    const userHasNoAccount = !getState().user.id;

    const cartCookies = getCartFromCookies(context);
    let owner;

    if (userHasNoAccount) {
      if (cartCookies.token) {
        owner = { guestToken: cartCookies.token };
      } else {
        // No identifier with which to fetch active carts
        dispatch(completeRequest(key));
        return Promise.resolve();
      }
    }

    return fetchActiveAgoraCarts(context, owner)
      .then((carts) => {
        // Select the most recent active cart
        const cart = carts[0] || {};

        cart.id ? setCartInCookies(context, cart.id, cart.guestToken) : deleteCartCookies(context);

        dispatch(setCart(context, formatCartForState(cart, context)));
        dispatch(completeRequest(key));

        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Carts fetch failed';

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const claimCart = (context, ownerEmail) => {
  const key = 'claimCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    const userHasAccount = !!getState().user.id;
    const cartId = state.carts[context].id;
    const cartToken = state.carts[context].guestToken;
    const owner = { guestToken: cartToken };

    // Don't include ownerEmail if request is already authenticated
    const claimCartArgs = userHasAccount ? [] : [ownerEmail];

    return claimAgoraCart(context, cartId, owner, ...claimCartArgs)
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          dispatch(showError(key, clientErrors[0].message, DISPLAY_METHODS.FLASH));
          return Promise.resolve();
        }
        // If no cart is found, the APIs return successful with a null cart
        const claimedCart = cart || {};

        if (!claimedCart.id) {
          deleteCartCookies(context);
          dispatch(setCart(context, formatCartForState({})));
          return Promise.reject();
        }

        dispatch(setCart(context, formatCartForState(cart, context)));
        dispatch(completeRequest(key));

        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Cart claim failed';
        deleteCartCookies(context);

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const fetchCart = (context) => {
  const key = 'fetchCart';

  return (dispatch, getState) => {
    dispatch(startRequest(key));

    const userHasAccount = !!getState().user.id;
    const cartCookies = getCartFromCookies(context);

    const owner = { guestToken: cartCookies.token };

    if (!cartCookies.id) {
      if (userHasAccount) {
        dispatch(completeRequest(key));
        return fetchActiveCarts(context)(dispatch, getState);
      }

      dispatch(setCart(context, formatCartForState({})));
      dispatch(completeRequest(key));
      return Promise.resolve();
    }

    // If we have identifying information, we always want to make sure that
    // it has been associated with the cart
    const fetchMethod = userHasAccount ? claimAgoraCart : fetchAgoraCart;

    return fetchMethod(context, cartCookies.id, owner)
      .then(({ cart }) => {
        // If no cart is found, the APIs return successful with a null cart
        if (!cart.id) {
          deleteCartCookies(context);

          if (userHasAccount) {
            return fetchActiveCarts(context)(dispatch, getState);
          }

          // We have no cart and no additional identifying information
          dispatch(setCart(context, formatCartForState({})));
          dispatch(completeRequest(key));
          return Promise.resolve();
        }

        dispatch(setCart(context, formatCartForState(cart, context)));
        dispatch(completeRequest(key));

        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Cart fetch failed';
        deleteCartCookies(context);

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
          dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

          return Promise.reject(parseError(response));
        }

        return fetchActiveCarts(context)(dispatch, getState);
      });
  };
};

export const addVariantsToCart = (context, variants) => {
  const key = 'addVariantsToCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    // Don't include guestToken if request is already authenticated
    const userHasAccount = !!state.user.id;
    const cartId = state.carts[context].id;
    const cartToken = state.carts[context].guestToken;

    let owner;
    if (!userHasAccount && cartToken) owner = { guestToken: cartToken };

    if (cartId) {
      return addVariantsToAgoraCart(context, cartId, variants, owner)
        .then(({ cart, clientErrors }) => {
          if (clientErrors && clientErrors.length) {
            dispatch(showError(key, clientErrors[0].message, DISPLAY_METHODS.FLASH));
            return Promise.resolve();
          }
          dispatch(setCart(context, formatCartForState(cart, context)));
          dispatch(completeRequest(key));

          return Promise.resolve();
        })
        .catch((response) => {
          const defaultErrorText = 'Cart addition failed';

          if (!response) {
            dispatch(showError(key, defaultErrorText));

            return Promise.reject(defaultErrorText);
          }
          if (response.status >= 500) {
            dispatch(showError(key, defaultErrorText));
          }

          dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

          return Promise.reject(parseError(response));
        });
    }

    // Clients are responsible for generating their idempotency token
    const idempotencyToken = uuidv4();

    // We need to create a cart with initial variants
    return createAgoraCart(context, variants, idempotencyToken, owner)
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          dispatch(showError(key, clientErrors[0].message, DISPLAY_METHODS.FLASH));
          return Promise.resolve();
        }
        setCartInCookies(context, cart.id, cart.guestToken);
        dispatch(setCart(context, formatCartForState(cart, context)));
        dispatch(completeRequest(key));

        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Cart addition failed';

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const removeVariantsFromCart = (context, variants, overrideId = undefined) => {
  const key = 'removeVariantsFromCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    // Don't include guestToken if request is already authenticated
    const userHasNoAccount = !state.user.id;
    const cartId = overrideId || state.carts[context].id;
    const cartGuestToken = state.carts[context].guestToken;

    let owner;
    if (userHasNoAccount && cartGuestToken) owner = { guestToken: cartGuestToken };

    return removeVariantsFromAgoraCart(context, cartId, variants, owner)
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          dispatch(showError(key, clientErrors[0].message, DISPLAY_METHODS.FLASH));
          return Promise.resolve();
        }
        dispatch(setCart(context, formatCartForState(cart, context)));
        dispatch(completeRequest(key));

        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Cart removal failed';

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const initiateCheckoutForCart = (context, autofillPayment, autofillShipping) => {
  const key = 'initiateCheckoutForCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    const userHasNoAccount = !state.user.id;
    const cartId = state.carts[context].id;
    const cartGuestToken = state.carts[context].guestToken;
    const cartCheckoutState = state.carts[context].checkoutState;

    let owner;
    if (userHasNoAccount && cartGuestToken) owner = { guestToken: cartGuestToken };

    let initiateOrAdvance;
    let args;

    if (cartCheckoutState === 'NONE') {
      initiateOrAdvance = initiateCheckoutForAgoraCart;
      args = context === GIFT_CART_CONTEXT ? [owner] : [autofillPayment, autofillShipping, owner];
    } else {
      initiateOrAdvance = advanceCheckoutForAgoraCart;
      args = [owner];
    }

    return initiateOrAdvance(context, cartId, ...args)
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          dispatch(showError(key, clientErrors[0].message, DISPLAY_METHODS.FLASH));
          return Promise.resolve();
        }
        if (!cart) {
          return Promise.reject();
        }
        dispatch(setCart(context, formatCartForState(cart, context)));
        dispatch(completeRequest(key));

        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Gift cart initialization failed';

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const applyCouponToCart = (context, couponCode) => {
  const key = 'applyCouponToCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    // Don't include guestToken if request is already authenticated
    const userHasAccount = !!state.user.id;
    const cartId = state.carts[context].id;
    const cartGuestToken = state.carts[context].guestToken;
    let owner;
    if (!userHasAccount && cartGuestToken) owner = { guestToken: cartGuestToken };

    return applyCouponToAgoraCart(context, cartId, couponCode, owner)
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          const errorMessage = clientErrors[0].message;

          performTrack({
            event: TRACK_EVENTS.couponDeclined,
            properties: {
              coupon_code: couponCode,
              coupon_error: errorMessage
            }
          });

          dispatch(showError(key, errorMessage, DISPLAY_METHODS.FLASH));
          return Promise.resolve();
        }
        if (!cart) {
          return Promise.reject();
        }
        dispatch(setCart(context, formatCartForState(cart, context)));
        dispatch(completeRequest(key));
        performTrack({
          event: TRACK_EVENTS.couponApplied,
          properties: {
            coupon_code: couponCode,
            coupon_amount: parseFloat(cart.promotions.at(-1).amount.displayValue),
            coupon_type: CHECKOUT_TYPES.marketplace
          }
        });

        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Applying cart coupon code failed';

        performTrack({
          event: TRACK_EVENTS.couponDeclined,
          properties: {
            coupon_code: couponCode,
            coupon_error: defaultErrorText
          }
        });

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const setShippingInfoForCart = (context, shippingAddress) => {
  const key = 'setShippingInfoForCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    // Don't include guestToken if request is already authenticated
    const userHasAccount = !!state.user.id;
    const cartId = state.carts[context].id;
    const cartGuestToken = state.carts[context].guestToken;
    let owner;
    if (!userHasAccount && cartGuestToken) owner = { guestToken: cartGuestToken };

    return setShippingInfoForAgoraCart(context, cartId, shippingAddress, owner)
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          dispatch(showError(key, clientErrors[0].message, DISPLAY_METHODS.FLASH));
          return Promise.resolve();
        }
        if (!cart) return Promise.reject();

        dispatch(setCart(context, formatCartForState(cart, context)));
        dispatch(completeRequest(key));

        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Updating cart shipping info failed';

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const selectShippingRatesForCart = (
  context,
  selectedShippingRates
  // INFO: used on upcoming page with nori
  // showRevertToStandardShippingMsg,
  // refreshSchedulePage
) => {
  const key = 'setShippingRatesForCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    const cartId = state.carts[context].id;

    return reduxSelectShippingRatesForCart(context, cartId, selectedShippingRates, null)
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          return Promise.reject({ errors: { message: clientErrors[0]?.message } });
        }

        if (!cart) return Promise.reject();
        // INFO: used on upcoming page with nori
        // dispatch(resetCart(FOOD_CART_CONTEXT));
        // if (refreshSchedulePage) {
        //   dispatch(setRefreshSchedulePageData());
        // }

        dispatch(completeRequest(key));
        // INFO: used on upcoming page with nori
        // if (showRevertToStandardShippingMsg) {
        //   dispatch(setRevertToStandardShippingMsg(RESET_TO_STANDARD_SHIPPING_MSG));
        // }
        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Updating cart shipping rates failed';

        if (!response) {
          dispatch(showError(key, defaultErrorText));

          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const checkoutGiftCart = ({
  senderName,
  senderEmail,
  recipientName,
  recipientEmail,
  message,
  billingAddress,
  recaptchaToken,
  stripePaymentMethodId,
  handleTracking
}) => {
  const key = 'checkoutGiftCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    // Don't include guestToken if request is already authenticated
    const userHasAccount = !!state.user.id;
    const cartId = state.carts[GIFT_CART_CONTEXT].id;
    const cartGuestToken = state.carts[GIFT_CART_CONTEXT].guestToken;
    let owner;
    if (!userHasAccount && cartGuestToken) owner = { guestToken: cartGuestToken };

    // If the user is not authenticated, we can now claim the cart with the provided email.
    // Otherwise, the cart is already claimed with no need to perform the call.
    const ensureCartClaimed = () => {
      if (owner) return claimAgoraCart(GIFT_CART_CONTEXT, cartId, owner, senderEmail);

      return Promise.resolve({ cart: state.carts[GIFT_CART_CONTEXT] });
    };
    let defaultErrorText = 'Gift cart checkout failed';
    return ensureCartClaimed()
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          defaultErrorText = clientErrors[0].message;
          return Promise.reject();
        }
        if (!cart) return Promise.reject();
        const newCart = owner ? formatCartForState(cart, GIFT_CART_CONTEXT) : cart;

        dispatch(setCart(GIFT_CART_CONTEXT, newCart));
        return setGiftInfoForAgoraCart(
          GIFT_CART_CONTEXT,
          cartId,
          senderName,
          recipientName,
          recipientEmail,
          message,
          owner
        );
      })
      .then(({ clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          defaultErrorText = clientErrors[0].message;
          return Promise.reject();
        }
        // No need to update redux cart, it doesn't contain any gift info
        return setPaymentInfoForAgoraCart(GIFT_CART_CONTEXT, {
          cartId,
          billingAddress,
          recaptchaToken,
          ownerId: owner,
          stripePaymentMethodId
        });
      })
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          defaultErrorText = clientErrors[0].message;
          return Promise.reject();
        }
        if (!cart) return Promise.reject();

        dispatch(setCart(GIFT_CART_CONTEXT, formatCartForState(cart, GIFT_CART_CONTEXT)));

        // Only attempt to finalize the cart if the cart is ready to be finalized
        if (cart.checkoutState !== 'CONFIRM') return Promise.reject();

        return finalizeAgoraCart(GIFT_CART_CONTEXT, cartId, owner);
      })
      .then(({ order, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          defaultErrorText = clientErrors[0].message;
          return Promise.reject();
        }

        if (!order) return Promise.reject();

        // By deleting the cookies, the next fetch will be a generic fetch for the user's carts.
        // This cart is no longer active, and so will not be returned.
        dispatch(setCart(GIFT_CART_CONTEXT, formatCartForState({})));
        deleteCartCookies(GIFT_CART_CONTEXT);
        handleTracking(order);

        dispatch(completeRequest(key));
        return Promise.resolve({ order: formatCartForState(order, GIFT_CART_CONTEXT) });
      })
      .catch((response) => {
        if (!response) {
          dispatch(showError(key, defaultErrorText, DISPLAY_METHODS.FLASH));
          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      });
  };
};

export const checkoutMarketCart = ({
  senderName,
  senderEmail,
  recipientName,
  recipientEmail,
  message,
  billingAddress,
  dateOfBirth,
  stripeToken,
  paymentMethodId,
  recaptchaToken,
  handleNavigate,
  handleTracking
}) => {
  const key = 'checkoutMarketCart';

  return (dispatch, getState) => {
    const state = getState();
    dispatch(startRequest(key));

    let newCart = state.carts[MARKET_CONTEXT];

    // Don't include guestToken if request is already authenticated
    const userHasAccount = !!state.user.id;
    const cartId = newCart.id;
    const cartGuestToken = newCart.guestToken;
    let owner;
    if (!userHasAccount && cartGuestToken) owner = { guestToken: cartGuestToken };

    // If the user is not authenticated, we can now claim the cart with the provided email.
    // Otherwise, the cart is already claimed with no need to perform the call.
    const ensureCartClaimed = () => {
      if (owner && owner.guestToken) return claimAgoraCart(MARKET_CONTEXT, cartId, owner, senderEmail);

      return Promise.resolve({ cart: newCart });
    };
    let defaultErrorText = 'Market cart checkout failed';

    return ensureCartClaimed()
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          defaultErrorText = clientErrors[0].message;
          return Promise.reject();
        }
        if (!cart) return Promise.reject();
        newCart = owner ? formatCartForState(cart, MARKET_CONTEXT) : cart;

        if (message) {
          return setGiftInfoForAgoraCart(
            MARKET_CONTEXT,
            cartId,
            senderName,
            recipientName,
            recipientEmail,
            message,
            owner
          );
        }

        return Promise.resolve();
      })
      .then(() => {
        // No need to update redux cart, it doesn't contain any gift info
        if (paymentMethodId || stripeToken) {
          return setPaymentInfoForAgoraCart(MARKET_CONTEXT, {
            cartId,
            billingAddress,
            recaptchaToken,
            ownerId: owner,
            stripePaymentMethodId: paymentMethodId
          });
        }
        return Promise.resolve({ cart: newCart, clientErrors: null });
      })
      .then(({ cart, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          defaultErrorText = clientErrors[0].message;
          return Promise.reject();
        }
        if (!cart) return Promise.reject();
        newCart = stripeToken || paymentMethodId ? formatCartForState(cart, MARKET_CONTEXT) : cart;

        // Only attempt to finalize the cart if the cart is ready to be finalized
        if (cart.checkoutState !== 'CONFIRM') return Promise.reject();
        const complianceInfo = dateOfBirth ? { dateOfBirth } : null;

        return finalizeAgoraCart(MARKET_CONTEXT, cartId, complianceInfo, owner);
      })
      .then(({ order, clientErrors }) => {
        if (clientErrors && clientErrors.length) {
          defaultErrorText = clientErrors[0].message;
          return Promise.reject();
        }
        if (!order) return Promise.reject();
        handleTracking(order);

        // By deleting the cookies, the next fetch will be a generic fetch for the user's carts.
        // This cart is no longer active, and so will not be returned.
        newCart = formatCartForState({});
        deleteCartCookies(MARKET_CONTEXT);

        dispatch(completeRequest(key));

        set(MARKET_ORDER_KEY, { id: order.id });
        handleNavigate();
        return Promise.resolve(formatCartForState(order, MARKET_CONTEXT));
      })
      .catch((response) => {
        if (!response) {
          dispatch(showError(key, defaultErrorText, DISPLAY_METHODS.FLASH));
          return Promise.reject();
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }

        dispatch(failRequest({ key, statusCode: response.status, errors: parseError(response) }));

        return Promise.reject(parseError(response));
      })
      .finally(() => {
        dispatch(setCart(MARKET_CONTEXT, newCart));
      });
  };
};
