import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const setShippingInfoForAgoraCart = (cartId, shippingAddress, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client.mutate({
    mutation: gql`
      mutation SetShippingInfoForMarketCart($input: SetShippingInfoForCartInput!) {
        setShippingInfoForCart(input: $input) {
          cart {
            ...MarketCartFields
          }
          clientErrors {
            message
          }
        }
      }
      ${MARKET_CART_FIELDS}
    `,
    variables: {
      input: {
        cartId,
        shippingAddress,
        ownerId
      }
    }
  })
  .then((result) => {
    const { data: { setShippingInfoForCart } } = result;
    const { clientErrors, cart } = setShippingInfoForCart;
    return { cart, clientErrors };
  });
  /* eslint-enable prettier/prettier */
};

export default setShippingInfoForAgoraCart;
