import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const removeVariantsFromAgoraCart = (cartId, variants, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client
    .mutate({
      mutation: gql`
        mutation RemoveVariantsFromMarketCart($input: RemoveVariantsFromCartInput!) {
          removeVariantsFromCart(input: $input) {
            cart {
              ...MarketCartFields
            }
            clientErrors {
              message
            }
          }
        }
        ${MARKET_CART_FIELDS}
      `,
      variables: {
        input: {
          cartId,
          variants,
          ownerId
        }
      }
    })
    .then((result) => {
      return result.data.removeVariantsFromCart;
    });
  /* eslint-enable prettier/prettier */
};

export default removeVariantsFromAgoraCart;
