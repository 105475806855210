// @flow

import { registerUser as registerUserApi } from 'services/api/user';
import { showError, removeNotice } from 'redux/reducers/notices';
import { startRequest, completeRequest, failRequest } from 'redux/reducers/requests';

export const CREATE_SESSION = 'CREATE_SESSION';
export const INVALIDATE_SESSION = 'INVALIDATE_SESSION';

const initialState = {
  authenticated: false,
  email: null,
  token: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SESSION: {
      let email = null;
      let token = null;

      if (action.payload) {
        token = action.payload.token || state.token;
        email = action.payload.email || state.email;
      }

      return {
        ...state,
        authenticated: true,
        token,
        email
      };
    }
    case INVALIDATE_SESSION: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const createSession = (session?: {| token: string, email: string |}) => {
  return {
    type: CREATE_SESSION,
    payload: session
  };
};

export const invalidateSession = () => {
  return {
    type: INVALIDATE_SESSION
  };
};

export const registerUser = (user: { email: string, password: string, zip: string }) => {
  const key = 'createSession';
  const { email, password, zip } = user;
  return (dispatch: Function) => {
    dispatch(startRequest(key));
    dispatch(removeNotice('createSession'));

    return registerUserApi({ email, password, zip })
      .then((session) => {
        dispatch(completeRequest(key));
        dispatch(
          createSession({
            token: session.resource.authentication_token,
            email
          })
        );
      })
      .catch((response) => {
        dispatch(
          failRequest({
            key,
            statusCode: response.status,
            errors: response.errors
          })
        );

        if (response.errors && response.errors.base) {
          response.errors.base.forEach((error) => {
            dispatch(showError(key, error));
          });
        }

        if (response.status >= 500) {
          dispatch(
            showError(
              key,
              'Something horrible has happened.' // TODO: i18n this somewhere
            )
          );
        }
      });
  };
};
