import pick from 'lodash/pick';

import {
  fetchUser as fetch,
  updateUser as updateUserApi,
  registerUser as registerUserApi,
  fetchExpiringCredits as fetchExpiringCreditsApi
} from 'services/api/user';
import { fetchCredits as fetchCreditsApi } from 'services/api/credits';
import fetchCreditBalanceApi from 'services/api/graphql/credits/fetch-credit-balance';
import { startRequest, completeRequest, failRequest } from 'redux/reducers/requests';
import { createSession, invalidateSession } from 'redux/reducers/session';
import { showError } from 'redux/reducers/notices';
import { SET_FROM_STORAGE } from 'redux/store/initial-state/storage';
import { trackForgottenPassword, trackMultipleAddresses } from 'shared/utils/tracking-utils/registration';
import { getCouponToBeApplied } from 'shared/utils/coupons';
import parseError from 'shared/utils/error-handling/parse-errors';
import { FOOD, WINE } from 'shared/constants/constants';

import { isUserInWineRegistration } from './user-selectors';

export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER = 'SET_USER';
export const SET_PLAN_FOR_USER = 'SET_PLAN_FOR_USER';
export const SET_PLAN_QUANTITY_FOR_USER = 'SET_PLAN_QUANTITY_FOR_USER';
export const SET_PLAN_PREFERENCES_FOR_USER = 'SET_PLAN_PREFERENCES_FOR_USER';
export const SET_IS_VEGETARIAN_FOR_USER = 'SET_IS_VEGETARIAN_FOR_USER';
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';
export const SET_IS_WINE_REGISTRATION_FOR_USER = 'SET_IS_WINE_REGISTRATION_FOR_USER';
export const SET_PLAN_AND_QUANTITY_FOR_USER = 'SET_PLAN_AND_QUANTITY_FOR_USER';
export const SET_USER_CANCELED = 'SET_USER_CANCELED';
export const SET_USER_ACTIVE = 'SET_USER_ACTIVE';
export const SET_WELLNESS = 'SET_WELLNESS';
export const SET_DIETARY_PROGRAM = 'SET_DIETARY_PROGRAM';
export const UPDATE_USER_SUBSCRIPTIONS = 'UPDATE_USER_SUBSCRIPTIONS';
export const SET_CREDITS = 'SET_CREDITS';
export const SET_CREDIT_BALANCE = 'SET_CREDIT_BALANCE';

// Expiring Credits Actions
export const FETCH_EXPIRING_CREDITS_SUCCESS = 'FETCH_EXPIRING_CREDITS_SUCCESS';
export const FETCH_EXPIRING_CREDITS_ERROR = 'FETCH_EXPIRING_CREDITS_ERROR';

export const initialState = {
  credits: null,
  isVegetarian: false,
  planApiName: 'two_person',
  plan: {
    id: undefined,
    quantity: undefined
  },
  subscriptions: [],
  expiringCredits: {},
  wellness: null,
  dietaryProgram: null,
  creditBalance: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        ...action.payload.user
      };
    }
    case LOGOUT_USER: {
      return {
        ...initialState
      };
    }
    case SET_PLAN_FOR_USER: {
      const id = action.payload === undefined ? undefined : String(action.payload);

      return {
        ...state,
        plan: {
          id,
          quantity: state.plan.quantity,
          preferences: state.plan.preferences
        }
      };
    }
    case SET_PLAN_QUANTITY_FOR_USER: {
      const quantity = action.payload === undefined ? undefined : String(action.payload);

      return {
        ...state,
        plan: {
          id: state.plan.id,
          quantity,
          preferences: state.plan.preferences
        }
      };
    }
    case SET_PLAN_PREFERENCES_FOR_USER: {
      return {
        ...state,
        plan: {
          id: state.plan.id,
          quantity: state.plan.quantity,
          preferences: action.payload
        }
      };
    }
    case SET_IS_VEGETARIAN_FOR_USER: {
      return {
        ...state,
        isVegetarian: action.payload
      };
    }
    case SET_USER_PREFERENCES: {
      return {
        ...state,
        planApiName: action.payload
      };
    }
    case SET_WELLNESS: {
      return {
        ...state,
        wellness: action.payload
      };
    }
    case SET_DIETARY_PROGRAM: {
      return {
        ...state,
        dietaryProgram: action.payload
      };
    }
    case SET_IS_WINE_REGISTRATION_FOR_USER: {
      return {
        ...state,
        isWineRegistration: action.payload
      };
    }
    case SET_PLAN_AND_QUANTITY_FOR_USER: {
      return {
        ...state,
        plan: {
          id: String(action.payload.planId),
          quantity: String(action.payload.quantity),
          preferences: state.plan.preferences
        }
      };
    }
    case SET_USER_CANCELED: {
      return {
        ...state,
        is_canceled: true,
        is_active: false
      };
    }
    case SET_USER_ACTIVE: {
      return {
        ...state,
        is_canceled: false,
        is_active: true
      };
    }
    case UPDATE_USER_SUBSCRIPTIONS: {
      const { data, subscriptionId } = action.payload;

      return {
        ...state,
        subscriptions: Object.values(state.subscriptions).reduce((acc, subscription) => {
          if (subscription.id === subscriptionId) {
            return [...acc, { ...subscription, ...data }];
          }
          return [...acc, subscription];
        }, [])
      };
    }
    case SET_FROM_STORAGE: {
      return action.payload.user ? { ...state, ...action.payload.user } : state;
    }
    case FETCH_EXPIRING_CREDITS_SUCCESS: {
      return {
        ...state,
        expiringCredits: action.payload
      };
    }
    case FETCH_EXPIRING_CREDITS_ERROR: {
      return {
        ...state,
        expiringCredits: {
          error: action.payload
        }
      };
    }
    case SET_CREDITS: {
      return {
        ...state,
        credits: action.payload
      };
    }
    case SET_CREDIT_BALANCE: {
      return {
        ...state,
        creditBalance: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

/*
  Selectors
*/
// TODO: refactor these into the user-selectors

export const isUserPersisted = (state) => {
  return Boolean(state.user && state.user.id);
};

export const getIncompletedAddresses = (user) => {
  if (user && user.addresses) {
    return user.addresses.filter((address) => !address.is_complete);
  }
  return [];
};

export const isUserVegetarian = (state) => {
  if (!state.user || !state.user.is_strictly_vegetarian) {
    return undefined;
  }

  return state.user.is_strictly_vegetarian;
};

export const isUserEmployee = (state) => {
  if (!state.user || !state.user.employee) {
    return undefined;
  }

  return state.user.employee;
};

export const hasUserCard = (state) => {
  if (state.user) {
    const { card_type, card_last_four } = state.user;
    return card_type && card_last_four;
  }

  return false;
};

export const paymentUserExists = (state) => {
  if (!state.user || !state.user.payment_info) {
    return undefined;
  }

  return state.user.payment_info.payment_method_exists;
};

export const getPreferredAddress = (state) => {
  const { addresses = [] } = state.user;
  return addresses.find((address) => address.is_preferred);
};

export const getPreferredAddressOrDefault = (state, defaultValue = {}) => {
  return getPreferredAddress(state) || defaultValue;
};

export const requireCard = (state) => {
  const { coupons, plans, subscription } = state;
  const coupon = getCouponToBeApplied(coupons);
  const { plansById } = plans;
  const isWineRegistration = isUserInWineRegistration(state);
  if (!coupon || coupon.restrictions.card) {
    return true;
  }
  const activeSubscription = subscription[isWineRegistration ? WINE : FOOD];
  const selectedPlanId = activeSubscription.plan_id;
  const productsPerDelivery = activeSubscription.products_per_delivery;
  const plan = plansById[selectedPlanId] && plansById[selectedPlanId].options[productsPerDelivery];
  if (plan) {
    const firstWeekTotal = plan.price.first_week_total;

    // if they owe us money they better pay up!
    const owedMoney = firstWeekTotal > 0;
    if (owedMoney) {
      return true;
    }
  } else {
    // default to true
    return true;
  }

  return false;
};

/*
  Action Creators
*/

export const logoutUser = () => {
  return {
    type: LOGOUT_USER
  };
};

export const setUserCanceled = () => {
  return {
    type: SET_USER_CANCELED,
    payload: false
  };
};

export const setUserActive = () => {
  return {
    type: SET_USER_ACTIVE,
    payload: false
  };
};

export const resetUserIsVegetarian = () => {
  return {
    type: SET_IS_VEGETARIAN_FOR_USER,
    payload: false
  };
};

export const resetUserPlanQuantity = () => {
  return {
    type: SET_PLAN_QUANTITY_FOR_USER
  };
};

export const setUserIsVegetarian = (isVegetarian, event) => {
  return {
    type: SET_IS_VEGETARIAN_FOR_USER,
    payload: isVegetarian,
    meta: { event }
  };
};

export const setUserPreferences = (planApiName, event) => {
  return {
    type: SET_USER_PREFERENCES,
    payload: planApiName,
    meta: { event }
  };
};

export const setWellness = (wellness, event) => {
  return {
    type: SET_WELLNESS,
    payload: wellness,
    meta: { event }
  };
};

export const setUserDietaryProgram = (dietaryProgram, event) => {
  return {
    type: SET_DIETARY_PROGRAM,
    payload: dietaryProgram,
    meta: { event }
  };
};

export const setUserIsInWineRegistration = (isWineRegistration, event) => {
  return {
    type: SET_IS_WINE_REGISTRATION_FOR_USER,
    payload: isWineRegistration,
    meta: { event }
  };
};

export const setUserPlanQuantity = (selectedPlanQuantity, event) => {
  return {
    type: SET_PLAN_QUANTITY_FOR_USER,
    payload: selectedPlanQuantity,
    meta: { event }
  };
};

export const setUserPlanPreferences = (selectedPreferences, event) => {
  return {
    type: SET_PLAN_PREFERENCES_FOR_USER,
    payload: selectedPreferences,
    meta: { event }
  };
};

export const setUserPlanType = (selectedPlanTypeId, event) => {
  return {
    type: SET_PLAN_FOR_USER,
    payload: selectedPlanTypeId,
    meta: { event }
  };
};

export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: { user }
  };
};

export const setPlanAndQuantityForUser = (planId, quantity) => ({
  type: SET_PLAN_AND_QUANTITY_FOR_USER,
  payload: {
    planId,
    quantity
  }
});

export const setExpiringCredits = (payload) => ({
  type: FETCH_EXPIRING_CREDITS_SUCCESS,
  payload
});

export const setExpiringCreditsError = (payload) => ({
  type: FETCH_EXPIRING_CREDITS_ERROR,
  payload
});

export const updateUserSubscriptions = (subscriptionId, data) => ({
  type: UPDATE_USER_SUBSCRIPTIONS,
  payload: { subscriptionId, data }
});

export const setCredits = (credits) => ({
  type: SET_CREDITS,
  payload: credits
});

export const setCreditBalance = (creditBalance) => ({
  type: SET_CREDIT_BALANCE,
  payload: creditBalance
});

// Thunks

export const fetchUser = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { session } = state;

    return fetch(session)
      .then((user) => {
        dispatch(setUser(user));
        dispatch(createSession());

        const incompletedAddresses = getIncompletedAddresses(user);
        if (incompletedAddresses.length > 1) {
          trackMultipleAddresses(incompletedAddresses);
        }
      })
      .catch(() => {
        dispatch(invalidateSession());
      });
  };
};

export const updateUser = (options) => {
  const key = 'updateUser';

  return (dispatch, getState) => {
    dispatch(startRequest(key));
    const { user } = getState();
    const payload = options ? pick(user, options) : user;

    return updateUserApi(payload)
      .then((userData) => {
        dispatch(setUser(userData));
        dispatch(completeRequest(key));
        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'User PUT failed';
        if (!response) {
          dispatch(showError(key, defaultErrorText));
          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }
        dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

        return Promise.reject(parseError(response));
      });
  };
};

export const registerUser = (user) => {
  const key = 'registerUser';

  return (dispatch) => {
    dispatch(startRequest(key));

    return registerUserApi(user)
      .then((response) => {
        dispatch(setUser(pick(response.resource, 'id')));
        dispatch(completeRequest(key));
        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'User POST failed';
        if (!response) {
          dispatch(showError(key, defaultErrorText));
          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }
        dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

        if (response.errors.email && response.errors.email[0].match(/has already been taken/)) {
          const message = `An account using ${user.email} already exists. If this is your email, please try a different password below or <a href="/users/password/new">reset it.</a>`;
          response.errors.email = [message];
          trackForgottenPassword(user);
        }

        return Promise.reject(parseError(response));
      });
  };
};

export const fetchExpiringCredits = () => async (dispatch) => {
  try {
    const data = await fetchExpiringCreditsApi();
    dispatch(setExpiringCredits(data));
  } catch (e) {
    dispatch(setExpiringCreditsError(e));
  }
};

export const fetchCredits = () => async (dispatch, getState) => {
  const { session } = getState();
  const defaultErrorText = 'Credits fetch failed';

  try {
    const credits = await fetchCreditsApi(session);
    await dispatch(setCredits(credits));
  } catch (e) {
    dispatch(showError('fetchCredits', defaultErrorText));
  }
};

export const fetchCreditBalance = () => async (dispatch, getState) => {
  const { session } = getState();
  const defaultErrorText = 'Total Credits fetch failed';

  try {
    const totalCredits = await fetchCreditBalanceApi(session);
    await dispatch(setCreditBalance(totalCredits));
  } catch (e) {
    dispatch(showError('fetchCreditBalance', defaultErrorText));
  }
};

export const refreshCredits = () => fetchCredits();
