// @flow

import { fetch, apiPost, apiPut } from 'services/fetch';

const createAddress = (address) => {
  return apiPost('/api/users/addresses', { address });
};

const createSubscriptionAddress = (subscription_id, address) => {
  return apiPost(`/api/subscriptions/${subscription_id}/addresses`, {
    address
  });
};

const updateAddress = (address) => {
  return apiPut(`/api/users/addresses/${address.id}`, {
    address
  });
};

const validateAddress = (address) => {
  return fetch('/api/users/addresses/validate', undefined, undefined, address);
};

const createTemporaryAddress = (address) => {
  return createAddress({ ...address, status: 'temporary' });
};

export { createAddress, createSubscriptionAddress, updateAddress, validateAddress, createTemporaryAddress };
