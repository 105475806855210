export const present = (value, message = null) => {
  return {
    valid: Boolean(value),
    message: message || 'Required field'
  };
};

export const presentAndValidString = (value, message = null) => {
  const wordRegex = /(\w)/;

  return {
    valid: Boolean(value) && Boolean(value.match(wordRegex)),
    message: message || 'Required field'
  };
};

export const password = (value, message = null) => {
  return {
    valid: Boolean(value) && value.length > 5,
    message: message || 'Required field'
  };
};

export const email = (value) => {
  // eslint-disable-next-line no-control-regex
  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9-](?:[a-zA-Z0-9-]*[a-zA-Z0-9-])?\.)+[a-zA-Z0-9-](?:[a-zA-Z0-9-]*[a-zA-Z0-9-])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9-]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // eslint-disable-line no-control-regex

  return {
    valid: Boolean(value) && Boolean(value.match(emailRegex)),
    message: 'Invalid email address'
  };
};

export const numeric = (value) => {
  return {
    valid: Boolean(value) && Boolean(value.match(/^\d+$/)),
    message: 'Must be a number'
  };
};

export const phone = (value) => {
  return {
    valid: Boolean(value) && Boolean(value.match(/^(\+)?([1])?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)),
    message: 'Invalid phone number'
  };
};

export const zip = (value) => {
  return {
    valid: Boolean(value) && Boolean(value.match(/^\d{5}$/)),
    message: 'Invalid zip code'
  };
};

export const literalsOnly = (value) => ({
  valid: Boolean(value) && Boolean(value.match(/^[A-Za-z '.-]+$/)),
  message: 'Cannot contain numbers. Reach us at contact@blueapron.com for help.'
});

export const characterLength = (value, message = null, length = 8) => {
  return {
    valid: Boolean(value) && value.length >= length,
    message: message || '8 or more characters'
  };
};

export const mixOfCase = (value, message = null) => {
  return {
    valid: Boolean(value) && Boolean(value.match(/^(?=.*[A-Z]*[a-z])(?=.*[a-z]*[A-Z])/)),
    message: message || 'Upper & lowercase letters'
  };
};

export const numberAndSpecialCharacter = (value, message = null) => {
  return {
    valid: Boolean(value) && Boolean(value.match(/^(?=.*\d)(?=.*[@$!%*?&])/)),
    message: message || 'One number & special character'
  };
};

export default {
  password,
  present,
  email,
  numeric,
  phone,
  zip,
  literalsOnly,
  characterLength,
  mixOfCase,
  numberAndSpecialCharacter,
  presentAndValidString
};
