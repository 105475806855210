import { PLAN_IDS } from 'shared/constants/plans';

export const SET_PLANS = 'SET_PLANS';
export const SET_DEFAULT_PLAN = 'SET_DEFAULT_PLAN';
export const SET_PLANS_LAST_FETCHED_PARAMS = 'SET_PLANS_LAST_FETCHED_PARAMS';
export const SET_LAST_FETCHED_COUPON = 'SET_LAST_FETCHED_COUPON';

export const setPlans = (plans) => {
  return {
    type: SET_PLANS,
    payload: plans
  };
};

export const setDefaultPlan = (selectedPlanId) => {
  const planId = selectedPlanId || PLAN_IDS.TWO_PERSON.toString();

  return {
    type: SET_DEFAULT_PLAN,
    payload: {
      id: planId
    }
  };
};

export const setPlansLastFetchedParams = (lastFetchedZip, lastFetchedCoupon) => {
  return {
    type: SET_PLANS_LAST_FETCHED_PARAMS,
    payload: { lastFetchedZip, lastFetchedCoupon }
  };
};

export const setLastCouponFetched = (lastFetchedCoupon) => {
  return {
    type: SET_LAST_FETCHED_COUPON,
    payload: lastFetchedCoupon
  };
};
