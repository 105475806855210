import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const InputError = ({ classNames, message }) => <div className={cx('pom-InputError', classNames)}>{message}</div>;

InputError.propTypes = {
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.bool)]),
  message: PropTypes.string.isRequired
};

InputError.defaultProps = {
  classNames: undefined
};

export default InputError;
