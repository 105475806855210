import Metrics from 'services/metrics/track-event';

import { SET_PLAN_FOR_USER, SET_PLAN_QUANTITY_FOR_USER, SET_IS_VEGETARIAN_FOR_USER } from 'redux/reducers/user';
import { SET_SELECTED_WINDOW_AND_DELIVERY_WINDOW } from 'redux/reducers/windows';

// Only track certain actions
const shouldTrack = (action, options = {}) => {
  let track = false;
  const targetIdRegExp = new RegExp(`^${options.targetId}`);

  if (options.forceTrack) {
    track = true;
  } else if (action.meta && action.meta.event && action.meta.event.id.match(targetIdRegExp)) {
    track = true;
  }

  return track;
};

const ACTION_EVENT_MAP = {
  [SET_PLAN_QUANTITY_FOR_USER]: (previousState, nextState, action) => {
    if (shouldTrack(action, { targetId: 'PlanQuantity' })) {
      Metrics.trackEvent({
        category: 'Meal Registration Flow - Preferences Page',
        action: 'Number of People Tapped',
        attributes: {
          plan_quantity: action.payload
        }
      });
    }
  },
  [SET_PLAN_FOR_USER]: (previousState, nextState, action) => {
    if (shouldTrack(action, { targetId: 'PlanType' })) {
      Metrics.trackEvent({
        category: 'Meal Registration Flow - Preferences Page',
        action: 'Recipes per Week Tapped',
        attributes: {
          plan_type: action.payload
        }
      });
    }
  },
  [SET_IS_VEGETARIAN_FOR_USER]: (previousState, nextState, action) => {
    if (shouldTrack(action, { targetId: 'ShowVegetarianOptions' })) {
      Metrics.trackEvent({
        category: 'Meal Registration Flow - Preferences Page',
        action: 'Vegetarian Toggle Tapped',
        attributes: {
          is_vegetarian: action.payload
        }
      });
    }
  },
  [SET_SELECTED_WINDOW_AND_DELIVERY_WINDOW]: (previousState, nextState, action) => {
    if (shouldTrack(action, { targetId: 'deliveryWindowsInWeek' })) {
      Metrics.trackEvent({
        category: 'Meal Registration Flow - Checkout Page',
        action: 'First Delivery Date Dropdown Click'
      });
      Metrics.trackEvent({
        category: 'Meal Registration Flow - Checkout Page',
        action: 'Delivery Day of Week Dropdown Click'
      });
    }
  }
};

export default ACTION_EVENT_MAP;
