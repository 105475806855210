export const TRACK_EVENTS = {
  allRecipesCleared: 'All Recipes Cleared',
  ctaClicked: 'CTA clicked',
  chooseOptionsClicked: 'Choose Options Clicked',
  closeRecipeModalView: 'Recipe Modal Closed',
  couponApplied: 'Coupon Applied - Client',
  couponDeclined: 'Coupon Declined',
  deliveryDateSelected: 'Delivery Date Selected',
  deliveryDayAssigned: 'Delivery Day Assigned',
  deliveryInformationEntered: 'Delivery Information Entered',
  elementClicked: 'Element Clicked',
  mealPlanSelected: 'Meal Plan And Preferences Selected',
  preferencesSaved: 'Preferences Saved - Client',
  preferencesSelected: 'Preferences Selected',
  productAdded: 'Product Added',
  productRemoved: 'Product Removed',
  productViewed: 'Product Viewed - Client',
  recipeClicked: 'Recipe Clicked',
  recipeModalArrowClicked: 'Recipe Modal Arrow Clicked',
  recipeModalTabClicked: 'Recipe Modal Tab Clicked',
  recipeModalViewDetails: 'Recipe Modal View Details Clicked',
  recipeModalViewed: 'Recipe Modal Viewed',
  recipeOptionChosen: 'Recipe Option Chosen',
  recipeSelectionsSaved: 'Recipe Selections Saved',
  registrationCompleted: 'Registration Completed - Client',
  signedOut: 'Signed out',
  singleDeliveryRescheduled: 'Single Delivery Rescheduled',
  singleRecipeRemoved: 'Single Recipe Removed',
  upcomingDeliverySelected: 'Upcoming Delivery Selected',
  upcomingHeroCTAClicked: 'Upcoming Hero CTA Clicked',
  upcomingJumpLinkClicked: 'Upcoming Jumplink Clicked'
};

export const CHECKOUT_TYPES = {
  gift: 'gift',
  marketplace: 'marketplace',
  meal: 'meal',
  wine: 'wine'
};

export const CLICK_LOCATIONS = {
  recipeModal: 'recipe_modal',
  menu: 'menu',
  myOrder: 'my_order'
};

export const CLIENT_TYPE = {
  desktop: 'desktop_web',
  mobileWeb: 'mobile_web',
  mobileApp: 'mobile_app'
};

export const CART_CONTEXT = {
  subscription_wine: 'subscription_wine',
  subscription_food: 'subscription_food',
  market: 'market',
  gift_card: 'gift_card'
};

export const USER_TYPE = {
  user: 'user',
  registrant: 'registrant',
  subscriber: 'subscriber'
};
