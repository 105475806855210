const appendQueryParams = (basePath, queryParams) => {
  const queryString = Object.entries(queryParams || {})
    .reduce((acc, [key, value]) => {
      if (!value && value !== false) return acc;
      return [
        ...acc,
        `${encodeURIComponent(key).replace('%5B', '[').replace('%5D', ']')}=${encodeURIComponent(value)}`
      ];
    }, [])
    .join('&');

  if (queryString) return `${basePath}?${queryString}`;

  return basePath;
};

export default appendQueryParams;
