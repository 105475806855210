import { combineReducers } from 'redux';

// import addresses from 'redux/reducers/addresses/addresses';
import address from 'redux/reducers/address';
import appConfigurations from 'redux/reducers/app-configurations';
// import appConfig from 'redux/reducers/appConfig/appConfig';
// import basket from 'redux/reducers/basket';
import configurations from 'redux/reducers/configurations';
import carts from 'redux/reducers/carts';
// import checkout from 'redux/reducers/checkout';
import copy from 'redux/reducers/copy';
import coupons from 'redux/reducers/coupons';
// import current from 'redux/reducers/current';
import plans from 'redux/reducers/plans';
// import deliveries from 'redux/reducers/deliveries/deliveries';
import notices from 'redux/reducers/notices';
// import session from 'redux/reducers/session';
import subscription from 'redux/reducers/subscription';
// import requests from 'redux/reducers/requests';
import user from 'redux/reducers/user';
// import windows from 'redux/reducers/windows';
// import invites from 'redux/reducers/invites';
// import notificationsQueue from 'redux/reducers/notificationsQueue';
// import market from 'redux/reducers/market';
// import merchandising from 'redux/reducers/merchandising';
// import deliveryWindows from 'redux/reducers/delivery-windows/delivery-windows';
// import deliveryHistory from 'redux/reducers/delivery-history';
// import packages from 'redux/reducers/packages/packages';
// import reactivate from 'redux/reducers/reactivate';
// import recipes from 'redux/reducers/recipes/recipes';
// import restrictions from 'redux/reducers/restrictions';
// import settings from 'redux/reducers/settings';
// import sampleRecipes from 'redux/reducers/sampleRecipes';
import upcoming from 'redux/reducers/upcoming';
// import surveys from 'redux/reducers/surveys';
// import holidayNotice from 'redux/reducers/holidayNotice';
// import graphqlUser from 'redux/reducers/graphql-user';
// import schedulePages from 'redux/reducers/schedule-pages';
// import deliveryDates from 'redux/reducers/delivery-dates';

const rootReducer = combineReducers({
  address,
  appConfigurations: appConfigurations || (() => null),
  // basket,
  carts,
  // checkout: checkout || (() => null),
  configurations: configurations || (() => null),
  copy: copy || (() => null),
  coupons: coupons || (() => null),
  // current: current || (() => null),
  // deliveries: deliveries || (() => null),
  // invites: invites || (() => null),
  plans: plans || (() => null),
  // market: market || (() => null),
  // merchandising: merchandising || (() => null),
  notices,
  // recipes: recipes || (() => null),
  // requests,
  // session,
  subscription: subscription || (() => null),
  // reactivate: reactivate || (() => null),
  // restrictions: restrictions || (() => null),
  // settings: settings || (() => null),
  // surveys: surveys || (() => null),
  // sampleRecipes: sampleRecipes || (() => null),
  upcoming: upcoming || (() => null),
  // schedulePages: schedulePages || (() => null),
  // deliveryDates: deliveryDates || (() => null),
  user: user || (() => null)
  // graphqlUser: graphqlUser || (() => null),
  // windows,
  // notificationsQueue,
  // deliveryWindows: deliveryWindows || (() => null),
  // deliveryHistory: deliveryHistory || (() => null),
  // holidayNotice,
  // packages: packages || (() => null),
  // addresses: addresses || (() => null),
  // appConfig
});

export default rootReducer;
