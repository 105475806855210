import { createSelector } from 'reselect';
import { WINE } from 'shared/constants/constants';

const rootSelector = (state) => state;

export const getCoupons = createSelector(rootSelector, (state) => state.coupons);

export const getWineCoupon = createSelector(
  getCoupons,
  (coupons) =>
    coupons && coupons.find && coupons.find((coupon) => coupon.restrictions && coupon.restrictions.type === WINE)
);
