export const ignoredMessages = [
  "null is not an object (evaluating 'c.browser_fingerprint_id')",
  'Error: 0',
  'Access is denied.',
  "'UET' is undefined",
  'Error calling method on NPObject.',
  'Error calling method on NPObject!',
  'Script error',
  'twttr is not defined',
  "Uncaught TypeError: Cannot read properties of null (reading 'appendChild')",
  "Object doesn't support property or method 'endsWith'",
  "Failed to execute 'sendBeacon()' on 'Window': Invalid argument.",
  'Blocked a frame with origin "https://www.blueapron.com" from accessing a frame with origin "https://a214661394.cdn.optimizely.com". Protocols, domains, and ports must match.',
  'SecurityError (Dom Exception 18): Blocked a frame with origin "https://www.blueapron.com" from accessing a frame with origin "https://a214661394.cdn.optimizely.com". Protocols, domains, and ports must match.',
  "TypeError: undefined is not an object (evaluating 'honey._native.tabs.getCurrent')",
  '(unknown): Not a Product',
  '(unknown): Unable to parse response',
  '(unknown): {}',
  'Blocked a frame with origin "https://www.blueapron.com" from accessing a frame with origin "https://tr.snapchat.com". Protocols, domains, and ports must match',
  'undefined is not an object (evaluating \'document.getElementsByClassName("docs-homescreen-gb-container")[0].style\')',
  'ResizeObserver loop limit exceeded',
  'Minified React error'
];

/*
 * Rollbar
 * As this code affects error reporting, please ensure full device/platform coverage when
 * validating any changes. This includes desktop web, mobile web, iOS (using Simulator or real device),
 * Android, and multiple browsers (Chrome/Safari/Firefox/Edge/IE10+).
 */
export const config = {
  accessToken: __ROLLBAR_TOKEN__,
  autoInstrument: {
    dom: false
  },
  enabled: Boolean(JSON.parse(__ROLLBAR_ENABLED__)),
  ignoredMessages,
  itemsPerMinute: 10,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: __ROLLBAR_ENVIRONMENT__,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: __ROLLBAR_CODE_VERSION__,
        guess_uncaught_frames: true
      }
    }
  }
};

export default { ignoredMessages, config };
