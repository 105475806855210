import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';

const fetchCreditBalance = () => {
  /* eslint-disable prettier/prettier */
  return client.query({
    query: gql`
      query CreditBalance {
        creditBalance {
          unrestricted {
            displayValue
          }
          restricted {
            displayValue
          }
          total {
            displayValue
          }
        }
    }
    `,
    fetchPolicy: 'no-cache'
  })
  .then((result) => {
    return result.data.creditBalance;
  });
  /* eslint-enable prettier/prettier */
};

export default fetchCreditBalance;
