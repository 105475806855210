import { createSelector } from 'reselect';
import {
  createAddress as createAddressApi,
  createSubscriptionAddress as createSubscriptionAddressApi,
  updateAddress as updateAddressApi,
  validateAddress as validateAddressApi
} from 'services/api/address';
import { startRequest, completeRequest, failRequest } from 'redux/reducers/requests';
import { showError } from 'redux/reducers/notices';

import parseError from 'shared/utils/error-handling/parse-errors';

export const SET_ADDRESS = 'SET_ADDRESS';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ADDRESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export const setAddress = (address, event) => {
  return {
    type: SET_ADDRESS,
    payload: address,
    meta: { event }
  };
};

export const addNewAddress = (address) => {
  const key = 'createAddress';

  return (dispatch) => {
    dispatch(startRequest(key));

    return createAddressApi(address)
      .then((response) => {
        dispatch(completeRequest(key));
        dispatch(setAddress(response.address));
        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Address creation failed';
        if (!response) {
          dispatch(showError(key, defaultErrorText));
          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }
        dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

        return Promise.reject(parseError(response));
      });
  };
};

export const createAddress = () => {
  return (dispatch, getState) => {
    const { address } = getState();
    dispatch(addNewAddress(address));
  };
};

export const createSubscriptionAddress = () => {
  const key = 'createSubscriptionAddress';

  return (dispatch, getState) => {
    const { address, subscription, user } = getState();
    const subscription_id = user.isWineRegistration ? subscription.wine.id : subscription.food.id;

    dispatch(startRequest(key));

    return createSubscriptionAddressApi(subscription_id, address)
      .then((response) => {
        dispatch(completeRequest(key));
        dispatch(setAddress(response.address));
        return Promise.resolve(response);
      })
      .catch((response) => {
        const defaultErrorText = 'Subscription address creation failed';
        if (!response) {
          dispatch(showError(key, defaultErrorText));
          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }
        dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

        return Promise.reject(parseError(response));
      });
  };
};

export const validateAddress = () => {
  const key = 'validateAddress';

  return (dispatch, getState) => {
    const { address } = getState();

    return validateAddressApi(address)
      .then(() => {
        dispatch(completeRequest(key));
        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Address validation failed';
        if (!response) {
          dispatch(showError(key, defaultErrorText));
          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }
        dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

        return Promise.reject(parseError(response));
      });
  };
};

export const updateAddress = () => {
  const key = 'updateAddress';

  return (dispatch, getState) => {
    dispatch(startRequest(key));
    const { address } = getState();

    return updateAddressApi(address)
      .then(() => {
        dispatch(completeRequest(key));
        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Address PUT failed';
        if (!response) {
          dispatch(showError(key, defaultErrorText));
          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }
        dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

        return Promise.reject(parseError(response));
      });
  };
};

/*
  Selectors
*/

const rootSelector = (state) => state;

export const getAddressState = createSelector(rootSelector, (state) => state.address);

export const isAddressPersisted = (state) => {
  return Boolean(state.address && state.address.id);
};

export const getHasZipCode = (state) => {
  return !!state.address && !!state.address.zip;
};
