import { formatPriceAsCurrency, priceOrFree } from 'shared/utils/pricing-helpers/helpers';
import { getDiscountAmountPerUse } from 'shared/utils/coupons';
import { FOOD, PREPARED_AND_READY_PLAN } from 'shared/constants/constants';

import compact from 'lodash/compact';
import without from 'lodash/without';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';

export const hasVegetarianOption = (plan) =>
  Object.values((plan && plan.options) || {}).find((planQuantity) =>
    planQuantity.variationsAvailable.includes('vegetarian')
  );

export const isVegetarianVariation = (plan) => Boolean(plan.display && plan.display.isVegetarian);

// Currently, the max shipping price is associated with the 2p2 plan
export const getMaxShipping = (plans) => {
  const twoPersonPlan = plans.find((plan) => plan.product.serves === 2);

  if (!twoPersonPlan) return null;

  return twoPersonPlan.options[2].price.shipping;
};

export const hasMoreThanTwoQuantities = (plans) =>
  Object.values(plans || []).some((plan) => Object.keys(plan.options).length > 2);

export const getShippingPriceText = (quantity) => priceOrFree(parseFloat(quantity.price.shipping));

export const getTotalPrediscount = (quantity) => formatPriceAsCurrency(quantity.price.total);

export const getTotalWithTaxPreDiscount = (quantity) => {
  const { total } = quantity.priceWithTax || quantity.price;
  return formatPriceAsCurrency(total);
};

export const getServingPrice = (quantity) => formatPriceAsCurrency(quantity.price.serving);

export const getTax = (plan, quantityKey) => {
  const selectedPlanOption = plan.options && plan.options[quantityKey];
  return selectedPlanOption && selectedPlanOption.priceWithTax ? selectedPlanOption.priceWithTax.tax : undefined;
};

const getMatchingPrice = (couponPrices, planId, quantityKey) =>
  Object.values(couponPrices || []).find(
    (p) => p.planId === planId && parseInt(p.productsPerOrder, 10) === parseInt(quantityKey, 10)
  );

export const getPlanFullPrice = (coupon, planId, quantityKey) => {
  const couponPrices = coupon && coupon.prices;
  const price = getMatchingPrice(couponPrices, planId, quantityKey);
  return price && price.introductory[0];
};

export const getPlanDiscount = (coupon, planId, quantityKey) => {
  const couponPrices = coupon && coupon.prices;
  const price = getMatchingPrice(couponPrices, planId, quantityKey);
  return price && (Number(price.full) - Number(price.introductory[0])).toFixed(2);
};

export const getTotalWithDiscount = (coupon, planId, quantityKey) => {
  const couponPrices = coupon && coupon.prices;
  const isEmployeeDiscount = coupon && coupon.isEmployeeDiscount;
  const price = getMatchingPrice(couponPrices, planId, quantityKey);
  return isEmployeeDiscount ? price && price.recurringPrice : price && price.introductory[0];
};

export const getUnitPriceWithDiscount = (coupon, planId, quantityKey) => {
  const couponPrices = coupon && coupon.prices;
  const isEmployeeDiscount = coupon && coupon.isEmployeeDiscount;
  const price = getMatchingPrice(couponPrices, planId, quantityKey);
  return isEmployeeDiscount
    ? price && price.recurringUnitCost
    : price && price.introductoryUnitCost[0] && price.introductoryUnitCost[0].product_amount;
};

export const getIntroductoryShippingPriceWithDiscount = (coupon, planId, quantityKey) => {
  const couponPrices = coupon && coupon.prices;
  const price = getMatchingPrice(couponPrices, planId, quantityKey);
  return price && price.introductoryUnitCost[0] && price.introductoryUnitCost[0].shipping_amount;
};

export const getTaxWithDiscount = (coupon, planId, quantityKey) => {
  const couponPricesWithTax = coupon && coupon.pricesWithTax;
  const priceWithTax = getMatchingPrice(couponPricesWithTax, planId, quantityKey);
  return priceWithTax && priceWithTax.tax;
};

export const getTotalWithTaxWithDiscount = (coupon, planId, quantityKey) => {
  const couponPricesWithTax = coupon && coupon.pricesWithTax;
  const isEmployeeDiscount = coupon && coupon.isEmployeeDiscount;
  const priceWithTax = getMatchingPrice(couponPricesWithTax, planId, quantityKey);
  return isEmployeeDiscount
    ? priceWithTax && priceWithTax.recurringPrice
    : priceWithTax && priceWithTax.introductory[0];
};

export const getDiscountAmount = (coupon, planId, quantityKey) => {
  return getDiscountAmountPerUse(coupon, planId, quantityKey);
};

export const getFoodPlans = (plans) => Object.values(plans || {}).filter(({ planType }) => planType === FOOD);

// gets variations that match PLAN_SORTING config and appends the rest of variations to a tale
const getSortedPlanVariations = (variations) => {
  const PLAN_SORTING = [
    {
      isVegetarian: false,
      id: '1'
    },
    {
      isVegetarian: false,
      id: '3'
    },
    {
      isVegetarian: true,
      id: '1'
    },
    {
      isVegetarian: false,
      id: '5'
    }
  ];

  const getMatchSortingIteratee = (sortItem) => (variation) => {
    return variation.display.isVegetarian === sortItem.isVegetarian && sortItem.id === variation.id;
  };

  const sortedVariations = compact(PLAN_SORTING.map((sortItem) => variations.find(getMatchSortingIteratee(sortItem))));

  const restVariations = without(variations, ...sortedVariations);

  return [...sortedVariations, ...restVariations];
};

// UI temporarily updated for A/B testing to hide certain preferences on UI
const mealKitsPreferencesShown = {
  favorites: true,
  wellness: true,
  family_friendly: true,
  fast_and_easy: true,
  veggies: true
};

const preparedAndReadyPreferencesShown = {
  '600_calories_or_less': true,
  high_protein: true,
  carb_conscious: true
};

const preferenceDescriptions = {
  favorites: `Our Test Kitchen's top picks`,
  wellness: 'Nutritionist-approved recipes that don’t sacrifice flavor',
  family_friendly: 'Classic flavors the whole family will love',
  fast_and_easy: 'Recipes designed for low prep or quick cook times',
  veggies: 'Meat-free meals highlighting seasonally inspired produce',
  keto_friendly: 'Meals higher in dietary fats with limited carbs and moderate amounts of protein',
  '600_calories_or_less': 'Meals for those choosing to monitor caloric intake without sacrificing flavor',
  high_protein: 'Meals with 30g or more of protein per serving',
  carb_conscious: 'Meals with less than 30g of net carbohydrates per serving'
};

// gets variations that match PLAN_SORTING config and appends the rest of variations to a tale
const getSortedPreferenceVariations = (variations, planId) => {
  const PLAN_SORTING = [
    { planApiName: 'favorites' },
    { planApiName: 'wellness' },
    { planApiName: 'family_friendly' },
    { planApiName: 'fast_and_easy' },
    { planApiName: 'veggies' },
    { planApiName: 'keto_friendly' },
    { planApiName: '600_calories_or_less' },
    { planApiName: 'high_protein' },
    { planApiName: 'carb_conscious' },
    { planApiName: 'seafood' },
    { planApiName: 'meal_prep' },
    { planApiName: 'special_menus' },
    { planApiName: 'try_it_all' }
  ];

  const isPreparedAndReady = planId === '12';

  const getMatchSortingIteratee = (sortItem) => (variation) => {
    return variation.planApiName === sortItem.planApiName;
  };

  const sortedVariations = compact(
    PLAN_SORTING.map((sortItem) => {
      return variations.find(getMatchSortingIteratee(sortItem));
    })
  );

  const restVariations = without(variations, ...sortedVariations);

  const sortedPreferenceVariations = [...sortedVariations, ...restVariations];

  return sortedPreferenceVariations.map((variation) => ({
    ...variation,
    preferenceShown: isPreparedAndReady
      ? !!preparedAndReadyPreferencesShown[variation.planApiName]
      : !!mealKitsPreferencesShown[variation.planApiName],
    preferenceDescription: preferenceDescriptions[variation.planApiName] || ''
  }));
};

// this selector creates variations out of plans. This is because we have vegetarian plan which has to be displayed but
// since vegetarian plan has the same plan id as a regular plan, we cannot store it in redux store because of current
// store structure. Also since this variations are needed only for one single page, we don't consider store structure
// refactoring.
export const getPlanVariations = (plans) => {
  const planVariations = getFoodPlans(plans).reduce((aggregatedPlans, plan) => {
    if (plan.display) {
      plan.display.forEach((disp) => {
        const isVegetarian = disp.planApiName === 'vegetarian';
        // preferences will be derived from the display.preferences returned by the api
        // For, Vegetarian display variation, preferences = [vegetarian]
        // For, Wellness display variation, preferences = [wellness]
        // This prefence will be used for updating the user's subscription preferences
        const preferences = (disp.displayNameVariation && disp.preferences) || [];
        let { options } = plan;
        const display = {
          ...disp,
          isVegetarian,
          preferences
        };
        if (disp.displayNameVariation) {
          options = pickBy(plan.options, (option) => option.variationsAvailable.includes(disp.planApiName));
        }

        if (isEmpty(options)) {
          return;
        }

        const variation = {
          ...plan,
          display,
          options
        };

        aggregatedPlans.push(variation);
      });
    } else {
      aggregatedPlans.push(plan);
    }
    return aggregatedPlans;
  }, []);

  return getSortedPlanVariations(planVariations);
};

export const getPlanPreferences = (plansById, planId, planApiName) => {
  const userPlansById = plansById.filter((plan) => {
    return parseInt(plan.id, 10) === parseInt(planId, 10);
  });

  const planByApiName = userPlansById.filter((userplan) => userplan.display.planApiName === planApiName);
  return (planByApiName.length && planByApiName[0].display.preferences) || [];
};

export const getUserPlanPreferences = (user = {}) => {
  const preferences = user.plan && user.plan.preferences ? user.plan.preferences : [];
  return Object.keys(preferences).reduce((selected, preferenceKey) => {
    return preferences[preferenceKey] ? [...selected, preferenceKey] : selected;
  }, []);
};

export const getPlanVariationName = (plansById, planId, planApiName) => {
  const userPlansById = plansById.filter((plan) => {
    return parseInt(plan.id, 10) === parseInt(planId, 10);
  });

  const planByApiName = userPlansById.filter((userplan) => userplan.display.planApiName === planApiName);
  return (planByApiName[0].display.displayNameVariation && planByApiName[0].display.planApiName) || '';
};

export const getDesktopPlanVariations = (planVariations, selectedVariation) =>
  (planVariations || []).map((plan) => {
    const isSelected =
      selectedVariation.id === plan.id && selectedVariation.display.isVegetarian === plan.display.isVegetarian;
    return {
      displayName: {
        label: (plan.display && plan.display.displayNameVariation) || plan.displayName,
        icon: plan.displayNameIcon,
        color: plan.display && plan.display.displayNameColor
      },
      prices: plan.options ? mapValues(plan.options, (opt) => opt.price) : [],
      serves: plan.product && plan.product.serves,
      selectedQuantity: `${plan.defaultProductsPerOrder}`,
      productType: 'recipes',
      description: (plan.display && plan.display.description.default) || '',
      image:
        (plan.display &&
          plan.display.images &&
          plan.display.images.desktop &&
          plan.display.images.desktop.find((image) => image.type === 'plan_image').url) ||
        '',
      frequency: plan.frequency,
      id: plan.id,
      isVegetarian: isVegetarianVariation(plan),
      isMostPopular: false,
      isSelected,
      preferences: plan.display.preferences,
      planApiName: plan.display.planApiName
    };
  });

export const getPreparedAndReadyPlanVariation = (desktopPlanVariations) =>
  desktopPlanVariations.find(({ planApiName }) => planApiName === PREPARED_AND_READY_PLAN);

export const getMealKitPlanVariations = (desktopPlanVariations) =>
  desktopPlanVariations.filter(({ planApiName }) => planApiName !== PREPARED_AND_READY_PLAN);

export const getPlanVariationsForSelectedPlan = (selectedPlanVariation, planVariations) =>
  selectedPlanVariation?.display?.planApiName === PREPARED_AND_READY_PLAN
    ? [selectedPlanVariation]
    : planVariations.filter((plan) => plan?.display?.planApiName !== PREPARED_AND_READY_PLAN);

export const getDisplayedPlanVariations = (planApiName, planVariations) =>
  planApiName === PREPARED_AND_READY_PLAN
    ? [planVariations.find((plan) => plan?.display?.planApiName === PREPARED_AND_READY_PLAN)]
    : planVariations.filter((plan) => plan?.display?.planApiName !== PREPARED_AND_READY_PLAN);

export const getDisplayName = (planDisplayVariations, planId, planApiName) => {
  const plan = planDisplayVariations[planId].display.filter((d) => d.planApiName === planApiName);
  return (plan.length && plan[0].displayNameVariation) || planDisplayVariations[planId].displayName;
};

export const getPlanQuantity = (user) => user && user.plan && user.plan.quantity;

const getFormattedOptions = (options) =>
  Object.entries(options || {}).reduce(
    (acc, [quantityKey, { price, priceWithTax, variationsAvailable }]) => ({
      ...acc,
      [quantityKey]: { price, priceWithTax, variationsAvailable }
    }),
    {}
  );

const getVariationsIdsByPlanId = (plans) =>
  Object.entries(plans || {}).reduce((acc, [planId, plan]) => {
    let planVariations = [];
    if (
      plan &&
      plan.relationships &&
      plan.relationships.displayVariations &&
      plan.relationships.displayVariations.data
    ) {
      planVariations = plan.relationships.displayVariations.data || [];
    }

    return {
      ...acc,
      [planId]: planVariations.map(({ id }) => id)
    };
  }, {});

export const getPlansByIdWithPreferences = ({ plans, planDisplayVariations }) => {
  const variationIdsByPlanId = getVariationsIdsByPlanId(plans);
  return Object.values(plans || {}).reduce((acc, plan) => {
    const displayVariations = [];
    const preferences = [];
    variationIdsByPlanId[plan.id].forEach((variationId) => {
      const variationContent = {
        planApiName: planDisplayVariations[variationId].attributes.apiName,
        description: planDisplayVariations[variationId].attributes.description,
        displayNameColor: planDisplayVariations[variationId].attributes.displayNameColor,
        displayNameVariation: planDisplayVariations[variationId].attributes.displayNameVariation,
        images: planDisplayVariations[variationId].attributes.images,
        preferences: planDisplayVariations[variationId].attributes.preferences
      };
      if (planDisplayVariations[variationId].attributes.displayNameVariation) {
        preferences.push(variationContent);
      } else {
        displayVariations.push(variationContent);
      }
    });
    const sortedPreferences = getSortedPreferenceVariations(preferences, plan.id);
    acc[plan.id] = {
      id: plan.id,
      planType: plan.attributes.planType,
      displayName: plan.attributes.displayName,
      defaultProductsPerOrder: plan.attributes.defaultProductsPerOrder,
      product: {
        serves: plan.attributes.product.serves,
        name: plan.attributes.product.name
      },
      display: displayVariations,
      preferences: sortedPreferences,
      frequency: plan.attributes.delivery.frequency,
      options: getFormattedOptions(plan.attributes.options)
    };

    return acc;
  }, {});
};

export const getPlanTypes = (plans) =>
  (plans || []).map((plan) => {
    const isDisplayEmpty = isEmpty(plan.display);
    return {
      id: plan.id,
      serves: plan.product.serves,
      displayName: {
        label: (!isDisplayEmpty && plan.display.displayNameVariation) || plan.displayName,
        icon: plan.displayNameIcon,
        color: !isDisplayEmpty && plan.display.displayNameColor
      },
      isVegetarian: isVegetarianVariation(plan),
      description: (!isDisplayEmpty && plan.display.description.default) || '',
      image: (!isDisplayEmpty && plan.display.images.mobile.find((image) => image.type === 'plan_image').url) || '',
      preferences: plan.display && plan.display.preferences,
      planApiName: plan.display && plan.display.planApiName
    };
  });

export const getPlanServings = (plans) => uniq((plans || []).map((p) => p.serves));

export const getSelectedPlan = (plans, userPlan) => {
  const selectedPlan = userPlan && plans.find(({ id }) => id === userPlan.id);
  if (!selectedPlan) return {};

  const selectedPlanOption = selectedPlan.options[userPlan.quantity];
  return {
    ...selectedPlan,
    selectedPlanOption
  };
};

export const getDefaultPlanForUser = (plans) =>
  (plans || []).find((variation) => variation.display.planApiName === 'two_person');

export default {
  hasVegetarianOption,
  hasMoreThanTwoQuantities,
  getShippingPriceText,
  getTotalPrediscount,
  getTotalWithTaxWithDiscount,
  getTotalWithTaxPreDiscount,
  getServingPrice,
  getSelectedPlan,
  getTotalWithDiscount,
  getFoodPlans,
  getPlanTypes,
  getPlansByIdWithPreferences
};
