/* eslint-disable no-console */
import { config } from 'shared/constants/rollbarConfig';

function isStripeError(error) {
  return error.stripeError;
}

function shouldReport(error) {
  if (error.status && error.status >= 400) return false;
  return !isStripeError(error);
}

function getRollbar() {
  return (
    window.Rollbar || {
      critical: console.error,
      debug: console.log,
      error: console.error,
      info: console.log,
      log: console.log
    }
  );
}

function errorMessage(error) {
  if (typeof error === 'string') return error;
  return error.message || JSON.stringify(error);
}

function castToError(error) {
  return error instanceof Error ? error : new Error(errorMessage(error));
}

export default function reportToRollbar(errorInput, { level = 'error' } = {}) {
  if (typeof window === 'undefined') {
    const error = castToError(errorInput);
    // eslint-disable-next-line global-require
    const Rollbar = require('rollbar');
    const rollbar = new Rollbar({
      ...config,
      accessToken: process.env.MY_NEXT_BA_ROLLBAR_SERVER_TOKEN
    });
    rollbar.error(error, errorInput, (rollbarError) => {
      if (rollbarError) {
        console.error('Rollbar error reporting failed:');
        console.error(rollbarError);
      }
    });
  } else {
    if (!shouldReport(errorInput)) return;
    const error = castToError(errorInput);
    const Rollbar = getRollbar();
    Rollbar[level](error);
  }
}
