import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const Ex = (props) => {
  return (
    <Icon height={props.height} viewBox={props.viewBox} width={props.width} containerClassNames={props.classNames}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g transform="translate(-1317.000000, -785.000000)" stroke={props.color} strokeWidth="2">
          <g transform="translate(1318.000000, 786.000000)">
            <path d="M0,7.82069468 L7.82069468,0" />
            <path d="M0,0 L7.82069468,7.82069468" />
          </g>
        </g>
      </g>
    </Icon>
  );
};

Ex.propTypes = {
  height: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.bool)])
};

Ex.defaultProps = {
  height: '10',
  viewBox: '0 0 10 10',
  width: '10',
  color: '#245091',
  classNames: undefined
};

export default Ex;
