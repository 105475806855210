import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import logEvent from 'services/metrics/logEvent';
import rootReducer from 'redux/reducers';
import { rollbarRedux } from 'redux/lib/rollbarRedux';
import Cookies from 'js-cookie';

// Development Tooling
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'shared/utils/development/action-logger';

import ACTION_EVENT_MAP from 'services/metrics/actionEventMap';

const middlewares = [thunk, logEvent(ACTION_EVENT_MAP), rollbarRedux];
let composer = compose;

if (
  process.env.NODE_ENV === 'staging' ||
  process.env.NODE_ENV === 'development' ||
  process.env.NODE_ENV === 'preview' ||
  process.env.NODE_ENV === 'ie'
)
  middlewares.push(logger);
if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  if (process.env.NODE_ENV !== 'production' || Cookies.getJSON('masquerading_as')) {
    composer = composeWithDevTools;
  }
}

const configureStore = (initialState) => {
  const composedCreateStore = composer(applyMiddleware(...middlewares))(createStore);
  const store = composedCreateStore(rootReducer, initialState);

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('../reducers', () => {
  //     const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
  //     store.replaceReducer(nextRootReducer);
  //   });
  // }

  return store;
};

export default configureStore;
