import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';

// eslint-disable-next-line import/prefer-default-export
const removeVariantsFromAgoraCart = (cartId, variants, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client
    .mutate({
      mutation: gql`
        mutation RemoveVariantsFromCart($input: RemoveVariantsFromCartInput!) {
          removeVariantsFromCart(input: $input) {
            cart {
              id
              checkoutState
              state
              guestToken
              price {
                discount {
                  displayValue
                }
                estimated
                shipping {
                  displayValue
                }
                subtotal {
                  displayValue
                }
                tax {
                  displayValue
                }
                total {
                  displayValue
                }
              }
              lineItems {
                quantity
                variant {
                  core
                  description
                  displayPrice {
                    formattedPrice
                  }
                  displayPriority
                  name {
                    full
                  }
                  price {
                    original {
                      displayValue
                    }
                  }
                  sku
                  variableValues {
                    displayValue
                    value
                    variable {
                      description
                      displayName
                      name
                      type
                    }
                  }
                }
              }
            }
            clientErrors {
              message
            }
          }
        }
      `,
      variables: {
        input: {
          cartId,
          variants,
          ownerId
        }
      }
    })
    .then((result) => {
      const {
        data: { removeVariantsFromCart }
      } = result;
      const { clientErrors, cart } = removeVariantsFromCart;
      return { cart, clientErrors };
    });
  /* eslint-enable prettier/prettier */
};

export default removeVariantsFromAgoraCart;
