/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import includes from 'lodash/includes';
import dataAttrs from 'shared/utils/data-attrs';

import Spinner from '../Spinner/Spinner';

const SPINNER_SIZE_MAP = {
  small: 6,
  medium: 8,
  large: 12,
  full: 12
};

const PAYMENT_TYPES = ['google', 'apple'];

const Button = (props) => {
  const color = props.category || props.color;
  const paymentCategory = includes(PAYMENT_TYPES, props.category);
  const image = props.image && !paymentCategory && !props.isLoading && (
    <span className={cx('pom-Button__Image')}>
      <img alt={props.category} src={props.image} />
    </span>
  );
  return (
    <button
      {...dataAttrs(props.data)}
      className={cx(
        'pom-Button',
        { 'pom-Button--social': props.image },
        `pom-Button--${color}`,
        `pom-Button--${props.buttonType}`,
        `pom-Button--${props.size}`,
        {
          'pom-Button--disabled': props.isDisabled,
          'pom-Button--loading': props.isLoading
        },
        props.classNames
      )}
      disabled={props.isDisabled || props.isLoading}
      id={props.id}
      uit={props.uit}
      form={props.htmlForm}
      type={props.htmlType}
      onClick={props.onClick}
      style={props.styles}
    >
      <div className="pom-Button--mouseover" onMouseOver={props.onHover} onMouseOut={props.onHover} />
      {props.svgImage && props.svgImage()}
      {image}
      {paymentCategory && !props.isLoading && (
        <span className={cx('pom-Button__Payment')}>
          <img alt={props.category} src={props.image} />
        </span>
      )}
      <span
        style={{
          opacity: props.isLoading && !props.showLoadingText ? 0 : 1
        }}
      >
        {props.text}
      </span>
      {props.isLoading && (
        <Spinner
          classNames={{
            'pom-Button__Spinner--center': !props.showLoadingText,
            'pom-Button__Spinner--right': props.showLoadingText
          }}
          size={props.showLoadingText ? 8 : SPINNER_SIZE_MAP[props.size]}
        />
      )}
    </button>
  );
};

const { arrayOf, bool, func, node, objectOf, object, oneOf, oneOfType, string } = PropTypes;

export const propTypes = {
  buttonType: oneOf(['filled', 'outline', 'pill-filled', 'pill-outline', 'round-filled', 'link', 'input-end']),
  category: oneOf(['facebook', 'google', 'apple']),
  classNames: oneOfType([string, objectOf(bool)]),
  color: oneOf(['blue', 'darkBlue', 'lightBlue', 'green', 'red', 'gray', 'orange', 'facebook', 'blueAndGray', 'white']),
  data: objectOf(string),
  htmlForm: string,
  htmlType: oneOf(['submit', 'reset', 'button']),
  id: string,
  isDisabled: bool,
  isLoading: bool,
  image: string,
  onClick: func,
  onHover: func,
  showLoadingText: bool,
  size: oneOf(['card', 'card-md', 'small', 'medium', 'large', 'full']),
  // eslint-disable-next-line react/forbid-prop-types
  styles: object,
  svgImage: func,
  text: oneOfType([arrayOf(node), node, string]),
  uit: string
};

Button.propTypes = propTypes;

Button.defaultProps = {
  buttonType: 'filled',
  category: undefined,
  classNames: undefined,
  color: 'blue',
  data: null,
  htmlForm: undefined,
  htmlType: 'button',
  id: null,
  isDisabled: false,
  isLoading: false,
  image: undefined,
  onClick() {},
  onHover() {},
  showLoadingText: false,
  size: 'medium',
  svgImage: null,
  styles: {},
  text: 'Click',
  uit: null
};

export default Button;
