import gql from 'graphql-tag';

export const ORDER_PRICE_FIELDS = gql`
  fragment OrderPriceFields on OrderPrice {
    discount {
      displayValue
    }
    estimated
    shipping {
      displayValue
    }
    subtotal {
      displayValue
    }
    tax {
      displayValue
    }
    total {
      displayValue
    }
    credit {
      displayValue
      hundredths
    }
  }
`;
