import { SET_APP_CONFIGURATION } from './actions';

export const initialState = {
  httpHeaders: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_CONFIGURATION: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
};
