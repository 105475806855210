import { fetchCopy as fetch } from 'services/api/copy';
import parseError from 'shared/utils/error-handling/parse-errors';

import { setCopy } from './copy-actions';

export const fetchCopy = (flow) => {
  return (dispatch, getState) => {
    const { session } = getState();

    if (getState().copy && getState().copy[flow]) {
      return Promise.resolve();
    }

    return fetch(session, flow)
      .then((copy) => {
        dispatch(setCopy(flow, copy));
        return Promise.resolve();
      })
      .catch((response) => {
        return Promise.reject(parseError(response));
      });
  };
};

export { fetchCopy as default };
