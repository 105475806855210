import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';

// eslint-disable-next-line import/prefer-default-export
const getAppConfiguration = () => {
  return client
    .query({
      query: gql`
        query AppConfiguration {
          configuration(appType: WEB) {
            features
            httpHeaders {
              name
              value
            }
            aLaCarteConfig {
              enabled
              mobileRedirect {
                copy
                subtitle
                title
                url
              }
            }
            referralConfig {
              enabled
              termsUrl
              value {
                displayValue
                hundredths
              }
            }
            stripeConfig {
              publishableKey
            }
          }
        }
      `
    })
    .then((result) => {
      return result.data.configuration;
    });
};

export default getAppConfiguration;
