import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import InputHelpTip from './InputHelpTip';

const toolTipStyle = {
  style: {
    width: '300px',
    letterSpacing: 'normal',
    textTransform: 'none'
  }
};

const InputHelpTipWithTooltip = ({ id, renderTooltip, className }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: 'top'
  });

  return (
    <span className={cx('pom-InputHelpTipWithTooltip', className)}>
      <div ref={setTriggerRef}>
        <InputHelpTip id={id} data-testid={id} />
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container'
          })}
        >
          <div {...toolTipStyle}>{renderTooltip()}</div>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </span>
  );
};

InputHelpTipWithTooltip.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  renderTooltip: PropTypes.func.isRequired
};

InputHelpTipWithTooltip.defaultProps = {
  id: '',
  className: ''
};

export default InputHelpTipWithTooltip;
