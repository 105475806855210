import { ApolloClient, HttpLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import fetch from 'node-fetch';

import reportToRollbar from 'shared/utils/error-handling/report-to-rollbar/report-to-rollbar';
import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';

const persistedQueryLink = createPersistedQueryLink(
  generatePersistedQueryIdsFromManifest({
    loadManifest: () => import('../../../persisted-query-manifest.json')
  })
);

const httpLink = new HttpLink({
  fetch,
  uri: `${typeof window !== 'undefined' && window.location.origin}/graphql`,
  headers: {
    accept: 'application/json',
    'content-type': 'application/json',
    'X-BlueApron-GraphQL-ClientName': __GRAPHQL_CLIENT_NAME__,
    'X-GraphQL-Client-Name': __GRAPHQL_CLIENT_NAME__,
    'X-GraphQL-Client-Version': 'main'
  }
});

let dyanmicHeaders = {};

const cacheHeaderLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    ...dyanmicHeaders
  }
}));

let middleware;

// Note: OperationStoreClient.apolloLink must come before httpLink
// in the array below.
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  middleware = [persistedQueryLink, cacheHeaderLink, httpLink];
} else {
  middleware = [cacheHeaderLink, httpLink];
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      reportToRollbar({ message, locations, path });
    });
  }

  if (networkError) {
    reportToRollbar(networkError);
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, ...middleware])
});

export const updateHeaders = (newHeaders) => {
  dyanmicHeaders = newHeaders;
};

export default { client, updateHeaders };
