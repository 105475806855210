/* eslint no-shadow: ["error", { "allow": ["_"] }] */
import Cookies from 'js-cookie';
import uniq from 'lodash/uniq';

import { LOGOUT_USER } from 'redux/reducers/user';
import { getSiteTestParticipated } from 'shared/utils/site-tests';
import { SET_FROM_STORAGE } from 'redux/store/initial-state/storage';
import { HOLDBACK_VARIATION } from 'shared/utils/email-password';

import { SET_CONFIGURATIONS, SET_OPTIMIZELY_EXPERIMENTS, SET_REGIONS } from './configurations-actions';

/*
  featuresToPersist
  These are tests we want to persist when setting from local storage
  Between registration and account
*/
export const featuresToPersist = [HOLDBACK_VARIATION];

export const initialState = {
  features: [...getSiteTestParticipated()],
  plan_flex: {
    show_tooltip: Cookies.getJSON('show_plan_flex_edu_tooltip') !== false
  },
  regions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIGURATIONS: {
      return action.payload.features
        ? {
            ...state,
            ...action.payload,
            features: uniq([...state.features, ...action.payload.features])
          }
        : {
            ...state,
            ...action.payload
          };
    }
    case LOGOUT_USER: {
      return {
        ...initialState
      };
    }
    case SET_OPTIMIZELY_EXPERIMENTS: {
      return {
        ...state,
        features: uniq([...state.features, ...action.payload])
      };
    }
    case SET_FROM_STORAGE: {
      const features = action.payload.configurations && action.payload.configurations.features;

      if (features) {
        const includedFeaturesToPresist = features.filter((feat) => featuresToPersist.includes(feat));

        return {
          ...state,
          features: uniq([...state.features, ...includedFeaturesToPresist])
        };
      }

      return state;
    }
    case SET_REGIONS: {
      return {
        ...state,
        regions: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
