import moment from 'moment';
import { MENU_SET_WINE } from 'shared/constants/constants';

export const dateFormatter = (selector) => selector.map(({ arrival }) => moment(arrival.date).format('dddd, MMM Do'));

export const setForkMutabilityById = (byId, packageId) => {
  return {
    ...byId,
    [packageId]: {
      ...byId[packageId],
      mutability: {
        ...byId[packageId].mutability,
        fork: false
      }
    }
  };
};

export const findMealPackageIdByMenuDate = (packages = {}, menuDate) =>
  (Object.values(packages).find(({ menu: { date, slug } } = {}) => date === menuDate && slug !== MENU_SET_WINE) || {})
    .id;

export const findPackageToNavigate = (currentPackageId = null, { menuDate } = {}, navigateToDefault, packages = {}) => {
  if (menuDate) {
    const packageId = findMealPackageIdByMenuDate(packages, menuDate);
    if (!packageId && navigateToDefault) {
      navigateToDefault();
    }
    return packageId;
  }
  return currentPackageId;
};
