import React from 'react';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      inputs: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });

    const invalidInputs = this.invalidInputs();
    if (Object.keys(invalidInputs).length) {
      this.props.onSubmitFailure(invalidInputs);
    } else {
      const inputs = mapValues(this.state.inputs, (i) => i.value);
      this.props.onSubmit(inputs);
    }
  }

  onChange(event) {
    this.setState(
      (state) => ({
        inputs: {
          ...state.inputs,
          [event.name]: event
        }
      }),
      () => {
        this.props.onInputChange(this.state.inputs);
      }
    );
  }

  invalidInputs() {
    return pickBy(this.state.inputs, (input) => {
      return (!input.value.length && !input.optional) || !input.valid;
    });
  }

  render() {
    return (
      <form
        id={this.props.id}
        action={this.props.action}
        onSubmit={this.onSubmit}
        method={this.props.method}
        name={this.props.name}
      >
        {this.props.children(this.onChange, this.state.submitted)}
      </form>
    );
  }
}

Form.propTypes = {
  id: PropTypes.string,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFailure: PropTypes.func,
  children: PropTypes.func.isRequired,
  method: PropTypes.oneOf(['get', 'post']),
  name: PropTypes.string,
  action: PropTypes.string
};

Form.defaultProps = {
  id: undefined,
  onInputChange: () => {},
  onSubmit: () => {},
  onSubmitFailure: () => {},
  method: 'post',
  name: undefined,
  action: undefined
};

export default Form;
