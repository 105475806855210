import gql from 'graphql-tag';

import { ADDRESS_FIELDS } from './address-fields';
import { CREDIT_CARD_FIELDS } from './credit-card-fields';
import { IMAGE_FIELDS } from './image-fields';
import { ORDER_PRICE_FIELDS } from './order-price-fields';

/* eslint-disable-next-line import/prefer-default-export */
export const MARKET_CART_FIELDS = gql`
  fragment MarketCartFields on Cart {
    id
    checkoutState
    state
    guestToken
    options
    paymentMethod {
      type
      displayName
      creditCardDetails {
        ...CreditCardFields
      }
    }
    shippingAddress {
      ...AddressFields
    }
    config {
      minimumValue {
        displayValue
        hundredths
      }
      priceModifierTiers {
        minimumUnitThreshold
        unitDiscount {
          displayValue
          hundredths
        }
      }
    }
    shipments {
      id
      lineItems {
        quantity
        variant {
          displayPrice {
            formattedPrice
          }
          name {
            full
            main
          }
          availability {
            level
          }
          images {
            primary {
              ...ImageFields
            }
          }
          sku
          variableValues {
            displayValue
            value
            variable {
              description
              displayName
              name
              type
            }
          }
        }
      }
      selectedShippingRate {
        id
        displayName
      }
      shippingRates {
        id
        displayName
      }
    }
    price {
      ...OrderPriceFields
    }
    promotions {
      amount {
        displayValue
      }
      displayName
    }
    lineItems {
      quantity
      variant {
        core
        description
        displayPrice {
          formattedPrice
        }
        displayPriority
        name {
          full
          main
        }
        price {
          original {
            displayValue
          }
        }
        sku
        images {
          primary {
            ...ImageFields
          }
        }
      }
    }
  }
  ${ADDRESS_FIELDS}
  ${CREDIT_CARD_FIELDS}
  ${IMAGE_FIELDS}
  ${ORDER_PRICE_FIELDS}
`;
