import { createSelector } from 'reselect';
import sortBy from 'lodash/sortBy';
import keys from 'lodash/keys';

import { PLAN_IDS } from './constants';

const rootSelector = (state) => state;

export const getPlans = createSelector(rootSelector, (state) => state.plans);

export const getWinePlan = createSelector(
  getPlans,
  ({ plansById }) => plansById && Object.values(plansById).find((plan) => parseInt(plan.id, 10) === PLAN_IDS.WINE)
);

export const getAllPlans = createSelector(getPlans, ({ plansById }) => plansById);

export const getLastFetchedZip = createSelector(getPlans, (plans) => {
  return !plans ? undefined : plans.plansLastFetchedParams.lastFetchedZip;
});

export const getPlanOptionIds = (plan) => {
  const planOptionIds = keys(plan.options);
  return sortBy(planOptionIds, (optionId) => Number(optionId));
};
