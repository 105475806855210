const stateToSkipDaysOfWeekMap = {
  AL: [],
  CT: [],
  DC: [],
  DE: [],
  FL: [],
  GA: [],
  IA: [],
  IL: [],
  IN: [],
  KY: [],
  MA: [],
  MD: [],
  ME: [],
  MI: [],
  MN: [],
  NC: [],
  ND: [],
  NH: [],
  NJ: [],
  NY: [],
  OH: [],
  PA: [],
  RI: [],
  SC: [],
  SD: [],
  TN: [],
  VA: [],
  VT: [],
  WI: [],
  WV: []
};

export default stateToSkipDaysOfWeekMap;
