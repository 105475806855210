import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

import { GIFT_CART_CONTEXT, FOOD_CART_CONTEXT, MARKET_CONTEXT, SHIPPING_RATE_TYPES } from 'shared/constants/constants';

const rootSelector = (state) => state;

export const getCarts = createSelector(rootSelector, (state) => state.carts);
export const getGiftCardsCart = createSelector(getCarts, (carts) => carts[GIFT_CART_CONTEXT]);
export const getMarketCart = createSelector(getCarts, (carts) => carts[MARKET_CONTEXT]);
export const getFoodCart = createSelector(getCarts, (carts) => carts[FOOD_CART_CONTEXT]);

export const getShipmentsForCart = createSelector(getFoodCart, (cart) => {
  if (isEmpty(cart)) {
    return null;
  }
  return cart?.shipments.length ? cart.shipments[0] : [];
});

export const getArrivalDateForCart = createSelector(getFoodCart, (cart) => {
  if (isEmpty(cart)) {
    return null;
  }
  return cart.scheduledArrival.date;
});

export const getShipmentIdForCart = createSelector(getShipmentsForCart, (shipment) => {
  if (!shipment || shipment.length === 0) {
    return null;
  }
  return shipment?.id;
});

export const getShippingRatesForCart = createSelector(getShipmentsForCart, (shipment) => {
  if (!shipment || shipment.length === 0) {
    return null;
  }
  return shipment?.shippingRates;
});

export const getAllStandardShippingRatesForCart = createSelector(getShippingRatesForCart, (shippingRates) => {
  if (!shippingRates || shippingRates.length === 0) {
    return null;
  }
  return shippingRates.filter((r) => r.type === SHIPPING_RATE_TYPES.SHIPPING && r.available);
});

export const getShippingRatesForDate = createSelector(
  getShippingRatesForCart,
  getArrivalDateForCart,
  (shippingRates, arrivalDate) => {
    if (!shippingRates || shippingRates.length === 0) {
      return false;
    }
    return shippingRates.filter((r) => r.arrivalDate === arrivalDate);
  }
);

export const isCartScheduledDeliveryEligible = createSelector(getShippingRatesForDate, (availableShippingRates) => {
  if (!availableShippingRates || availableShippingRates.length === 0) {
    return false;
  }
  return !!availableShippingRates.find((c) => c.type === SHIPPING_RATE_TYPES.COURIER);
});

export const getCartScheduledDeliverySlots = createSelector(
  getShippingRatesForDate,
  isCartScheduledDeliveryEligible,
  (shippingRates, cartScheduledDeliveryEligible) => {
    if (!cartScheduledDeliveryEligible) {
      return null;
    }
    const courierShippingRates = shippingRates.filter((c) => c.type === SHIPPING_RATE_TYPES.COURIER);
    return courierShippingRates.sort((a, b) => a.displayPriority - b.displayPriority);
  }
);

export const getStandardShippingRateForCartDate = createSelector(getShippingRatesForDate, (shippingRates) => {
  if (!shippingRates || shippingRates.length === 0) {
    return null;
  }
  return shippingRates.find((c) => c.type === SHIPPING_RATE_TYPES.SHIPPING);
});

export const getAvailableCartScheduledDeliverySlots = createSelector(getCartScheduledDeliverySlots, (slots) => {
  if (!slots) {
    return null;
  }
  return slots.filter((c) => c.available);
});

export const cartScheduledDeliverySlotsAvailable = createSelector(
  getAvailableCartScheduledDeliverySlots,
  (deliverySlots) => {
    if (!deliverySlots) {
      return false;
    }
    return deliverySlots.length > 0;
  }
);

export const getSelectedShippingRateForCart = createSelector(getShipmentsForCart, (shipment) => {
  if (!shipment || shipment.length === 0) {
    return null;
  }
  return shipment?.selectedShippingRate;
});

export const getSelectedShippingRateIdOnCalendar = createSelector(getFoodCart, (cart) => {
  if (isEmpty(cart)) {
    return null;
  }
  return cart?.selectedShippingRateIdOnCalendar;
});

export const getPotentialDeliveryDate = createSelector(
  getAllStandardShippingRatesForCart,
  getSelectedShippingRateIdOnCalendar,
  (deliveryDates, selectedDeliveryDayId) => {
    if (!deliveryDates) return null;
    return deliveryDates.find((deliveryDay) => deliveryDay.id === selectedDeliveryDayId)?.arrivalDate;
  }
);

export const amountInCartFactory = (context) => {
  return createSelector(getCarts, (carts) => {
    const cart = carts[context];
    const cartCategories = cart.variants ? Object.keys(cart.variants) : [];

    return cartCategories.reduce((accumulator, category) => {
      const amountInCategory = cart.variants[category].reduce((a, product) => a + (product.quantity || 1), 0);
      return accumulator + amountInCategory;
    }, 0);
  });
};

export const getGiftAmountInCart = createSelector(amountInCartFactory(GIFT_CART_CONTEXT), (amount) => amount);
