import Cookies from 'js-cookie';
import camelize from 'shared/utils/camelize';
import { FOOD, WINE } from 'shared/constants/constants';

const getFormattedPrices = (prices) => {
  return prices.reduce((acc, price) => {
    return [
      ...acc,
      {
        planId: String(price.plan_id),
        productsPerOrder: String(price.products_per_order),
        introductory: price.prices.introductory,
        introductoryUnitCost: price.prices.introductory_unit_costs,
        full: price.prices.full,
        recurringPrice: price.prices.recurring,
        recurringUnitCost: price.prices.recurring_unit_cost
      }
    ];
  }, []);
};

const getFormattedPricesWithTax = (prices) => {
  return prices.reduce((acc, price) => {
    return [
      ...acc,
      {
        planId: String(price.plan_id),
        productsPerOrder: String(price.products_per_order),
        tax: price.prices_with_tax.tax,
        introductory: price.prices_with_tax.introductory,
        introductoryUnitCost: price.prices.introductory_unit_costs,
        full: price.prices_with_tax.full,
        recurringPrice: price.prices_with_tax.recurring,
        recurringUnitCost: price.prices.recurring_unit_cost
      }
    ];
  }, []);
};

export const isCouponBlank = (coupon) => {
  if (!coupon) {
    return true;
  }
  // return true if the coupon actually applies some sort of discount amount
  // (intended to filter out "redirect" coupons, which only have a redirect_url)
  const { discount } = coupon;
  const { amount, recurring } = discount;

  const amountDollars = parseFloat(amount.dollars);
  const recurringDollars = parseFloat(recurring.dollars);

  return (
    amountDollars <= 0 && amount.percent <= 0 && amount.servings <= 0 && recurringDollars <= 0 && recurring.percent <= 0
  );
};

export const getFormattedCoupons = (coupons) => {
  return coupons.reduce((acc, coupon) => {
    const { discount, discount_description, code, prices, type, restrictions, message, max_drips, legal_copy } = coupon;
    const { recurring } = discount;
    const recurringDollars = recurring && recurring.dollars;
    const isEmployeeDiscount = recurringDollars > 0;
    return [
      ...acc,
      {
        discount: isEmployeeDiscount ? recurringDollars : discount.amount.dollars,
        discountDescription: discount_description,
        maxAmountPerUse: discount.amount.max_amount_per_use,
        maxDrips: camelize(max_drips),
        legalCopy: legal_copy,
        couponMessage: message,
        isEmployeeDiscount,
        code,
        type,
        prices: getFormattedPrices(prices),
        pricesWithTax: getFormattedPricesWithTax(prices),
        restrictions: {
          card: restrictions.card,
          type: restrictions.type
        },
        isBlank: isCouponBlank(coupon)
      }
    ];
  }, []);
};

const getPrices = ({ prices }, userPlanId, quantityKey) =>
  prices.find(({ planId, productsPerOrder }) => {
    if (planId && userPlanId) {
      return (
        planId.toString() === userPlanId.toString() && parseInt(productsPerOrder, 10) === parseInt(quantityKey, 10)
      );
    }
    return false;
  });

export const getCouponToBeApplied = (coupons) => {
  return coupons && coupons.length ? coupons[0] : undefined;
};

export const getUserCouponToBeApplied = (coupons) => {
  const userCoupon = Cookies.get('coupon_code');
  let coupon;
  if (userCoupon) {
    coupon = coupons.find(({ code }) => code === userCoupon);
  }
  return coupon || getCouponToBeApplied(coupons);
};

export const isCoupon = (coupon) => coupon && coupon.type === 'coupon';

export const isDripCoupon = (coupon) => isCoupon(coupon) && coupon.maxAmountPerUse > 0;
// Drip coupons have max_amount_per_use that distinguishes them
// from other coupons
// drip coupons do not cover the full amt and conventional coupons will not
// have multiple introductory prices without fully covering the first x weeks

export const isVariableDripCoupon = (coupon) =>
  isCoupon(coupon) && coupon.maxDrips && coupon.maxDrips.length > 0 && !isDripCoupon(coupon);
// Checks for variable drip coupon that has max_drips which distinguishes them from regular drip coupons

export const isVariableDripFirstShipFree = (coupon) =>
  isVariableDripCoupon(coupon) && coupon.maxDrips[0].shippingAmount && coupon.maxDrips[0].shippingAmount.dollars > 0;

export const totalCouponDiscount = ({ type, discount }) => type === 'coupon' && discount;

export const isGiftCoupon = ({ type }) => {
  return type === 'gift';
};

export const isExternalCoupon = ({ discountDescription }) => {
  return !!discountDescription; // If a discount description is present, it's an external coupon!
};

export const getDiscountAmountPerUse = (coupon, planId, quantityKey) => {
  const prices = getPrices(coupon, planId, quantityKey);
  if (!prices || !coupon.discount) {
    return undefined;
  }
  const { full, introductory } = prices;
  const discountAmountPerUse =
    coupon && coupon.isEmployeeDiscount ? parseFloat(coupon.discount) : parseFloat(full) - parseFloat(introductory);
  return `${discountAmountPerUse.toFixed(2)}`;
};

export const getNumberOfDripWeeks = (coupon, planId, quantityKey) => {
  const prices = getPrices(coupon, planId, quantityKey);
  if (!prices || !prices.introductory.length || (!isDripCoupon(coupon) && !isVariableDripCoupon(coupon))) {
    return undefined;
  }

  return prices.introductory.length;
};

export const showAppliedAmount = (coupon) => {
  // Order matters here since drip coupons are also type coupon
  if (!coupon || isGiftCoupon(coupon) || isDripCoupon(coupon) || isExternalCoupon(coupon)) {
    return false;
  }

  if (coupon.type === 'invite' || coupon.type === 'coupon') {
    return true;
  }

  return undefined;
};

export const isCouponFood = (coupon) => {
  return coupon.restrictions.type === FOOD;
};

export const isCouponWine = (coupon) => {
  return coupon.restrictions.type === WINE;
};
export const isRedeemable = (coupon, isWineRegistration) => {
  if (isCouponWine(coupon) && isWineRegistration) {
    return true;
  }
  if (isCouponFood(coupon) && !isWineRegistration) {
    return true;
  }

  return false;
};

export default {
  getFormattedCoupons,
  getCouponToBeApplied,
  showAppliedAmount
};
