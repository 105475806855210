/* eslint-disable no-plusplus */

function camelCase(str) {
  return str.replace(/[_.-](\w|$)/g, (_, x) => {
    return x.toUpperCase();
  });
}

function walk(alreadyProcessedObjects, obj) {
  if (!obj || typeof obj !== 'object') return obj;
  if (obj.constructor === Date || obj.constructor === RegExp) return obj;

  if (alreadyProcessedObjects.has(obj)) {
    return alreadyProcessedObjects.get(obj);
  }

  if (Array.isArray(obj)) {
    const camelizedArray = [];
    alreadyProcessedObjects.set(obj, camelizedArray);

    Object.assign(
      camelizedArray,
      obj.map((v) => walk(alreadyProcessedObjects, v))
    );
    return camelizedArray;
  }

  const camelizedObject = {};
  alreadyProcessedObjects.set(obj, camelizedObject);

  Object.assign(
    camelizedObject,
    Object.keys(obj).reduce((acc, key) => {
      const camel = camelCase(key);
      acc[camel] = walk(alreadyProcessedObjects, obj[key]);
      return acc;
    }, {})
  );

  return camelizedObject;
}

// NOTE: this module was originally taken from https://github.com/substack/camelize/blob/master/index.js
// Changes were done only for avoiding stack overflow for objects with
// circular references. And original code doesn't touch strings in arrays and
// values in objects.
//
// For better compatibility the same behavior preserved here.
module.exports = function (obj) {
  if (typeof obj === 'string') return camelCase(obj);
  const alreadyProcessedObjects = new WeakMap();
  const result = walk(alreadyProcessedObjects, obj);
  return result;
};
