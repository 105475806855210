// @flow

export const DISPLAY_METHODS = Object.freeze({
  FLASH: 'flash'
});

export const LEVELS = Object.freeze({
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success'
});

export const SHOW_ERROR = 'SHOW_ERROR';
export const SHOW_WARNING = 'SHOW_WARNING';
export const SHOW_INFO = 'SHOW_INFO';
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const REMOVE_NOTICE = 'REMOVE_NOTICE';
export const CLEAR_NOTICES = 'CLEAR_NOTICES';

const initialState = {
  scroll: false,
  byId: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR: {
      const { key, message, displayMethod, level = 0, scroll = true } = action.payload;

      return {
        ...state,
        scroll,
        byId: {
          ...state.byId,
          [key]: {
            type: LEVELS.ERROR,
            message,
            displayMethod,
            level
          }
        }
      };
    }
    case SHOW_WARNING: {
      const { key, message, displayMethod, level = 0, scroll = true } = action.payload;

      return {
        ...state,
        scroll,
        byId: {
          ...state.byId,
          [key]: {
            type: LEVELS.WARNING,
            message,
            displayMethod,
            level
          }
        }
      };
    }
    case SHOW_INFO: {
      const { key, message, displayMethod, level = 0, scroll = true } = action.payload;

      return {
        ...state,
        scroll,
        byId: {
          ...state.byId,
          [key]: {
            type: LEVELS.INFO,
            message,
            displayMethod,
            level
          }
        }
      };
    }
    case SHOW_SUCCESS: {
      const { key, message, displayMethod, level = 0, scroll = true, multiline = false } = action.payload;

      return {
        ...state,
        scroll,
        byId: {
          ...state.byId,
          [key]: {
            type: LEVELS.SUCCESS,
            message,
            displayMethod,
            level,
            multiline
          }
        }
      };
    }
    case REMOVE_NOTICE: {
      const keyToRemove = action.payload.key;
      const newById = {
        ...state.byId
      };
      delete newById[keyToRemove];
      return {
        ...state,
        scroll: false,
        byId: newById
      };
    }
    case CLEAR_NOTICES: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export const showError = (key, message, displayMethod, level, scroll) => {
  return {
    type: SHOW_ERROR,
    payload: { key, message, displayMethod, level, scroll }
  };
};

export const showWarning = (key, message, displayMethod, level, scroll) => {
  return {
    type: SHOW_WARNING,
    payload: { key, message, displayMethod, level, scroll }
  };
};

export const showInfo = (key, message, displayMethod, level, scroll) => {
  return {
    type: SHOW_INFO,
    payload: { key, message, displayMethod, level, scroll }
  };
};

export const showSuccess = (key, message, displayMethod, level, scroll, multiline) => {
  return {
    type: SHOW_SUCCESS,
    payload: { key, message, displayMethod, level, scroll, multiline }
  };
};

export const removeNotice = (key) => {
  return {
    type: REMOVE_NOTICE,
    payload: { key }
  };
};

export const clearNotices = () => {
  return {
    type: CLEAR_NOTICES
  };
};
