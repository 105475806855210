import isArray from 'lodash/isArray';

const trackGoogleTagManager = (events) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  if (!isArray(events)) {
    window.dataLayer.push(events);
  } else {
    events.forEach((event) => {
      window.dataLayer.push(event);
    });
  }
};

export default trackGoogleTagManager;
