import React from 'react';
import { string, bool, oneOfType, objectOf } from 'prop-types';

import Icon from '../Icon';

const Checkmark = (props) => {
  const checkPath = !props.bold ? (
    <path
      d="M9 14.6l4 3.8 7.5-7.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
  ) : (
    <g transform="translate(3, 2)" stroke={props.stroke} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round">
      <polyline points="5 12.410209 9.45249546 16.8627044 18.3151999 8" />
    </g>
  );

  return (
    <Icon containerClassNames={props.classNames} height={props.height} width={props.width} viewBox={props.viewBox}>
      <g
        fill={props.fill}
        stroke={props.outlineStroke || props.stroke}
        strokeWidth={props.outlineStrokeWidth}
        transform={props.transform}
        className="pom-Checkmark"
      >
        {!props.hideOutline && <ellipse cx="15" cy="15" rx="14.9" ry="14.9" />}
        {checkPath}
      </g>
    </Icon>
  );
};

Checkmark.propTypes = {
  classNames: oneOfType([string, objectOf(bool)]),
  height: string,
  width: string,
  viewBox: string,
  stroke: string,
  strokeWidth: string,
  outlineStroke: string,
  outlineStrokeWidth: string,
  transform: string,
  hideOutline: bool,
  bold: bool,
  fill: string
};

Checkmark.defaultProps = {
  classNames: undefined,
  height: '32',
  width: '32',
  viewBox: '0 0 32 32',
  fill: '#fff',
  stroke: '#00AF7D',
  strokeWidth: '2',
  outlineStroke: undefined,
  outlineStrokeWidth: '2',
  transform: 'translate(1 1)',
  hideOutline: false,
  bold: false
};

export default Checkmark;
