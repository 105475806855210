import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const claimAgoraCart = (cartId, currentOwner, email) => {
  /* eslint-disable prettier/prettier */
  return client.mutate({
    mutation: gql`
      mutation ClaimMarketCart($input: ClaimCartInput!) {
        claimCart(input: $input) {
          cart {
            ...MarketCartFields
          }
          clientErrors {
            message
          }
        }
      }
      ${MARKET_CART_FIELDS}
    `,
    variables: {
      input: {
        cartId,
        email,
        currentOwner
      }
    }
  })
  .then((result) => {
    const { data: { claimCart } } = result;
    const { clientErrors, cart } = claimCart;
    return { cart, clientErrors };
  });
  /* eslint-enable prettier/prettier */
};

export default claimAgoraCart;
