// @flow

import { fetch, Session } from 'services/fetch';

const apiVersion = 'v20161006';

export const fetchCredits = (session: Session) =>
  fetch('/api/users/coupons/remaining_balance', session, apiVersion).then((response) => response);

export default { fetchCredits };
