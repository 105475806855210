import { ADD_PRODUCT_DETAILS, SET_QUICKVIEW_PRODUCT_ID, RESET_QUICKVIEW_PRODUCT_ID } from './actions';

export const initialState = {
  cartProductDetailsById: {},
  quickViewProductId: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_DETAILS: {
      return {
        ...state,
        cartProductDetailsById: {
          ...state.cartProductDetailsById,
          ...action.payload.details
        }
      };
    }
    case SET_QUICKVIEW_PRODUCT_ID: {
      const { sku, slug } = action.payload;
      return {
        ...state,
        quickViewProductId: sku,
        quickViewSlug: slug
      };
    }
    case RESET_QUICKVIEW_PRODUCT_ID: {
      return {
        ...state,
        quickViewProductId: null,
        quickViewSlug: null
      };
    }
    default: {
      return state;
    }
  }
};
