import { createSelector } from 'reselect';

import { POST_CUTOFF_STATUSES, FOOD, WINE } from 'shared/constants/constants';

import { dateFormatter } from './utils';

const rootSelector = (state) => state;
export const packagesSelector = createSelector(rootSelector, ({ packages }) => packages);
export const packagesByIdSelector = createSelector(packagesSelector, ({ byId }) => byId);
export const isPackagesLoaded = createSelector(packagesByIdSelector, (packages) => Object.keys(packages).length > 0);
export const isPackagesLoading = createSelector(packagesSelector, ({ isLoading }) => isLoading);
export const postCutOffsPackageSelector = createSelector(packagesByIdSelector, (packages) =>
  Object.values(packages).filter(({ status }) => POST_CUTOFF_STATUSES.includes(status))
);
export const winePostCutOffPackageSelector = createSelector(postCutOffsPackageSelector, (packages) =>
  packages.filter(({ planType }) => planType === WINE)
);
export const mealsPostCutOffPackageSelector = createSelector(postCutOffsPackageSelector, (packages) =>
  packages.filter(({ planType }) => planType === FOOD)
);
export const packagesType = (state, { type }) => type;

export const getPostCutOffDatesByType = createSelector(
  winePostCutOffPackageSelector,
  mealsPostCutOffPackageSelector,
  (wineSelector, mealsSelector) => ({
    postCutOffs: {
      meals: dateFormatter(mealsSelector),
      wine: dateFormatter(wineSelector)
    }
  })
);

export const getFoodPackages = createSelector(packagesByIdSelector, (packages) =>
  Object.values(packages).filter(({ planType }) => planType === FOOD)
);
