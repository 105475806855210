import { getFormattedCoupons } from 'shared/utils/coupons';

export const SET_COUPONS = 'SET_COUPONS';

export const setCoupons = (coupons) => {
  return {
    type: SET_COUPONS,
    payload: getFormattedCoupons(coupons)
  };
};
