export const ADD_PRODUCT_DETAILS = 'ADD_PRODUCT_DETAILS';
export const SET_QUICKVIEW_PRODUCT_ID = 'SET_QUICKVIEW_PRODUCT_ID';
export const RESET_QUICKVIEW_PRODUCT_ID = 'RESET_QUICKVIEW_PRODUCT_ID';

export const addProductDetails = (details) => ({
  type: ADD_PRODUCT_DETAILS,
  payload: {
    details
  }
});

export const setQuickViewProductId = (sku, slug) => ({
  type: SET_QUICKVIEW_PRODUCT_ID,
  payload: { sku, slug }
});

export const resetQuickViewProductId = () => ({
  type: RESET_QUICKVIEW_PRODUCT_ID
});
