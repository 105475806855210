import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const addVariantsToAgoraCart = (cartId, variants, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client
    .mutate({
      mutation: gql`
        mutation AddVariantsToMarketCart($input: AddVariantsToCartInput!) {
          addVariantsToCart(input: $input) {
            cart {
              ...MarketCartFields
            }
            clientErrors {
              message
            }
          }
        }
        ${MARKET_CART_FIELDS}
      `,
      variables: {
        input: {
          cartId,
          variants,
          ownerId
        }
      }
    })
    .then((result) => {
      return result.data.addVariantsToCart;
    });
  /* eslint-enable prettier/prettier */
};

export default addVariantsToAgoraCart;
