import Cookies from 'js-cookie';
import MobileDetect from 'mobile-detect';

import urlParameters from 'shared/utils/url-parameters/url-parameters';

import getEnabledTestsMap from 'services/metrics/ba-client/get-enabled-tests-map';

export const DEFAULT_PROPERTIES = {
  active_subscription: 'none',
  is_pmc: 'none',
  meal_plan_type: 'none',
  member_type: 'guest',
  native_mobile: 'false',
  payment_type: 'none',
  site_test: [],
  user_id: null,
  wine_plan_type: 'none'
};

const buildMobileFlags = (preferences) => {
  const { mobile } = urlParameters();
  if (mobile === 'false') return preferences;

  const md = new MobileDetect(window.navigator.userAgent);
  if (md.match('iphone|ipod|ipad')) {
    return { ...preferences, native_mobile: 'ios' };
  }

  if (md.match('android')) {
    return { ...preferences, native_mobile: 'android' };
  }

  return preferences;
};

const buildUserInfoFlags = (user) => (preferences) => {
  if (!user) return preferences;
  const { id } = user;

  return {
    ...preferences,
    user_id: id
  };
};

const buildUserPaymentFlags = (user) => (preferences) => {
  if (!user) return preferences;
  const paymentCapture = user.payment_type === 'null' ? 'none' : user.payment_method;

  return {
    ...preferences,
    payment_capture: paymentCapture
  };
};

const buildActiveSubscriptionFlags = (user) => (preferences) => {
  if (!user) return preferences;
  let active_subscription;
  if (user.is_wine_only) {
    active_subscription = 'wine_only';
  } else {
    active_subscription = user.is_wine_active ? 'meal_and_wine' : 'meal_only';
  }

  return {
    ...preferences,
    active_subscription
  };
};

const buildUserMemberFlags = (user) => (preferences) => {
  if (!user) return preferences;
  return {
    ...preferences,
    member_type: user && user.is_active ? 'member' : 'non_member'
  };
};

const buildWinePlanTypeFlags = (user) => (preferences) => {
  if (!user) return preferences;
  return {
    ...preferences,
    wine_plan_type: user.is_wine_active ? 'mixed' : ''
  };
};

const buildMealPlanTypeFlags = (user) => (preferences) => {
  if (!user) return preferences;
  let mealPlanTypes = '2_person';

  if (user.is_family) {
    if (user.order_quantity === 2) {
      mealPlanTypes = 'family_4_meals';
    } else {
      mealPlanTypes = 'family_2_meals';
    }
  }

  return {
    ...preferences,
    meal_plan_type: mealPlanTypes
  };
};

const buildSiteTests = (preferences) => {
  const activeSiteTests = Object.entries(getEnabledTestsMap() || {}).map(([variationId, stv]) => {
    return stv.name ? `${variationId}_${stv.name}` : variationId;
  });

  return {
    ...preferences,
    site_test: activeSiteTests
  };
};

const getUserProperties = () => {
  let preferences = buildMobileFlags(DEFAULT_PROPERTIES);

  const user = Cookies.getJSON('user_attributes');
  const buildUserInfoFlagsFun = buildUserInfoFlags(user);
  const buildUserPaymentFlagsFun = buildUserPaymentFlags(user);
  const buildActiveSubscriptionFlagsFun = buildActiveSubscriptionFlags(user);
  const buildUserMemberFlagsFun = buildUserMemberFlags(user);
  const buildWinePlanTypeFlagsFun = buildWinePlanTypeFlags(user);
  const buildMealPlanTypeFlagsFun = buildMealPlanTypeFlags(user);

  if (user) {
    preferences = buildUserInfoFlagsFun(preferences);
    preferences = buildUserPaymentFlagsFun(preferences);
    preferences = buildActiveSubscriptionFlagsFun(preferences);
    preferences = buildWinePlanTypeFlagsFun(preferences);
    preferences = buildUserMemberFlagsFun(preferences);
    preferences = buildMealPlanTypeFlagsFun(preferences);
  }

  preferences = buildSiteTests(preferences);

  return preferences;
};

export default getUserProperties;
