import Metrics from 'services/metrics/track-event';
import { TRACK_EVENTS } from 'shared/utils/segment/constants';
import { performTrack, reset } from 'shared/utils/segment/utils';

export const onLogoClick = () => {
  Metrics.trackEvent({
    category: 'Header',
    action: 'Logo'
  });
};

export const trackHeaderClick = (menuItemTrackingName) => {
  Metrics.trackEvent({
    category: 'Header',
    action: `${menuItemTrackingName} Click`
  });
};

export const trackLogout = () => {
  performTrack({ event: TRACK_EVENTS.signedOut }).then(() => {
    reset();
  });
};
