import addVariantsToGiftsCart from 'services/api/graphql/cart/gifts/add-variants-to-agora-cart';
import advanceCheckoutForGiftsCart from 'services/api/graphql/cart/gifts/advance-checkout-for-agora-cart';
import claimGiftsCart from 'services/api/graphql/cart/gifts/claim-agora-cart';
import finalizeGiftsCart from 'services/api/graphql/cart/gifts/finalize-agora-cart';
import fetchActiveGiftsCarts from 'services/api/graphql/cart/gifts/fetch-active-agora-carts';
import fetchGiftsCart from 'services/api/graphql/cart/gifts/fetch-agora-cart';
import initiateCheckoutForGiftsCart from 'services/api/graphql/cart/gifts/initiate-checkout-for-agora-cart';
import removeVariantsFromGiftsCart from 'services/api/graphql/cart/gifts/remove-variants-from-agora-cart';
import setGiftInfoForGiftsCart from 'services/api/graphql/cart/gifts/set-gift-info-for-agora-cart';
import setPaymentInfoForGiftsCart from 'services/api/graphql/cart/gifts/set-payment-info-for-agora-cart';

import addVariantsToMarketCart from 'services/api/graphql/cart/market/add-variants-to-agora-cart';
import advanceCheckoutForMarketCart from 'services/api/graphql/cart/market/advance-checkout-for-agora-cart';
import applyCouponToMarketCart from 'services/api/graphql/cart/market/apply-coupon-to-agora-cart';
import claimMarketCart from 'services/api/graphql/cart/market/claim-agora-cart';
import finalizeMarketCart from 'services/api/graphql/cart/market/finalize-agora-cart';
import fetchActiveMarketCarts from 'services/api/graphql/cart/market/fetch-active-agora-carts';
import fetchMarketCart from 'services/api/graphql/cart/market/fetch-agora-cart';
import initiateCheckoutForMarketCart from 'services/api/graphql/cart/market/initiate-checkout-for-agora-cart';
import removeVariantsFromMarketCart from 'services/api/graphql/cart/market/remove-variants-from-agora-cart';
import setGiftInfoForMarketCart from 'services/api/graphql/cart/market/set-gift-info-for-agora-cart';
import setPaymentInfoForMarketCart from 'services/api/graphql/cart/market/set-payment-info-for-agora-cart';
import setShippingInfoForMarketCart from 'services/api/graphql/cart/market/set-shipping-info-for-agora-cart';
import selectShippingRatesForCartGraphQL from 'services/api/graphql/cart/market/select-shipping-rates-for-agora-cart';
import { GIFT_CART_CONTEXT, FOOD_CART_CONTEXT, MARKET_CONTEXT } from 'shared/constants/constants';

export const formatCartForState = (cart, context) => {
  const {
    id,
    state,
    checkoutState,
    guestToken,
    lineItems,
    price,
    options,
    shipments,
    shippingAddress,
    paymentMethod,
    promotions
  } = cart;

  const formattedVariants = {};
  let contextSpecificAttrs = {};
  let formattedShipments = [];

  switch (context) {
    case GIFT_CART_CONTEXT:
      if (lineItems) {
        lineItems.forEach((item) => {
          const {
            quantity,
            variant: {
              sku,
              variableValues,
              name: { full: category }
            }
          } = item;

          const giftCardValue = variableValues.find((val) => val.variable.name === 'gift_card_value');
          const parsedAmount = giftCardValue ? parseInt(giftCardValue.displayValue, 10) : 0;
          const name = Number.isNaN(parsedAmount) ? '$0' : `$${parsedAmount}`;

          if (formattedVariants[category]) {
            formattedVariants[category].push({ sku, name, quantity });
          } else {
            formattedVariants[category] = [{ sku, name, quantity }];
          }
        });
      }

      contextSpecificAttrs = { variants: formattedVariants };
      break;
    case MARKET_CONTEXT:
      if (shipments) {
        formattedShipments = shipments.map(
          ({ id: shipmentId, lineItems: shipmentLineItems, selectedShippingRate, shippingRates }) => ({
            id: shipmentId,
            variants: shipmentLineItems,
            selectedShippingRate,
            shippingRates
          })
        );
      }

      contextSpecificAttrs = {
        options,
        shipments: formattedShipments,
        lineItems,
        shippingAddress,
        paymentMethod,
        promotions
      };
      break;
    default:
      break;
  }

  return {
    id,
    state,
    checkoutState,
    guestToken,
    price,
    ...contextSpecificAttrs
  };
};

export const addVariantsToAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return addVariantsToGiftsCart(...args);
    case MARKET_CONTEXT:
      return addVariantsToMarketCart(...args);
    default:
      return null;
  }
};

export const advanceCheckoutForAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return advanceCheckoutForGiftsCart(...args);
    case MARKET_CONTEXT:
      return advanceCheckoutForMarketCart(...args);
    default:
      return null;
  }
};

export const applyCouponToAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return null;
    case MARKET_CONTEXT:
      return applyCouponToMarketCart(...args);
    default:
      return null;
  }
};

export const claimAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return claimGiftsCart(...args);
    case MARKET_CONTEXT:
      return claimMarketCart(...args);
    default:
      return null;
  }
};

export const finalizeAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return finalizeGiftsCart(...args);
    case MARKET_CONTEXT:
      return finalizeMarketCart(...args);
    default:
      return null;
  }
};

export const fetchActiveAgoraCarts = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return fetchActiveGiftsCarts(context, ...args);
    case MARKET_CONTEXT:
      return fetchActiveMarketCarts(context, ...args);
    default:
      return null;
  }
};

export const fetchAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return fetchGiftsCart(...args);
    case MARKET_CONTEXT:
      return fetchMarketCart(...args);
    default:
      return null;
  }
};

export const initiateCheckoutForAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return initiateCheckoutForGiftsCart(...args);
    case MARKET_CONTEXT:
      return initiateCheckoutForMarketCart(...args);
    default:
      return null;
  }
};

export const removeVariantsFromAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return removeVariantsFromGiftsCart(...args);
    case MARKET_CONTEXT:
      return removeVariantsFromMarketCart(...args);
    default:
      return null;
  }
};

export const setGiftInfoForAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return setGiftInfoForGiftsCart(...args);
    case MARKET_CONTEXT:
      return setGiftInfoForMarketCart(...args);
    default:
      return null;
  }
};

export const setPaymentInfoForAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return setPaymentInfoForGiftsCart(...args);
    case MARKET_CONTEXT:
      return setPaymentInfoForMarketCart(...args);
    default:
      return null;
  }
};

export const setShippingInfoForAgoraCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return null;
    case MARKET_CONTEXT:
      return setShippingInfoForMarketCart(...args);
    default:
      return null;
  }
};

export const reduxSelectShippingRatesForCart = (context, ...args) => {
  switch (context) {
    case GIFT_CART_CONTEXT:
      return null;
    case MARKET_CONTEXT:
      return selectShippingRatesForCartGraphQL(...args);
    case FOOD_CART_CONTEXT:
      return selectShippingRatesForCartGraphQL(...args);
    default:
      return null;
  }
};
