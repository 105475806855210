// @flow

import fetch from 'isomorphic-fetch';
import normalize from 'json-api-normalizer';
import getConfig from 'next/config';
import appendQueryParams from './utils';

export type Session = {
  email: string,
  token: string
};

export type QueryParams = {
  [string]: string
};

type ApiVersion = 'v20150501' | 'v20161006';

const sessionHeaders = (session: Session) => {
  if (session) {
    const { email, token } = session;
    return {
      'X-BlueApron-Token': token,
      'X-BlueApron-Email': email
    };
  }
  return {};
};

const checkResponse = (response): {} => {
  // 204s have no content so we can just resolve
  if (response.status === 204) {
    return Promise.resolve();
  }

  if (response.ok) {
    return response.json().catch((error) => {
      const errorLog = {
        error,
        response,
        message: 'Unable to parse response json'
      };
      throw errorLog;
    });
  }

  if (response.status >= 500) {
    if (response.headers.get('Content-type').includes('application/json')) {
      return response.json().then((error) => {
        const errorResp = { status: response.status, errors: error };
        throw errorResp;
      });
    }

    // if the response is not JSON, it will be text
    return response.text().then((error) => {
      const errorResp = { status: response.status, errors: error };
      throw errorResp;
    });
  }
  if (response.headers.get('Content-type').includes('application/json')) {
    return response
      .json()
      .then((resp) => {
        const errorResp = {
          status: response.status,
          errors: resp.errors,
          message: resp.message
        };
        throw errorResp;
      })
      .catch((error) => {
        const errorLog = {
          ...error,
          response,
          content: error.message,
          message: 'Unable to parse response json'
        };
        throw errorLog;
      });
  }
  return response
    .text()
    .then((resp) => {
      const errorResp = {
        status: response.status,
        message: resp
      };
      throw errorResp;
    })
    .catch((error) => {
      const errorLog = {
        ...error,
        response,
        content: error.message,
        message: 'Unable to parse response'
      };
      throw errorLog;
    });
};

const apiFetch = (
  path_: string,
  session: Session,
  apiVersion: ApiVersion = 'v20150501',
  queryParams: QueryParams
): Promise<*> => {
  const environmentName = __ENVIRONMENT__;
  const authorizationKey = `Basic ${Buffer.from(`${process.env.API_USERNAME}:${process.env.API_PASSWORD}`).toString(
    'base64'
  )}`;
  const headers =
    environmentName === 'preview' || environmentName === 'staging'
      ? {
          ...sessionHeaders(session),
          Accept: `application/vnd.blueapron.com.${apiVersion}+json`,
          'Content-Type': 'application/json',
          Cache: 'no-cache',
          Authorization: authorizationKey
        }
      : {
          ...sessionHeaders(session),
          Accept: `application/vnd.blueapron.com.${apiVersion}+json`,
          'Content-Type': 'application/json',
          Cache: 'no-cache'
        };

  const path = queryParams ? appendQueryParams(path_, queryParams) : path_;

  return fetch(path, {
    credentials: 'include',
    headers
  }).then(checkResponse);
};

const jsonApiFetch = (path_: string, session: Session, queryParams: QueryParams): Promise<*> => {
  const headers = {
    ...sessionHeaders(session),
    'Content-Type': 'application/vnd.api+json'
  };

  const path = queryParams ? appendQueryParams(path_, queryParams) : path_;

  return fetch(path, {
    credentials: 'include',
    headers
  })
    .then(checkResponse)
    .then(normalize);
};

const apiPost = (path: string, params: {}, apiVersion: ApiVersion = 'v20150501', session: Session): Promise<*> => {
  const headers = {
    ...sessionHeaders(session),
    Accept: `application/vnd.blueapron.com.${apiVersion}+json`,
    'Content-Type': 'application/json'
  };

  return fetch(path, {
    method: 'POST',
    body: JSON.stringify(params),
    credentials: 'same-origin',
    headers
  }).then(checkResponse);
};

const jsonApiPost = (path: string, params: {}, session: Session): Promise<*> => {
  const headers = {
    ...sessionHeaders(session),
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json'
  };

  return fetch(path, {
    method: 'POST',
    body: JSON.stringify(params),
    credentials: 'same-origin',
    headers
  }).then(checkResponse);
};

const apiPut = (path: string, params: {}, apiVersion: ApiVersion = 'v20150501', session: Session): Promise<*> => {
  const headers = {
    ...sessionHeaders(session),
    Accept: `application/vnd.blueapron.com.${apiVersion}+json`,
    'Content-Type': 'application/json'
  };

  return fetch(path, {
    method: 'PUT',
    body: JSON.stringify(params),
    credentials: 'same-origin',
    headers
  }).then(checkResponse);
};

const apiDelete = (path: string, params: {}, apiVersion: ApiVersion = 'v20150501', session: Session): Promise<*> => {
  const headers = {
    ...sessionHeaders(session),
    Accept: `application/vnd.blueapron.com.${apiVersion}+json`,
    'Content-Type': 'application/json'
  };

  return fetch(path, {
    method: 'DELETE',
    body: JSON.stringify(params),
    credentials: 'same-origin',
    headers
  }).then(checkResponse);
};

const makeAbsolute = (method) => {
  return (...args) => {
    const { serverRuntimeConfig } = getConfig();
    if (!serverRuntimeConfig.apiPath) return method(...args);
    const absolutePath = serverRuntimeConfig.apiPath + args[0];
    return method(absolutePath, ...args.slice(1));
  };
};

export { apiFetch as fetch, apiPut, jsonApiFetch, apiPost, jsonApiPost, checkResponse, apiDelete, makeAbsolute };
