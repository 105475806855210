const toKeyValueString = ([key, value]) => `${key}: ${value}`;

const googleAnalytics = ({ category, action, attributes = {}, note = {}, value }) => {
  const eventLabel = [...Object.entries(attributes), ...Object.entries(note)].map(toKeyValueString).join(', ');

  const event = ['send', 'event', category, action, eventLabel];
  if (value) event.push(value);
  if (typeof window === 'undefined' || typeof window.ga === 'undefined') return;
  window.ga(...event);
};

export default googleAnalytics;
