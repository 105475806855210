const optimizelyStateMock = {
  getVariationMap: () => {},
  getExperimentStates: () => {}
};

class OptimizelyWrapper {
  constructor() {
    if (window.optimizely && typeof window.optimizely.get === 'function') {
      this.optimizelyState = window.optimizely.get('state') || optimizelyStateMock;
    } else {
      this.optimizelyState = optimizelyStateMock;
    }
    this.siteTests = {};
  }

  /*
    getVariationMap
    Returns an object where the keys are the experiment ids
    And the values are objects each with the variation name, id, and index
    https://developers.optimizely.com/x/solutions/javascript/reference/index.html#function_getvariationmap
  */
  getVariationMap() {
    return this.optimizelyState.getVariationMap() || {};
  }

  /*
    getExperimentStates
    Returns an object where the keys are the experiment ids
    And the values are the state of every experiment
    https://developers.optimizely.com/x/solutions/javascript/reference/index.html#function_getexperimentstates
  */
  getExperimentStates() {
    return this.optimizelyState.getExperimentStates() || {};
  }

  /*
    inactiveOrHoldbackTests
    If users are in a holdback or the test is not active on the page
  */
  inactiveOrHoldbackTests() {
    return Object.values(this.getExperimentStates() || [])
      .filter(
        ({ variation, isInExperimentHoldback, isActive } = {}) => variation && (isInExperimentHoldback || !isActive)
      )
      .map(({ id }) => id);
  }

  /*
    removeInactiveHoldbackTests
    remove inactive tests or tests a user is in a holdback for
  */
  removeInactiveHoldbackTests(siteTests, inactiveTests) {
    return Object.values(this.siteTests || []).filter(({ id }) => !inactiveTests.includes(id));
  }

  getOptimizelyMap() {
    this.siteTests = this.getVariationMap();

    const inactiveTests = this.inactiveOrHoldbackTests();

    this.siteTests = this.removeInactiveHoldbackTests(this.siteTests, inactiveTests);

    return this.siteTests;
  }
}

export default OptimizelyWrapper;
