// @flow
/*
  @formatPriceAsCurrency
*/
export const formatPriceAsCurrency = (amount) => {
  return parseFloat(amount, 10).toFixed(2);
};

/*
  @formatPriceAsCurrencyInCents
  Takes a string price and returns a number price in cents
*/
export const formatPriceAsCurrencyInCents = (amount) => {
  const currencyInCents = (parseFloat(amount, 10) * 100).toFixed(0); // Avoids decimal numbers with .toFixed
  return parseInt(currencyInCents, 10);
};

/*
  @priceOrFree
  Takes a number amount and returns a string rounded to two decimal points or 'FREE'
*/
export const priceOrFree = (amount) => {
  return amount > 0 ? `$${amount.toFixed(2)}` : 'FREE';
};

export default { formatPriceAsCurrency, formatPriceAsCurrencyInCents, priceOrFree };
