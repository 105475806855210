import formatEventName from './lib/event-name';

import getUserProperties from '../get-user-properties';

export default ({ category, action, attributes = {} }, userProps = getUserProperties()) => {
  if (typeof window === 'undefined' || typeof window.amplitude === 'undefined') return;

  if (userProps) {
    window.amplitude.getInstance().setUserId(userProps.user_id);
    window.amplitude.getInstance().setUserProperties(userProps);
  }
  const name = formatEventName(category, action);
  return window.amplitude.getInstance().logEvent(name, attributes);
};
