import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';

// eslint-disable-next-line import/prefer-default-export
const claimAgoraCart = (cartId, currentOwner, email) => {
  /* eslint-disable prettier/prettier */
  return client
    .mutate({
      mutation: gql`
        mutation ClaimCart($input: ClaimCartInput!) {
          claimCart(input: $input) {
            cart {
              id
              checkoutState
              state
              guestToken
              price {
                discount {
                  displayValue
                }
                estimated
                shipping {
                  displayValue
                }
                subtotal {
                  displayValue
                }
                tax {
                  displayValue
                }
                total {
                  displayValue
                }
              }
              lineItems {
                quantity
                variant {
                  core
                  description
                  displayPrice {
                    formattedPrice
                  }
                  displayPriority
                  name {
                    full
                  }
                  price {
                    original {
                      displayValue
                    }
                  }
                  sku
                  variableValues {
                    displayValue
                    value
                    variable {
                      description
                      displayName
                      name
                      type
                    }
                  }
                }
              }
            }
            clientErrors {
              message
            }
          }
        }
      `,
      variables: {
        input: {
          cartId,
          email,
          currentOwner
        }
      }
    })
    .then((result) => {
      const {
        data: { claimCart }
      } = result;
      const { clientErrors, cart } = claimCart;
      return { cart, clientErrors };
    });
  /* eslint-enable prettier/prettier */
};

export default claimAgoraCart;
