// import isEmpty from 'lodash/isEmpty';
// import { createSelector } from 'reselect';

// import {
//   createSubscription as createSubscriptionApi,
//   fetchSubscriptions as fetchSubscriptionsApi,
//   updateSubscription as updateSubscriptionApi,
//   deactivateSubscription as deactivateSubscriptionApi,
//   reactivateSubscription as reactivateSubscriptionApi,
//   fetchPrices as fetchPricesApi
// } from 'services/api/subscription';
// import {
//   fetchUser,
//   getMealSubscription,
//   getWineSubscription,
//   isWineOnly,
//   updateUserSubscriptions,
//   refreshCredits
// } from 'redux/reducers/user';
// import { hasFeature } from 'redux/reducers/configurations';
// import { FOOD, WINE, MEALS } from 'components/common/constants';
// import pauseSubscriptionsApi from 'account/upcoming/api/graph-ql/pause-subscriptions';
// import unpauseSubscriptionsApi from 'account/upcoming/api/graph-ql/unpause-subscriptions';
// import { startRequest, completeRequest, failRequest } from 'redux/reducers/requests';
// import { showError, showSuccess, DISPLAY_METHODS } from 'redux/reducers/notices';
// import { refreshAfterUpdates } from 'redux/reducers/upcoming';
// import { PLAN_IDS, PLAN_QUANTITIES } from 'utils/constants/plans';
// import { getFoodPlans, getPlanVariations, getPlanPreferences, getUserPlanPreferences } from 'shared/utils/plans';
// import parseError from 'utils/error-handling/parse-errors';
// import camelize from 'shared/utils/camelize';
// import { objectConvertCamelToSnakeCase } from 'utils/string-utils/string-utils';
// import { prepareSurveyAnswers } from 'react/account/cancelSubscriptions/utilities/helpers';
// import { ERROR_MESSAGES } from 'react/common/messages';

// import { generateReactivationSuccessMessage } from './utilities';

// export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
// export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
// export const SET_PRICES = 'SET_PRICES';
// export const UPDATE_SUBSCRIPTION_DATA = 'UPDATE_SUBSCRIPTION_DATA';

// const initialState = {
//   food: undefined,
//   wine: undefined
// };

// export default (state = initialState, action) => {
//   switch (action.type) {
//     case SET_SUBSCRIPTION: {
//       const newState = {};
//       if (state.food || action.payload.food) {
//         newState.food = {
//           ...state.food,
//           ...action.payload.food
//         };
//       }
//       if (state.wine || action.payload.wine) {
//         newState.wine = {
//           ...state.wine,
//           ...action.payload.wine
//         };
//       }
//       return newState;
//     }
//     case SET_SUBSCRIPTIONS: {
//       const { food, wine } = action.payload;

//       return {
//         ...state,
//         food,
//         wine
//       };
//     }
//     case SET_PRICES: {
//       const { prices } = action.payload;

//       return { ...state, prices };
//     }
//     case UPDATE_SUBSCRIPTION_DATA: {
//       const { data, packageType } = action.payload;

//       return {
//         ...state,
//         [packageType]: { ...state[packageType], ...data }
//       };
//     }
//     default: {
//       return state;
//     }
//   }
// };

// export const setSubscription = (subscription) => {
//   const planId = String(subscription.plan_id);
//   const payload = planId === '4' ? { wine: subscription } : { food: subscription };
//   return {
//     type: SET_SUBSCRIPTION,
//     payload
//   };
// };

// export const updateSubscriptionData = (packageType, data) => ({
//   type: UPDATE_SUBSCRIPTION_DATA,
//   payload: { data, packageType }
// });

// export const setSubscriptions = (subscriptions) => {
//   const foodSubscription = subscriptions.find((s) => {
//     const planId = String(s.plan_id);
//     return planId === '1' || planId === '3' || planId === '5';
//   });
//   const wineSubscription = subscriptions.find((s) => {
//     const planId = String(s.plan_id);
//     return planId === '4';
//   });
//   const payload = {};

//   if (foodSubscription) {
//     payload.food = foodSubscription;
//   }

//   if (wineSubscription) {
//     payload.wine = wineSubscription;
//   }

//   return {
//     type: SET_SUBSCRIPTIONS,
//     payload
//   };
// };

// export const setPrices = (prices) => {
//   return {
//     type: SET_PRICES,
//     payload: { prices }
//   };
// };

// export const createSubscription = (subscription) => {
//   const key = 'createSubscription';

//   return (dispatch) => {
//     dispatch(startRequest(key));

//     return createSubscriptionApi(subscription)
//       .then((response) => {
//         dispatch(completeRequest(key));
//         dispatch(setSubscription(response.subscription));
//         return Promise.resolve();
//       })
//       .catch((response) => {
//         const defaultErrorText = 'Subscription POST failed';
//         if (!response) {
//           dispatch(showError(key, defaultErrorText));
//           return Promise.reject(defaultErrorText);
//         }
//         if (response.status >= 500) {
//           dispatch(showError(key, defaultErrorText));
//         }
//         dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

//         return Promise.reject(parseError(response));
//       });
//   };
// };

// export const updateSubscription = (subscription) => {
//   const key = 'updateSubscription';

//   return (dispatch) => {
//     dispatch(startRequest(key));

//     return updateSubscriptionApi(subscription)
//       .then((response) => {
//         dispatch(completeRequest(key));
//         dispatch(setSubscription(response.subscription));
//         return Promise.resolve();
//       })
//       .catch((response) => {
//         const defaultErrorText = 'Subscription PUT failed';
//         if (!response) {
//           dispatch(showError(key, defaultErrorText));
//           return Promise.reject(defaultErrorText);
//         }
//         if (response.status >= 500) {
//           dispatch(showError(key, defaultErrorText));
//         }
//         dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

//         return Promise.reject(parseError(response));
//       });
//   };
// };

// export const createOrUpdateSubscription = () => {
//   return (dispatch, getState) => {
//     const state = getState();
//     const sub = state.subscription;
//     const { user } = state;
//     const key = user.isWineRegistration ? WINE : FOOD;
//     const subscription = {
//       ...sub[key],
//       accepted_arl: true
//     };
//     if (subscription && subscription.id) {
//       if (key === FOOD) {
//         const foodPlans = getFoodPlans(state.plans.plansById);
//         const foodPlanVariations = getPlanVariations(foodPlans);
//         const preferences = hasFeature(getState(), 'bapa_enabled')
//           ? getUserPlanPreferences(user)
//           : getPlanPreferences(foodPlanVariations, user.plan.id, user.planApiName);
//         subscription.preferences = preferences;
//       }
//       return dispatch(updateSubscription(subscription));
//     }

//     return dispatch(createSubscription(subscription));
//   };
// };

// export const fetchSubscriptions = () => {
//   const key = 'fetchSubscriptions';

//   return (dispatch) => {
//     dispatch(startRequest(key));

//     return fetchSubscriptionsApi()
//       .then((response) => {
//         dispatch(completeRequest(key));
//         dispatch(setSubscriptions(response.subscriptions));
//         return Promise.resolve();
//       })
//       .catch((response) => {
//         const defaultErrorText = 'Subscription GET failed';
//         if (!response) {
//           dispatch(showError(key, defaultErrorText));
//           return Promise.reject(defaultErrorText);
//         }
//         if (response.status >= 500) {
//           dispatch(showError(key, defaultErrorText));
//         }
//         dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

//         return Promise.reject(parseError(response));
//       });
//   };
// };

// export const activateSubscription = (subscription) => {
//   return (dispatch, getState) => {
//     const sub = getState().subscription.food;
//     if (isEmpty(sub)) {
//       return dispatch(createSubscription(subscription));
//     }
//     return dispatch(updateSubscription({ ...subscription, id: sub.id, accepted_arl: true }));
//   };
// };

// export const deactivateSubscriptions = ({
//   surveys,
//   selectedOptions,
//   additionalComment,
//   subscriptions,
//   successMessage,
//   errorMessage,
//   isAccountToBeDeactivated,
//   next
// }) => async (dispatch) => {
//   try {
//     await Promise.all(
//       surveys.deliveries.map((delivery) =>
//         deactivateSubscriptionApi(
//           subscriptions[delivery].id,
//           prepareSurveyAnswers(delivery, selectedOptions, additionalComment)
//         )
//       )
//     );

//     if (!isAccountToBeDeactivated) {
//       await dispatch(refreshCredits());
//       await dispatch(fetchUser());
//       await dispatch(fetchSubscriptions());
//       await dispatch(refreshAfterUpdates());
//       await dispatch(showSuccess('deactivate_subscription_success', successMessage, DISPLAY_METHODS.FLASH));
//     }

//     next();
//   } catch (e) {
//     dispatch(showError('deactivate_subscription_error', errorMessage));
//   }
// };

// export const deactivateSubscription = ({
//   isOnlySubscription,
//   survey,
//   subscription,
//   successMessage,
//   errorMessage,
//   next
// }) => async (dispatch) => {
//   try {
//     await deactivateSubscriptionApi(subscription, survey);

//     if (!isOnlySubscription) {
//       await dispatch(refreshCredits());
//       await dispatch(fetchUser());
//       await dispatch(fetchSubscriptions());
//       await dispatch(showSuccess('deactivate_subscription_success', successMessage, DISPLAY_METHODS.FLASH));
//       await dispatch(refreshAfterUpdates());
//     }
//     next();
//   } catch (e) {
//     dispatch(showError('deactivate_subscription_error', errorMessage));
//   }
// };

// export const pauseSubscriptions = (params) => async (dispatch) => {
//   try {
//     const { next, ...rest } = params;
//     const { subscriptionId, packageType } = rest;
//     const { subscription, clientErrors, pauseDuration } = await pauseSubscriptionsApi(params);
//     if (clientErrors && clientErrors.length) {
//       throw Error;
//     } else {
//       let data = { ...objectConvertCamelToSnakeCase(subscription), pauseDuration };
//       data = {
//         ...data,
//         status: (data.status || '').toLowerCase()
//       };
//       dispatch(updateSubscriptionData(packageType, data));
//       dispatch(updateUserSubscriptions(subscriptionId, data));
//       dispatch(refreshAfterUpdates());
//       if (next) next();
//     }
//   } catch (e) {
//     dispatch(showError('pauseSubscriptions', ERROR_MESSAGES.COMMON, DISPLAY_METHODS.FLASH));
//   }
// };

// export const unpauseSubscriptions = (params) => async (dispatch) => {
//   try {
//     const { subscriptionId, packageType } = params;
//     const { subscription, clientErrors } = await unpauseSubscriptionsApi(params);
//     if (clientErrors && clientErrors.length) {
//       throw Error;
//     } else {
//       let data = objectConvertCamelToSnakeCase(subscription);
//       data = {
//         ...data,
//         status: (data.status || '').toLowerCase()
//       };
//       dispatch(updateSubscriptionData(packageType, data));
//       dispatch(updateUserSubscriptions(subscriptionId, data));
//       dispatch(refreshAfterUpdates());
//     }
//   } catch (e) {
//     dispatch(showError('unpauseSubscriptions', ERROR_MESSAGES.COMMON, DISPLAY_METHODS.FLASH));
//   }
// };

// export const reactivateSubscriptions = ({ discountCopy, errorMessage, subscriptions }) => async (
//   dispatch,
//   getState
// ) => {
//   try {
//     await Promise.all(subscriptions.map((subscription) => reactivateSubscriptionApi(subscription)));

//     await dispatch(refreshCredits());
//     await dispatch(fetchUser());
//     await dispatch(fetchSubscriptions());
//     await dispatch(refreshAfterUpdates());

//     const state = getState();
//     dispatch(
//       showSuccess(
//         'reactivate_subscription_success',
//         generateReactivationSuccessMessage(discountCopy, {
//           mealSubscription: getMealSubscription(state),
//           wineSubscription: getWineSubscription(state),
//           isWineOnly: isWineOnly(state)
//         }),
//         DISPLAY_METHODS.FLASH,
//         0,
//         false,
//         true
//       )
//     );
//   } catch (e) {
//     dispatch(showError('reactivate_subscription_error', errorMessage));
//     throw Error;
//   }
// };

// export const fetchPrices = (subscription, firstDeliveryWindow, zipCode = null) => {
//   const key = 'fetchPrices';

//   return (dispatch) => {
//     dispatch(startRequest(key));

//     return fetchPricesApi(subscription, firstDeliveryWindow, zipCode)
//       .then((response) => {
//         dispatch(completeRequest(key));
//         dispatch(setPrices(response.prices));
//         return Promise.resolve();
//       })
//       .catch((response) => {
//         const defaultErrorText = 'Subscription Pricing GET failed';
//         if (!response) {
//           dispatch(showError(key, defaultErrorText));
//           return Promise.reject(defaultErrorText);
//         }
//         if (response.status >= 500) {
//           dispatch(showError(key, defaultErrorText));
//         }
//         dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

//         return Promise.reject(parseError(response));
//       });
//   };
// };

// /*
//   Selectors
// */

// const rootSelector = (state) => state;
// export const getSubscriptionParams = (state, params) => params;
// export const getSubscriptions = createSelector(rootSelector, ({ subscription }) => subscription);

// export const getSubscriptionForPlanType = (state, planType) => {
//   return state.subscription[planType];
// };

// export const getSubscriptionPlan = (state, planType) => {
//   const subscription = getSubscriptionForPlanType(state, planType);
//   const plan = subscription ? subscription.plan : {};
//   return plan;
// };

// export const getRecipesPerDelivery = (state, planType = FOOD) => {
//   const subscription = getSubscriptionForPlanType(state, planType);
//   return subscription && subscription.products_per_delivery;
// };

// export const getConversionId = (state) => {
//   const plan = state.user.isWineRegistration ? WINE : FOOD;
//   return (state.subscription && state.subscription[plan] && state.subscription[plan].conversion_id) || undefined;
// };

// export const hasWineSubscription = (state) => {
//   return state.subscription && !isEmpty(state.subscription.wine);
// };

// export const getNextOrderIdFromSubscription = (state, planType = FOOD) => {
//   const subscription = getSubscriptionForPlanType(state, planType);
//   return subscription && subscription.next_order && subscription.next_order.id;
// };

// export const isFamilyFourPlan = (state) => {
//   const foodSubscription = getSubscriptionForPlanType(state, FOOD);
//   const isFamilyPlan = foodSubscription.plan.id === PLAN_IDS.FAMILY_PLAN;
//   const isFourServings = foodSubscription.products_per_delivery === PLAN_QUANTITIES.FOUR;

//   return isFamilyPlan && isFourServings;
// };

// export const isSubscriptionIncomplete = (subscription) => {
//   return subscription && subscription.status === 'incomplete';
// };

// export const getMealSubscriptionPrices = (state) => {
//   return state && state.subscription && state.subscription.prices;
// };

// export const hasMealSubscriptionPrices = (state) => {
//   return !!getMealSubscriptionPrices(state);
// };

// export const getMealSubscriptionWithTax = (state) => {
//   if (!state.subscription && !state.subscription.prices) return null;
//   const { tax, total } = state.subscription.prices;
//   return { tax, total };
// };

// export const getHasMealTax = (state) => {
//   return !!state.subscription && !!state.subscription.prices && !!state.subscription.prices.tax;
// };

// export const getPauseOptions = createSelector(
//   getSubscriptionParams,
//   getSubscriptions,
//   ({ packageType }, subscriptions) => {
//     const { pause_begins_on = null, pause_ends_on = null, pause_set = false, pauseDuration = {} } =
//       (packageType === MEALS ? subscriptions.food : subscriptions[packageType]) || {};

//     return camelize({ pause_begins_on, pause_ends_on, pause_set, pauseDuration: pauseDuration.displayText });
//   }
// );

// export const getPauseOptionsSelector = createSelector(getPauseOptions, (pauseOptions) => pauseOptions);
