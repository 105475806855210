const urlParameters = (search) => {
  return (search || window.location.search)
    .substr(1)
    .split('&')
    .reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      if (!key || !value) return acc;
      return {
        ...acc,
        [key]: decodeURIComponent(value)
      };
    }, {});
};

export default urlParameters;
