import React from 'react';
import { bool, string, arrayOf, func } from 'prop-types';
import cx from 'classnames';
import Checkmark from '../icons/Checkmark/Checkmark';
import Ex from '../icons/Ex/Ex';

const InputConditions = ({ showErrors, validator, value }) => (
  <div
    className={cx('pom-InputConditions', {
      'pom-InputConditions--error': showErrors
    })}
    data-testid="signupPasswordConditions"
  >
    {validator.map((validatorCriteria) => {
      const { valid: valueIsValid, message } = validatorCriteria(value);
      const icon =
        !valueIsValid && showErrors ? (
          <Ex width="12" color="#d64148" />
        ) : (
          <Checkmark
            hideOutline={true}
            height="10"
            width="14"
            strokeWidth={valueIsValid ? '3' : '2'}
            viewBox="10 10 12 12"
            stroke={valueIsValid ? '#00AF7D' : ' #b1b5bd'}
          />
        );
      return (
        <div
          className={cx('pom-InputCondition', {
            'pom-InputCondition--valid': valueIsValid
          })}
          key={message}
          data-testid={message.replace(/\s/g, '-')}
        >
          {icon}
          <span className="pom-InputCondition-message">{message}</span>
        </div>
      );
    })}
  </div>
);

InputConditions.propTypes = {
  showErrors: bool,
  validator: arrayOf(func),
  value: string
};

InputConditions.defaultProps = {
  showErrors: false,
  validator: [],
  value: ''
};

export default InputConditions;
