import { gql } from '@apollo/client';

import { client } from 'shared/utils/graphql-helpers';

import { IMAGE_FIELDS } from '../fragments/image-fields';

const fetchGraphQlProduct = (sku) => {
  /* eslint-disable prettier/prettier */
  return client
    .query({
      query: gql`
        query variant($sku: ID!) {
          variant(sku: $sku) {
            description
            displayPriority
            sku
            url
            ... on Recipe {
              nutritionInfo {
                estimatedServingCalories
                accurateServingCalories
                detailLabel {
                  ...ImageFields
                }
              }
              times {
                overall {
                  average
                  max
                  min
                }
              }
              ingredients {
                description
                displayPriority
              }
            }
            name {
              main
              sub
            }
            badges {
              description
              displayName
              displayPriority
              id
              name
              type
              image {
                ...ImageFields
              }
            }
            images {
              primary {
                ...ImageFields
              }
            }
          }
        }
        ${IMAGE_FIELDS}
      `,
      variables: {
        sku
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    })
    .then(({ data }) => data.variant);
};

export default fetchGraphQlProduct;
