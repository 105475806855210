/* istanbul ignore file */
import Metrics from 'services/metrics/track-event';
import trackGoogleTagManager from 'services/metrics/track-google-tag-manager';
import MobileDetect from 'mobile-detect';
import { getOrSetUserSessionIdForGTMCookie } from 'shared/utils/user-session-id-gtm-cookie';
import capitalize from 'shared/utils/capitalize';

export const onEmailEdit = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Order Summary Email Change Link Click'
  });
};

export const onPlanSelectionClick = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Order Summary Plan Change Click or Tap'
  });
};

export const trackPlaceOrderClick = (attributes) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Place Order Click',
    attributes: { ...attributes }
  });
};

export const trackContinueButtonClick = (attributes) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Email Capture Modal',
    action: 'Continue Button Click',
    attributes: { ...attributes }
  });
};

export const trackBillingFieldFocus = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Billing Field Focus'
  });
};

export const trackSocialSignupApple = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Sign in with Apple Activated'
  });
};

export const trackSocialSignupFacebook = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Sign in with Facebook activated'
  });
};

export const trackWinePlaceOrderClick = (attributes) => {
  Metrics.trackEvent({
    category: 'Wine Registration Flow - Complete Order Page',
    action: 'Place Order Click',
    attributes: { ...attributes }
  });
};

export const trackWinePageView = (attributes) => {
  Metrics.trackEvent({
    category: 'Wine Registration Flow - Complete Order Page',
    action: 'Page View',
    attributes: {
      coupon_code: attributes.couponCode,
      coupon_applied: attributes.couponApplied
    }
  });
};

export const trackForgottenPassword = ({ email }) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Place Order Click',
    attributes: {
      forgotten_password: true,
      email
    }
  });
};

export const trackDeliveryInformationTap = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Order Summary Delivery Information Drawer Tap'
  });
};

export const trackPlaceOrderValidationError = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Place Order Validation Error'
  });
};

export const trackValidateAddressSuccessful = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Validate address successful'
  });
};

export const trackUpdatePaymentSuccessful = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Update payment successful'
  });
};

export const trackCreateOrUpdateAddressSuccessful = (type) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: `${type} address successful`
  });
};

export const trackSubscriptionActivationSuccessful = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Subscription activation successful'
  });
};

export const trackRegistrationError = (action, errorMessage) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action,
    attributes: {
      error_message: errorMessage
    }
  });
};

const getProductAttributes = (planName, planPrice, isVegetarian, selectedPlanQuantity, category) => {
  const isMealPlanEvent = category === 'Meal Plan';
  const products = {
    id: planName.replace(/ /g, '_'),
    price: planPrice,
    brand: planName,
    name: planName,
    category,
    quantity: 1
  };
  const mealPlanTrackingAttributes = {
    variant: `${selectedPlanQuantity} recipes per week`,
    dimension11: isVegetarian ? 'vegetarian' : 'non-vegetarian'
  };
  return isMealPlanEvent ? { ...products, ...mealPlanTrackingAttributes } : products;
};

export const trackGTMCheckoutEvents = (
  selectedPlanQuantity,
  isVegetarian,
  planName,
  planPrice,
  userId,
  userHashedIds,
  coupon,
  subscriptionId,
  category,
  actionField
) => {
  const isMealPlanEvent = category === 'Meal Plan';
  const products = [getProductAttributes(planName, planPrice, isVegetarian, selectedPlanQuantity, category)];
  const event = isMealPlanEvent ? 'MealRegCheckout' : 'WineCheckout';
  const ecommerce = {
    checkout: {
      actionField,
      products
    }
  };

  trackGoogleTagManager({
    event,
    userSessionId: getOrSetUserSessionIdForGTMCookie(),
    ecommerce,
    userId,
    userIdSha256: (userHashedIds && userHashedIds.id_256) || '',
    coupon: coupon || 'not available',
    subscriptionId
  });
};

export const trackCheckoutPageView = ({ selectedPlanId, selectedPlanQuantity, category }) => {
  const isMealPlanEvent = category === 'Meal Plan';
  if (isMealPlanEvent) {
    Metrics.trackEvent({
      category: 'Meal Registration Flow - Checkout Page',
      action: 'Page View',
      attributes: {
        plan_type: selectedPlanId,
        plan_quantity: selectedPlanQuantity
      }
    });

    const md = new MobileDetect(navigator.userAgent);
    const iOSsafari = md.is('iOS') && md.is('Safari');
    const androidChrome = md.is('Android') && md.is('Chrome');

    if (window.PaymentRequest) {
      if (window.ApplePaySession && iOSsafari) {
        Metrics.trackEvent({
          category: 'Meal Registration Flow - Checkout Page',
          action: 'Apple Pay Shown',
          attributes: {
            type: 'Control'
          }
        });
      } else if (androidChrome) {
        Metrics.trackEvent({
          category: 'Meal Registration Flow - Checkout Page',
          action: 'Google Pay Shown',
          attributes: {
            type: 'Control'
          }
        });
      }
    }
  }
};

export const trackCheckoutConfirmationEvent = (
  userId,
  conversionId,
  coupon,
  totalOrderAmount,
  discountApplied,
  tax,
  shownPrice,
  shippingFee,
  displayName,
  userHashedIds,
  subscriptionId,
  selectedPlanId,
  category,
  userEmail,
  selectedPlanQuantity = null,
  isVegetarianUser = null
) => {
  const products = [
    getProductAttributes(displayName, shownPrice, selectedPlanId, isVegetarianUser, selectedPlanQuantity, category)
  ];
  const purchase = {
    actionField: {
      id: conversionId,
      affiliation: category,
      revenue: totalOrderAmount,
      tax: tax || '0.00',
      shipping: shippingFee,
      coupon: (coupon && coupon.code) || ''
    },
    products
  };
  const event = category === 'Meal Plan' ? 'MealRegConfirmation' : 'WineConfirmView';
  const mealPlanAttribute = { isTrial: Boolean(coupon) && coupon.type === 'invite' };
  const conversionItems = products.map(({ price, quantity, name }) => ({ sku: '', product_id: name, price, quantity }));

  let attributes = {
    event,
    userId,
    email: userEmail,
    userIdSha256: userHashedIds.id_256,
    userSessionId: getOrSetUserSessionIdForGTMCookie(),
    conversionId,
    subscriptionId,
    orderAmount: totalOrderAmount,
    discountApplied,
    couponCode: (coupon && coupon.code) || '',
    shippingFee,
    ecommerce: {
      purchase
    },
    wunderkindConversion: {
      discount: discountApplied,
      coupons: coupon && coupon.code ? [coupon.code] : [],
      tax,
      paymentTotal: totalOrderAmount,
      items: conversionItems,
      id: conversionId
    }
  };
  attributes = category === 'Meal Plan' ? { ...attributes, ...mealPlanAttribute } : attributes;
  trackGoogleTagManager(attributes);
};

export const trackPaymentExperiment = (payment) => {
  if (payment) {
    Metrics.trackEvent({
      category: 'Meal Registration Flow - Checkout Page',
      action: `${payment} Pay Shown`,
      attributes: {
        type: 'Test'
      }
    });
  } else {
    const paymentType = window.ApplePaySession ? 'Apple' : 'Google';
    Metrics.trackEvent({
      category: 'Meal Registration Flow - Checkout Page',
      action: `${paymentType} Pay Not Shown`
    });
  }
};

export const trackCreateOrUpdateUserSuccessful = (type) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: `${type} user successful`
  });
};

export const trackApplyCouponSuccessful = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Apply coupon successful'
  });
};

export const trackErrorPageView = () => {
  Metrics.trackEvent({
    category: 'Error Page',
    action: 'Page View'
  });
};

export const trackEmailInUserError = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Address Already In Use Error'
  });
};

export const trackMealPickerPageView = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Meal Picker Page',
    action: 'Page View'
  });
};

export const trackZipValidationError = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Place Order Zip Validation Error'
  });
};

export const trackGTMPlansView = (mealPlans, user, coupon, subscriptionId) => {
  const planInfo = mealPlans.reduce((arr, plan) => {
    const planDisplayName = plan.display.displayNameVariation || plan.displayName;
    arr.push({
      name: planDisplayName,
      id: planDisplayName.replace(/ /g, '_'),
      price: plan.options[plan.defaultProductsPerOrder].price.total,
      brand: planDisplayName,
      category: 'Meal Plan'
    });

    return arr;
  }, []);

  trackGoogleTagManager({
    event: 'MealRegPref',
    userId: user.id || '',
    userIdSha256: user.hashed_ids ? user.hashed_ids.id_256 : '',
    userSessionId: user.id ? getOrSetUserSessionIdForGTMCookie() : '',
    coupon: coupon ? coupon.code : '',
    subscriptionId: subscriptionId || '',
    ecommerce: {
      currencyCode: 'USD',
      impressions: planInfo,
      detail: {
        actionField: { list: 'Meal Plan' },
        products: planInfo
      }
    }
  });
};

export const trackPreferencesPageView = (couponApplied) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Preferences Page',
    action: 'Page View',
    attributes: {
      coupon_applied: String(couponApplied)
    }
  });
};

export const trackProductsPerDeliveryChange = (attributes) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Preferences Page',
    action: 'Products per Delivery Change',
    attributes: {
      quantity: attributes.quantity,
      plan_id: attributes.planId
    }
  });
};

export const trackPreferenceToggleClick = ({ planId, preference, enabled }) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Preferences Page',
    action: 'Preference Toggle Click',
    attributes: {
      plan_id: planId,
      preference,
      enabled
    }
  });
};

export const trackMealRegPlanId = ({
  planType,
  quantity,
  isVegetarian,
  price,
  planName,
  user,
  coupon,
  subscriptionId,
  planVariationName
}) => {
  const variationName = planVariationName ? capitalize(planVariationName) : '';
  const event = `MealRegPlanId${variationName}${planType}`;
  const ecommerce = {
    currencyCode: 'USD',
    add: {
      products: [
        {
          id: planName.replace(/ /g, '_'),
          price,
          brand: planName,
          name: planName,
          category: 'Meal Plan',
          variant: `${quantity} recipes per week`,
          dimension11: isVegetarian ? 'vegetarian' : 'non-vegetarian',
          quantity: 1
        }
      ]
    }
  };

  trackGoogleTagManager({
    event,
    isVegetarian,
    userId: user.id || '',
    userIdSha256: user.hashed_ids ? user.hashed_ids.id_256 : '',
    coupon: coupon ? coupon.code : '',
    subscriptionId: subscriptionId || '',
    ecommerce
  });
};

export const trackSelectPlanClick = ({ planType, quantity }) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Preferences Page',
    action: 'Select Plan Click',
    attributes: {
      plan_type: planType,
      quantity
    }
  });
};

export const trackPreferencesPageButtonClick = ({ planType, planName }) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Preferences Page',
    action: 'Continue to Checkout Button Click',
    attributes: {
      plan_type: planType,
      plan_name: planName
    }
  });
};

export const trackSavePlanButtonTap = () => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Preferences Page',
    action: 'Select Your Plan Save Button Tap'
  });
};

export const trackPayAddressChange = (paymentType) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: `${paymentType} Pay Address Change`
  });
};

export const trackGoogleApplePayError = (error) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: 'Google/Apple Pay Error',
    attributes: {
      error
    }
  });
};

export const trackReactFlow = (planType) => {
  Metrics.trackEvent({
    category: `${planType} Registration Flow - Checkout Page`,
    action: 'Checkout via React flow'
  });
};

export const trackGoogleApplePaySubmit = (paymentType) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: `${paymentType} Pay Submit`
  });
};

export const trackGoogleApplePayTapped = (paymentType) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: `${paymentType} Pay Tapped`
  });
};

export const trackMultipleAddresses = (addresses) => {
  Metrics.trackEvent({
    category: 'Meal Registration Flow - Checkout Page',
    action: `Multiple incomplete addresses`,
    attributes: {
      addresses
    }
  });
};

export default {
  onEmailEdit,
  onPlanSelectionClick,
  trackPlaceOrderClick,
  trackContinueButtonClick,
  trackWinePlaceOrderClick,
  trackWinePageView,
  trackForgottenPassword,
  trackDeliveryInformationTap,
  trackPlaceOrderValidationError,
  trackValidateAddressSuccessful,
  trackUpdatePaymentSuccessful,
  trackCreateOrUpdateAddressSuccessful,
  trackSubscriptionActivationSuccessful,
  trackRegistrationError,
  trackCheckoutPageView,
  trackPaymentExperiment,
  trackCreateOrUpdateUserSuccessful,
  trackApplyCouponSuccessful,
  trackErrorPageView,
  trackEmailInUserError,
  trackMealPickerPageView,
  trackZipValidationError,
  trackPreferencesPageView,
  trackPreferencesPageButtonClick,
  trackSavePlanButtonTap,
  trackPayAddressChange,
  trackGoogleApplePayError,
  trackReactFlow,
  trackGoogleApplePaySubmit,
  trackGoogleApplePayTapped,
  trackMultipleAddresses
};
