// @flow

import { fetch, apiDelete, apiPost, apiPut } from 'services/fetch';

const fetchUser = (session) => {
  return fetch('/api/users', session).then((response) => response.user);
};

const registerUser = (data) => {
  return apiPost('/api/devise/registrations', {
    user: data,
    strict: false // allows login if email/password match existing
  });
};

const updateUser = (user) => {
  return apiPut('/api/users', {
    user
  });
};

const deviseSessions = (user) => apiPost('/api/devise/sessions', user);

export const signUpUser = (email, password) => {
  const params = {
    user: {
      password,
      email
    },
    strict: false // allows login if email/password match existing
  };

  return apiPost('/api/devise/registrations', params);
};

export const registerUserWithFacebook = (email, fbToken) => {
  const params = {
    facebook_token: fbToken,
    user: {
      email
    }
  };

  return apiPost('/api/users/facebook_connect/register', params);
};

const deleteSessions = (user) => apiDelete('/api/devise/sessions', user);

const updatePassword = (user) => apiPost('/api/users/change_password', user);

const fetchExpiringCredits = () => fetch('/api/users/coupons/churn_loss');

export { deleteSessions, deviseSessions, fetchUser, registerUser, updateUser, updatePassword, fetchExpiringCredits };
