import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const applyCouponToAgoraCart = (cartId, couponCode, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client.mutate({
    mutation: gql`
      mutation ApplyCouponToMarketCart($input: ApplyCouponToCartInput!) {
        applyCouponToCart(input: $input) {
          cart {
            ...MarketCartFields
          }
          clientErrors {
            message
          }
        }
      }
      ${MARKET_CART_FIELDS}
    `,
    variables: {
      input: {
        cartId,
        couponCode,
        ownerId
      }
    }
  })
  .then((result) => {
    const { data: { applyCouponToCart } } = result;
    const { clientErrors, cart } = applyCouponToCart;
    return { cart, clientErrors };
  });
  /* eslint-enable prettier/prettier */
};

export default applyCouponToAgoraCart;
