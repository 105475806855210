import throttle from 'lodash/throttle';

import { fetchConfigurations } from 'redux/reducers/configurations/configurations-thunks';
import { fetchAppConfiguration } from 'redux/reducers/app-configurations';
import { setFromStorage, saveState } from './initial-state/storage';
import configureStore from './configureStore';

const store = configureStore();

store.dispatch(setFromStorage());
store.dispatch(fetchConfigurations());
store.dispatch(fetchAppConfiguration());

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

export default store;
