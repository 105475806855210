/* global __EVERGREEN_PROMO_CODE__ */
import Cookies from 'js-cookie';

import {
  fetchCoupons as fetchCouponsApi,
  fetchCoupon as fetchCouponApi,
  applyCoupons as applyCouponsApi
} from 'services/api/coupons';
import { showError } from 'redux/reducers/notices';
import { startRequest, completeRequest, failRequest } from 'redux/reducers/requests';
import parseError from 'shared/utils/error-handling/parse-errors';
import { performTrack } from 'shared/utils/segment/utils';
import { TRACK_EVENTS } from 'shared/utils/segment/constants';
import { setLastCouponFetched } from 'redux/reducers/plans/plans-actions';
import { isEvergreenPromotionEnabled } from 'redux/reducers/configurations/configurations-selectors';

import { setCoupons } from './coupons-actions';

export const PRICING_AB_TEST_NAME = '2023 New User Pricing';

export const fetchCoupons = (scope = 'candidate') => {
  return (dispatch, getState) => {
    const { session } = getState();

    return fetchCouponsApi(session, { scope })
      .then((coupons) => {
        dispatch(setCoupons(coupons));
        return Promise.resolve();
      })
      .catch((response) => {
        return Promise.reject(parseError(response));
      });
  };
};

export const fetchCoupon = () => {
  const key = 'fetchCoupon';

  return (dispatch, getState) => {
    const isEvergreenEnabled = isEvergreenPromotionEnabled(getState());
    const couponCode = Cookies.get('coupon_code') || (isEvergreenEnabled && __EVERGREEN_PROMO_CODE__);
    const { zip } = getState().address;

    if (!couponCode) {
      return Promise.resolve();
    }

    const oldCouponCode = Cookies.get('old_coupon_code');
    const isFreshCookie = couponCode !== oldCouponCode;
    const { lastFetchedCoupon } = getState().plans;

    // Don't refetch coupons unless coupon code changes
    if (lastFetchedCoupon === couponCode) {
      return Promise.resolve();
    }
    Cookies.set('old_coupon_code', couponCode, { expires: 14 });
    dispatch(startRequest(key));
    dispatch(setLastCouponFetched(couponCode));

    const optimizelyExperiments =
      window.optimizely &&
      typeof window.optimizely.get === 'function' &&
      window.optimizely.get('state').getExperimentStates();
    const pricingExperiement =
      optimizelyExperiments &&
      Object.values(optimizelyExperiments).find((exp) => exp.experimentName === PRICING_AB_TEST_NAME);

    return fetchCouponApi(couponCode, {
      zip,
      optimizely_variation_id: pricingExperiement?.variation?.id
    })
      .then((response) => {
        if (response.type === 'coupon' && isFreshCookie) {
          const promoDiscount = response.discount.amount.dollars;
          const promoCode = response.code;
          performTrack({
            event: TRACK_EVENTS.couponApplied,
            properties: { promo_code: promoCode, promo_discount: promoDiscount }
          });
        }
        dispatch(setCoupons([response]));
        dispatch(completeRequest(key));
        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Coupon fetch failed';
        if (!response) {
          dispatch(showError(key, defaultErrorText));
          return Promise.reject(defaultErrorText);
        }
        if (response.status === 404) {
          dispatch(showError(key, defaultErrorText));
          return Promise.resolve();
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }
        dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

        return Promise.reject(parseError(response));
      });
  };
};

/*
 * There are two coupon scenarios:
 * - the user is visiting registration after redeeming a coupon, and the
 *   coupon hasn't been applied, but is visible in the cookie
 * - the user HAS the coupon applied and is coming back to registration
 *
 * The first case is easy. POST the coupon code and we're all set.
 * Second scenario requires us to fetch the collection to fetch the coupon.
 */
export const applyCoupons = () => {
  const key = 'applyCoupons';

  return (dispatch, getState) => {
    const coupon = Cookies.get('coupon_code');
    if (!coupon) {
      return Promise.resolve();
    }

    dispatch(startRequest(key));

    const appliedCoupon = getState().user.coupon;
    if (appliedCoupon) {
      dispatch(completeRequest(key));
      return Promise.resolve();
    }

    return applyCouponsApi(coupon)
      .then(() => {
        dispatch(completeRequest(key));
        dispatch(fetchCoupons());
        return Promise.resolve();
      })
      .catch((response) => {
        const defaultErrorText = 'Applying coupons failed';
        if (!response) {
          dispatch(showError(key, defaultErrorText));
          return Promise.reject(defaultErrorText);
        }
        if (response.status >= 500) {
          dispatch(showError(key, defaultErrorText));
        }
        dispatch(failRequest({ key, statusCode: response.status, errors: response.errors }));

        return Promise.reject(parseError(response));
      });
  };
};
