import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const InputHelpTip = ({ className, ...restProps }) => {
  return <span {...restProps} className={cx('pom-Input-tooltip', className)} />;
};

InputHelpTip.propTypes = {
  className: PropTypes.string
};

InputHelpTip.defaultProps = {
  className: ''
};

export default InputHelpTip;
