// @flow

const initialState = {};

export const REQUEST_STARTED = 'REQUEST_STARTED';
export const REQUEST_COMPLETED = 'REQUEST_COMPLETED';

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_STARTED: {
      return {
        ...state,
        [action.payload.key]: {
          status: 'pending'
        }
      };
    }
    case REQUEST_COMPLETED: {
      const { key } = action.payload;
      if (action.error) {
        const payload = { ...action.payload };
        delete payload.key;
        return {
          ...state,
          [key]: {
            status: 'failed',
            ...payload
          }
        };
      }

      return {
        ...state,
        [key]: {
          status: 'completed'
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const startRequest = (key) => {
  return {
    type: REQUEST_STARTED,
    payload: { key }
  };
};

export const completeRequest = (key) => {
  return {
    type: REQUEST_COMPLETED,
    payload: { key }
  };
};

export const failRequest = ({ key, errors: responseErrors, statusCode }) => {
  let errors = responseErrors;
  if (typeof errors !== 'object') {
    errors = { errors: responseErrors };
  }
  return {
    type: REQUEST_COMPLETED,
    payload: new Error({ key, statusCode, ...errors }),
    error: true
  };
};
