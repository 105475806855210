import gql from 'graphql-tag';

export const CREDIT_CARD_FIELDS = gql`
  fragment CreditCardFields on CreditCard {
    displayType
    expirationMonth
    expirationYear
    lastDigits
  }
`;
