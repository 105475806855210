import { SET_PLANS, SET_DEFAULT_PLAN, SET_PLANS_LAST_FETCHED_PARAMS, SET_LAST_FETCHED_COUPON } from './plans-actions';

export const initialState = {
  plansById: {},
  defaultPlan: {
    id: null,
    quantity: null
  },
  plansLastFetchedParams: {
    lastFetchedZip: null,
    lastFetchedCoupon: null
  },
  lastFetchedCoupon: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANS: {
      return {
        ...state,
        plansById: action.payload
      };
    }
    case SET_DEFAULT_PLAN: {
      const { id } = action.payload;
      const plan = Object.values(state.plansById || {}).find((p) => p.id === id);
      const quantity = `${plan.defaultProductsPerOrder}`;

      return {
        ...state,
        defaultPlan: {
          id,
          quantity
        }
      };
    }
    case SET_PLANS_LAST_FETCHED_PARAMS: {
      return {
        ...state,
        plansLastFetchedParams: action.payload
      };
    }
    case SET_LAST_FETCHED_COUPON: {
      return {
        ...state,
        lastFetchedCoupon: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
