import React, { useState, useRef, useEffect } from 'react';
import { string, bool, func, number, object, oneOfType, arrayOf } from 'prop-types';
import cx from 'classnames';

import InputError from './InputError';
import InputConditions from './InputConditions';
import InputHelpTipWithTooltip from '../InputHelpTip/InputHelpTipWithTooltip';

const Input = ({
  autoComplete,
  name,
  label,
  hideErrors,
  showErrors: propShowErrors,
  showErrorText,
  errorMessage,
  placeholder,
  disabled,
  maxLength,
  validator,
  onChange,
  onBlur,
  onFocus,
  renderHelpTip,
  value: propValue,
  htmlType,
  optional,
  changeable,
  styles,
  hideOptionalLabel,
  setFocusOnRender,
  showPasswordEnabled,
  showValidationConditions,
  wrapperStyles,
  validateOnChange,
  validateOnReturnKey
}) => {
  const inputEl = useRef(null);
  const [valid, setValid] = useState(true);
  const [value, setValue] = useState(propValue);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [showErrors, setShowErrors] = useState(propShowErrors);
  const helpTipId = `input-helpTip-for-${name}`;

  const validate = (valueToValidate, triggerChange = true) => {
    const anyConditionsInvalid =
      Array.isArray(validator) && validator.some((singleValidation) => !singleValidation(valueToValidate).valid);
    const { valid: valueIsValid, message } = !Array.isArray(validator)
      ? validator(valueToValidate)
      : { valid: !anyConditionsInvalid };

    setValue(valueToValidate);
    setValid(valueIsValid);
    setError(message || '');
    setShowErrors(!valueIsValid);

    if (triggerChange) {
      onChange({
        valid: valueIsValid,
        value: valueToValidate,
        error: message || '',
        showErrors: !valueIsValid,
        name,
        optional
      });
    }
  };

  const handleChange = (event) => {
    const newValue = event ? event.target.value : value;
    setValue(newValue);
    if (validateOnChange) {
      validate(newValue);
    } else if (
      validateOnReturnKey &&
      event &&
      !(event.nativeEvent?.inputType && event.nativeEvent?.inputType === 'insertText')
    ) {
      validate(newValue);
    } else {
      onChange({ valid, value: newValue, error, showErrors, name, optional });
    }
  };

  const handleAutofillOnInput = (event) => {
    if (!isSelected) {
      const newValue = event ? event.target.value : value;
      validate(newValue);
    }
  };

  const handleFocus = () => {
    setShowErrors(false);
    setIsSelected(true);
    onFocus();
  };

  const handleBlur = (event) => {
    setIsSelected(false);
    if (!validateOnChange) {
      const { target: { value: newValue } = {} } = event || {};
      validate(newValue);
    }
    onBlur();
  };

  const renderErrors = () => {
    const showErrorMessage = !hideErrors && (showErrorText || showErrors);
    const errorText = errorMessage || error;

    if (showErrorMessage && errorText) {
      return <InputError message={errorText} />;
    }

    return null;
  };

  const renderConditions = (valueToValidate) => {
    if (!showValidationConditions || !Array.isArray(validator)) {
      return null;
    }
    return (
      <InputConditions
        showErrors={showErrors && valueToValidate.length > 0}
        validator={validator}
        value={valueToValidate}
      />
    );
  };

  const renderShowHide = () => {
    if (!showPasswordEnabled || !value) {
      return null;
    }
    return (
      <button onClick={() => setShowPassword(!showPassword)} type="button" className="pom-ToggleShowPassword">
        {showPassword ? 'Hide' : 'Show'}
      </button>
    );
  };

  const renderOptionalLabel = () => {
    if (!optional || hideOptionalLabel) {
      return null;
    }

    return <span className="pom-Input__optional-tag">(optional)</span>;
  };

  useEffect(() => {
    handleChange(null);
    if (setFocusOnRender) inputEl.current.focus();
  }, []);

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.addEventListener('change', handleChange);
    }

    return () => {
      if (inputEl && inputEl.current) {
        inputEl.current.removeEventListener('change', handleChange);
      }
    };
  }, [inputEl]);

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (propValue !== value) {
      setValue(propValue);
      setValid(true);
      setError('');
      setShowErrors(propValue ? propShowErrors : '');
    } else if (propShowErrors && !propValue.length) {
      validate(value, false);
    }
  }, [propValue]);

  useEffect(() => {
    if (propShowErrors && !propValue.length) {
      validate(value, false);
    }
  }, [propShowErrors]);

  if (!changeable) {
    return <p className="pom-Input__unchangeable">{value}</p>;
  }

  return (
    <>
      <label
        className={cx('pom-Input', {
          'pom-Input--error': (!valid || errorMessage) && showErrors,
          'pom-Input--disabled': disabled,
          'pom-Input--border-transparent': showPasswordEnabled
        })}
        htmlFor={name}
        style={wrapperStyles}
      >
        {label}
        {renderOptionalLabel()}
        {!renderHelpTip || (
          <InputHelpTipWithTooltip className="pom-Input-helpTip" id={helpTipId} renderTooltip={renderHelpTip} />
        )}
        <div
          className={cx('pom-Input-fieldWrapper', {
            'pom-Input-fieldWrapper--border-transparent': !showPasswordEnabled,
            'pom-Input-fieldWrapper--focus': isSelected,
            'pom-Input-fieldWrapper--error': (!valid || errorMessage) && showErrors
          })}
        >
          <input
            ref={inputEl}
            style={styles}
            autoComplete={autoComplete}
            id={name}
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            onInput={handleAutofillOnInput}
            onBlur={handleBlur}
            onFocus={handleFocus}
            disabled={disabled}
            maxLength={maxLength}
            value={value}
            type={htmlType === 'password' && showPassword ? 'text' : htmlType}
          />
          {renderShowHide()}
        </div>
        {renderErrors()}
      </label>
      {renderConditions(value)}
    </>
  );
};

Input.propTypes = {
  autoComplete: string,
  name: string.isRequired,
  label: string,
  hideErrors: bool,
  showErrors: bool,
  showErrorText: bool,
  errorMessage: string,
  placeholder: string,
  disabled: bool,
  maxLength: number,
  validator: oneOfType([func, arrayOf(func)]),
  onChange: func,
  onBlur: func,
  onFocus: func,
  renderHelpTip: func,
  value: string,
  htmlType: string,
  optional: bool,
  changeable: bool,
  styles: object, // eslint-disable-line
  hideOptionalLabel: bool,
  wrapperStyles: object, // eslint-disable-line
  setFocusOnRender: bool,
  showValidationConditions: bool,
  showPasswordEnabled: bool,
  validateOnChange: bool,
  validateOnReturnKey: bool
};

Input.defaultProps = {
  autoComplete: '',
  label: '',
  hideErrors: undefined,
  showErrors: false,
  showErrorText: false,
  errorMessage: null,
  placeholder: '',
  disabled: false,
  maxLength: undefined,
  validator: () => ({ valid: true }),
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  renderHelpTip: null,
  value: '',
  htmlType: null,
  optional: false,
  changeable: true,
  styles: {},
  hideOptionalLabel: false,
  wrapperStyles: {},
  setFocusOnRender: false,
  showValidationConditions: false,
  showPasswordEnabled: false,
  validateOnChange: false,
  validateOnReturnKey: false
};

export default Input;
