import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CloseIcon from './CloseIcon';

const CloseButton = ({ buttonStyles, fill, isRounded, height, onClose, styles, width }) => (
  <button
    type="button"
    aria-label="Close"
    onClick={onClose}
    className={cx('pom-closeButton', {
      'pom-closeButton--rounded': isRounded
    })}
    style={buttonStyles}
  >
    <CloseIcon fill={fill} height={height} width={width} styles={styles} />
  </button>
);

CloseButton.propTypes = {
  buttonStyles: PropTypes.shape({}),
  fill: PropTypes.string,
  isRounded: PropTypes.bool,
  height: PropTypes.string,
  onClose: PropTypes.func,
  styles: PropTypes.shape({}),
  width: PropTypes.string
};

CloseButton.defaultProps = {
  buttonStyles: null,
  fill: undefined,
  isRounded: false,
  height: '19',
  onClose() {},
  styles: null,
  width: '19'
};

export default CloseButton;
