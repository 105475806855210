import getAppConfiguration from 'services/api/graphql/appConfiguration/get-app-configuration';
import { updateHeaders } from 'shared/utils/graphql-helpers';

import { setAppConfiguration } from './actions';

// eslint-disable-next-line import/prefer-default-export
export const fetchAppConfiguration = () => (dispatch) => {
  return getAppConfiguration()
    .then((appConfiguration) => {
      dispatch(setAppConfiguration(appConfiguration));

      updateHeaders(
        appConfiguration.httpHeaders.reduce((acc, header) => ({ ...acc, [header.name]: header.value }), {})
      );
    })
    .catch((error) => {
      console.error(error);
    });
};
