import moment from 'moment';

import fetch from 'services/api/windows';
import { failRequest } from 'redux/reducers/requests';
import {
  getFormattedWeekDay,
  getSelectedWindow,
  getFormattedWindows,
  getFirstWindowForWeek,
  getWindowByState
} from 'services/windows';
import parseError from 'shared/utils/error-handling/parse-errors';

export const SET_WINDOWS = 'SET_WINDOWS';
export const SET_SELECTED_WINDOW = 'SET_SELECTED_WINDOW';
export const SET_WINDOWS_LAST_FETCHED_PARAMS = 'SET_WINDOWS_LAST_FETCHED_PARAMS';
export const SET_FIRST_DELIVERY_WINDOW = 'SET_FIRST_DELIVERY_WINDOW';
export const SET_SELECTED_WINDOW_AND_DELIVERY_WINDOW = 'SET_SELECTED_WINDOW_AND_DELIVERY_WINDOW';
export const SET_FIRST_SELECTED_WINDOW_FOR_WEEK = 'SET_FIRST_SELECTED_WINDOW_FOR_WEEK';
export const SET_AVAILABLE_WINDOW_BY_STATE = 'SET_AVAILABLE_WINDOW_BY_STATE';
export const SET_WINDOW_FOR_NEXT_WEEK = 'SET_WINDOW_FOR_NEXT_WEEK';

const initialState = {
  available: [],
  default: null,
  selectedWindow: {},
  hasUpdatedDeliveryWindow: null,
  windowsLastFetchedParams: {
    lastFetchedZip: null,
    lastFetchedPlanId: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_WINDOWS: {
      return {
        ...state,
        available: getFormattedWindows(action.payload.windows),
        default: action.payload.defaultWindow,
        selectedWindow: {}
      };
    }
    case SET_SELECTED_WINDOW: {
      const { id, firstDeliveryWindowId } = getSelectedWindow(action.payload, state);

      return {
        ...state,
        selectedWindow: {
          id,
          firstDeliveryWindowId
        }
      };
    }
    case SET_WINDOWS_LAST_FETCHED_PARAMS: {
      return {
        ...state,
        windowsLastFetchedParams: action.payload
      };
    }
    case SET_FIRST_DELIVERY_WINDOW: {
      return {
        ...state,
        selectedWindow: {
          ...state.selectedWindow,
          firstDeliveryWindowId: action.payload
        }
      };
    }
    case SET_SELECTED_WINDOW_AND_DELIVERY_WINDOW: {
      const { id, firstDeliveryWindowId } = action.payload;

      return {
        ...state,
        hasUpdatedDeliveryWindow: true,
        selectedWindow: {
          ...state.selectedWindow,
          id,
          firstDeliveryWindowId
        }
      };
    }
    case SET_FIRST_SELECTED_WINDOW_FOR_WEEK: {
      const { id, firstDeliveryWindowId } = getFirstWindowForWeek(action.payload.week, state);

      return {
        ...state,
        selectedWindow: {
          id,
          firstDeliveryWindowId
        }
      };
    }
    case SET_AVAILABLE_WINDOW_BY_STATE: {
      const { id, firstDeliveryWindowId } = getWindowByState(state, action.payload.state);

      return {
        ...state,
        selectedWindow: {
          id,
          firstDeliveryWindowId
        }
      };
    }
    case SET_WINDOW_FOR_NEXT_WEEK: {
      const currentWindow = state.available.find(({ id }) => {
        return id === state.selectedWindow.id;
      });

      if (!currentWindow) return state;

      const currentDeliveryWindow = currentWindow.delivery_windows.find(({ id }) => {
        return id === state.selectedWindow.firstDeliveryWindowId;
      });

      if (!currentDeliveryWindow) return state;

      const nextWeekDeliveryWindow = state.available
        .reduce((acc, curr) => [...acc, ...curr.delivery_windows], [])
        .find((deliveryWindow) => {
          return (
            moment.utc(currentDeliveryWindow.arrival_date).week() + 1 === moment.utc(deliveryWindow.arrival_date).week()
          );
        });

      return {
        ...state,
        selectedWindow: {
          ...state.selectedWindow,
          id: nextWeekDeliveryWindow.window_id,
          firstDeliveryWindowId: nextWeekDeliveryWindow.id
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const setWindows = ({ windows, defaultWindow }) => {
  return {
    type: SET_WINDOWS,
    payload: {
      windows,
      defaultWindow
    }
  };
};

export const setSelectedWindow = (selectedWindow, event) => {
  return {
    type: SET_SELECTED_WINDOW,
    payload: selectedWindow,
    meta: { event }
  };
};

export const setFirstDeliveryWindow = (deliveryWindowId, event) => {
  return {
    type: SET_FIRST_DELIVERY_WINDOW,
    payload: deliveryWindowId,
    meta: { event }
  };
};

export const setWindowsLastFetchedParams = (lastFetchedZip, lastFetchedPlanId) => {
  return {
    type: SET_WINDOWS_LAST_FETCHED_PARAMS,
    payload: { lastFetchedZip, lastFetchedPlanId }
  };
};

export const setSelectedWindowAndDeliveryWindow = (id, firstDeliveryWindowId, event) => {
  return {
    type: SET_SELECTED_WINDOW_AND_DELIVERY_WINDOW,
    payload: { id, firstDeliveryWindowId },
    meta: { event }
  };
};

export const setFirstSelectedWindowForWeek = (week) => {
  return {
    type: SET_FIRST_SELECTED_WINDOW_FOR_WEEK,
    payload: { week }
  };
};

export const setWindowForNextWeek = () => {
  return {
    type: SET_WINDOW_FOR_NEXT_WEEK
  };
};

/*
  Selectors
*/
const getDeliveryWindowId = (state) => {
  if (!state.windows && !state.windows.selectedWindow) {
    return null;
  }
  return state.windows.selectedWindow.id;
};

export const getDeliveryWindowWeekDay = (state) => {
  if (!getDeliveryWindowId(state)) {
    return null;
  }
  const id = getDeliveryWindowId(state);
  const weekday = state.windows.available.find((days) => {
    return days.id === id;
  });
  return getFormattedWeekDay(weekday.day_of_week.toString());
};

export const getDefaultDeliveryWindowDate = (state) => {
  const selectedWindow = state.windows.available.find(({ id }) => id === state.windows.default);
  if (selectedWindow) {
    return selectedWindow.delivery_windows[0].arrival_date;
  }

  return undefined;
};

export const getDeliveryWindowDate = (state, windowId, deliveryWindowId) => {
  if (!windowId || !deliveryWindowId) {
    return getDefaultDeliveryWindowDate(state);
  }

  const selectedWindow = state.windows.available.find(({ id }) => id === windowId);
  if (selectedWindow) {
    const selectedDeliveryWindow = selectedWindow.delivery_windows.find(({ id }) => id === deliveryWindowId);
    return selectedDeliveryWindow.arrival_date;
  }

  return undefined;
};

export const getSelectedWindowSelector = (state) => {
  const windowId = state.windows.selectedWindow && state.windows.selectedWindow.id;
  const deliveryWindowId = state.windows.selectedWindow && state.windows.selectedWindow.firstDeliveryWindowId;

  if (!windowId || !deliveryWindowId) {
    return undefined;
  }

  const selectedWindow = state.windows.available.find(({ id }) => id === windowId);
  const firstDeliveryWindow = selectedWindow.delivery_windows.find(({ id }) => id === deliveryWindowId);

  return {
    ...selectedWindow,
    firstDeliveryWindow
  };
};

export const getSelectedWineWindowSelector = (state) => {
  const windowId = state.windows.selectedWindow && state.windows.selectedWindow.id;

  const selectedWindow = state.windows.available.find(({ id }) => id === windowId);

  return selectedWindow;
};

export const getAllDeliveryWindowsWithinTheSameWeek = (state, windowId, deliveryWindowId) => {
  const selectedWindow = state.windows.available.find(({ id }) => id === windowId);
  if (!selectedWindow) return [];
  const selectedDeliveryWindow =
    selectedWindow && selectedWindow.delivery_windows.find(({ id }) => id === deliveryWindowId);
  if (!selectedDeliveryWindow) return [];

  const startOfWeek = moment.utc(selectedDeliveryWindow.arrival_date).startOf('week');
  const endOfWeek = moment.utc(selectedDeliveryWindow.arrival_date).endOf('week');
  const deliveryWindows = state.windows.available.reduce((acc, curr) => {
    const isInWeek = curr.delivery_windows.find(({ arrival_date }) => {
      const arrivalDateMoment = moment.utc(arrival_date);
      return startOfWeek.isBefore(arrivalDateMoment) && endOfWeek.isAfter(arrivalDateMoment);
    });

    if (isInWeek) {
      return [...acc, isInWeek];
    }

    return acc;
  }, []);

  return deliveryWindows;
};

/*
  Fetch
*/

export const fetchWindows = ({ setDefaultWindow = true } = {}) => {
  return (dispatch, getState) => {
    const { session } = getState();
    const planId = getState().user.plan.id;
    const { zip } = getState().address;
    const { lastFetchedZip, lastFetchedPlanId } = getState().windows.windowsLastFetchedParams;

    // Don't refetch windows unless zip or planId change
    if (!zip || (lastFetchedZip === zip && lastFetchedPlanId === planId)) {
      return Promise.resolve();
    }

    dispatch(setWindowsLastFetchedParams(zip, planId));

    return fetch(zip, planId, session)
      .then((response) => {
        dispatch(setWindows(response));

        if (setDefaultWindow) {
          dispatch(setSelectedWindow(response.defaultWindow));
        }

        return Promise.resolve();
      })
      .catch((response) => {
        if (!response) {
          return Promise.reject('Windows fetch failed');
        }
        dispatch(failRequest({ key: 'fetchWindows', statusCode: response.status, errors: response.errors }));

        return Promise.reject(parseError(response));
      });
  };
};
