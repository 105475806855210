import { createSelector } from 'reselect';
import moment from 'moment';

import { FOOD, WINE, MEAL, ORDER_STATUS, SUBSCRIPTION_STATUS } from 'shared/constants/constants';
import camelize from 'shared/utils/camelize';

import { packagesByIdSelector } from 'redux/reducers/packages/packages-selectors';
import { hasFeature, isUserInVariation } from 'redux/reducers/configurations';

const CREDIT_TYPES = [MEAL, WINE];
const SUBSCRIPTION_TYPE_BY_CREDIT_TYPE = {
  meal: FOOD,
  wine: WINE
};

const rootSelector = (state) => state;

export const getUserState = createSelector(rootSelector, (state) => state.user);

export const hasAnyPastOrder = createSelector(
  getUserState,
  ({ has_past_order, has_marketplace_order }) => has_past_order || has_marketplace_order
);

export const getAccountInfo = createSelector(getUserState, (user) => ({
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  receiveNewsletter: user.receive_newsletter
}));

export const getAccountInfoData = createSelector(
  getAccountInfo,
  ({ firstName, lastName, email, receiveNewsletter } = {}) => [
    { label: 'First name', type: 'text', name: 'firstName', value: firstName },
    { label: 'Last name', type: 'text', name: 'lastName', value: lastName },
    { label: 'Name', type: '', name: 'fullName', value: `${firstName} ${lastName}` },
    { label: 'Email', type: 'email', name: 'email', value: email },
    { label: 'Password', type: 'password', name: 'password', value: '', valueNode: '********' },
    {
      label: 'Receive announcements',
      type: 'password',
      name: 'receiveAnnouncements',
      value: receiveNewsletter,
      valueNode: receiveNewsletter ? 'Yes' : 'No'
    }
  ]
);

export const getSubscriptions = createSelector(
  getUserState,
  ({ subscriptions } = {}) => subscriptions && subscriptions.sort((a, b) => a.plan.type.localeCompare(b.plan.type))
);

export const getServingSize = createSelector(getUserState, ({ serving_size }) => serving_size);

export const getMealSubscription = createSelector(
  getSubscriptions,
  (subscriptions) => subscriptions && subscriptions.find((subscr) => subscr.plan.type === FOOD)
);

export const getWineSubscription = createSelector(
  getSubscriptions,
  (subscriptions) => subscriptions && subscriptions.find((subscr) => subscr.plan.type === WINE)
);

export const isMealSubscriptionInactive = createSelector(getMealSubscription, (subscription) => {
  return !subscription || subscription.status === SUBSCRIPTION_STATUS.CANCELED;
});

export const isWineSubscriptionInactive = createSelector(
  getWineSubscription,
  (subscription) => !subscription || subscription.status === SUBSCRIPTION_STATUS.CANCELED
);

export const isUserInWineRegistration = createSelector(getUserState, ({ isWineRegistration }) => isWineRegistration);

export const getMealPlan = createSelector(getMealSubscription, (subscription) => {
  return subscription && subscription.plan;
});

const getWinePlan = createSelector(getWineSubscription, (subscription) => {
  return subscription && subscription.plan;
});

export const getUserPlan = createSelector(
  getUserState,
  isUserInWineRegistration,
  getWineSubscription,
  ({ plan }, isWineRegistration, wineSubscription) => {
    if (isWineRegistration) {
      return (
        wineSubscription && {
          id: String(wineSubscription.plan.id),
          quantity: String(wineSubscription.products_per_delivery)
        }
      );
    }
    return plan;
  }
);

export const getUserPlanQuantity = createSelector(getUserPlan, (plan) => {
  return plan ? plan.quantity : undefined;
});

// subscribed to both at some point.
export const isMealsAndWine = createSelector(
  getMealPlan,
  getWinePlan,
  (mealPlan, winePlan) => !!(mealPlan && winePlan)
);

export const getSubscriptionSettings = createSelector(
  getWineSubscription,
  getMealSubscription,
  (wineSubscription = {}, mealSubscription = {}) => ({
    wineSubscriptionIsActive: wineSubscription.is_active,
    mealSubscriptionIsActive: mealSubscription.is_active,
    mealAndWineSubscriptionsActive: wineSubscription.is_active && mealSubscription.is_active,
    wineSubscriptionActiveOnly: wineSubscription.is_active && !mealSubscription.is_active,
    mealSubscriptionActiveOnly: mealSubscription.is_active && !wineSubscription.is_active,
    aLaCarteOnly: !(wineSubscription.is_active || mealSubscription.type === 'recurring')
  })
);

export const isWineSubscriptionCancellable = createSelector(
  getWineSubscription,
  (wineSubscription = {}) => wineSubscription.is_active && wineSubscription.type === 'recurring' && wineSubscription
);

export const isMealsSubscriptionCancellable = createSelector(
  getMealSubscription,
  (mealsSubscription = {}) =>
    mealsSubscription.is_active &&
    (mealsSubscription.type === 'recurring' || mealsSubscription.type === 'a_la_carte') &&
    mealsSubscription
);

export const getCancellationSettings = createSelector(
  isWineSubscriptionCancellable,
  isMealsSubscriptionCancellable,
  getUserState,
  (wine, meals, { id, hashed_ids }) => {
    const subscriptions = {
      ...(wine && {
        wine: {
          id: wine.id,
          conversionId: wine.conversion_id,
          pauseEndsOn: wine.pause_ends_on || null
        }
      }),
      ...(meals && {
        meals: {
          id: meals.id,
          conversionId: meals.conversion_id,
          pauseEndsOn: meals.pause_ends_on || null
        }
      })
    };

    const isOnlySubscription =
      Object.values(subscriptions).filter((subscription) => Boolean(subscription.id)).length === 1;

    return {
      isAccountCancellable: Boolean(wine && meals),
      isOnlySubscription,
      subscriptions,
      user: {
        userId: id,
        userIdSha256: (hashed_ids || {}).id_256
      }
    };
  }
);

export const isMealPlanActive = createSelector(getMealPlan, (mealPlan) => mealPlan && mealPlan.is_active);
export const isWinePlanActive = createSelector(getWinePlan, (winePlan) => winePlan && winePlan.is_active);

export const isMealsOnly = createSelector(
  isMealPlanActive,
  isWinePlanActive,
  isMealsAndWine,
  (isMealActive, isWineActive, isMealAndWine) => !!(isMealAndWine ? isMealActive && !isWineActive : isMealActive)
);

export const isMealsSubscriptionIncomplete = createSelector(
  getMealSubscription,
  (mealSubscription) => mealSubscription && mealSubscription.status === 'incomplete'
);

export const isWineSubscriptionIncomplete = createSelector(
  getWineSubscription,
  (wineSubscription) => wineSubscription && wineSubscription.status === 'incomplete'
);

export const isMealsSubscriptionActive = createSelector(
  getSubscriptionSettings,
  ({ mealSubscriptionIsActive }) => mealSubscriptionIsActive
);

export const isWineOnly = createSelector(
  isMealPlanActive,
  isWinePlanActive,
  isMealsAndWine,
  isMealsSubscriptionIncomplete,
  (isMealActive, isWineActive, isMealAndWine, isMealsIncomplete) =>
    !!(isMealAndWine ? (!isMealActive || isMealsIncomplete) && isWineActive : isWineActive)
);

export const isMealsAndWineSubscriptionsActive = createSelector(
  getSubscriptionSettings,
  ({ mealAndWineSubscriptionsActive }) => mealAndWineSubscriptionsActive
);

export const isMealsOnlyActive = createSelector(
  getSubscriptionSettings,
  ({ mealSubscriptionActiveOnly }) => mealSubscriptionActiveOnly
);

export const isUserALaCarteOnly = createSelector(getSubscriptionSettings, ({ aLaCarteOnly }) => aLaCarteOnly);

const pickAddressFields = ({ address } = {}) => {
  if (!address) {
    return null;
  }
  const { id, delivery_name, address_line_1, address_line_2, city, state, zip, phone, instructions } = address;

  return {
    id,
    deliveryName: delivery_name,
    address: {
      line1: address_line_1,
      line2: address_line_2
    },
    city,
    state,
    zip,
    phone,
    instructions
  };
};

const getAddressForMeals = createSelector(getMealSubscription, pickAddressFields);
const getAddressForWines = createSelector(getWineSubscription, pickAddressFields);

export const getAddressInfo = createSelector(
  getAddressForMeals,
  getAddressForWines,
  getSubscriptionSettings,
  (mealAddress, wineAddress, { wineSubscriptionActiveOnly, mealSubscriptionActiveOnly }) => ({
    isAddressShared:
      !wineSubscriptionActiveOnly && Boolean(mealAddress && wineAddress && mealAddress.id === wineAddress.id),
    mealsOnly: !!mealSubscriptionActiveOnly,
    wineOnly: !!wineSubscriptionActiveOnly,
    mealAddress,
    wineAddress
  })
);

export const isUserCardless = createSelector(
  getUserState,
  (user) => !user.payment_info || !user.payment_info.payment_method_exists
);

export const userNeedsPaymentUpdate = createSelector(
  getUserState,
  (user) => user.payment_info && user.payment_info.needs_update
);

export const userPaymentInfoExists = createSelector(
  getUserState,
  (user) => user.payment_info && user.payment_info.payment_method_exists
);

export const getWellness = createSelector(getUserState, (user) => user.wellness);
export const getUserDietaryProgram = createSelector(getUserState, (user) => user.dietaryProgram);

export const isUserStrictlyVegetarian = createSelector(getUserState, (user) => user.is_strictly_vegetarian);

export const getPaymentInfo = createSelector(getUserState, (user) => ({
  nameOnCard: user.card_name,
  cardType: user.card_type,
  cardNumber: `Ended in ${user.card_last_four}`,
  expiration: `${user.card_exp_month}/${user.card_exp_year}`,
  isGooglePay: user.stripe_tokenization_method === 'android_pay',
  isApplePay: user.stripe_tokenization_method === 'apple_pay'
}));

export const getInactiveSubscription = createSelector(
  getSubscriptions,
  (subscriptions) => subscriptions && subscriptions.find((subscription) => !subscription.is_active)
);

export const getConversionAndSubscriptionIDs = createSelector(getInactiveSubscription, (inactiveSubscription) => ({
  conversionId: inactiveSubscription && inactiveSubscription.conversion_id,
  subscriptionId: inactiveSubscription && inactiveSubscription.id
}));

export const getReactivatedSubscriptionMetrics = createSelector(
  getUserState,
  getInactiveSubscription,
  getConversionAndSubscriptionIDs,
  (user, inactiveSubscription, conversionAndSubscriptionIDs) => ({
    category: inactiveSubscription && inactiveSubscription.plan.type === WINE ? 'Wine Plan' : 'Meal Plan',
    userId: user.id,
    userIdSha256: user.hashed_ids && user.hashed_ids.id_256,
    ...conversionAndSubscriptionIDs
  })
);

export const isUserLegalForWine = createSelector(getUserState, ({ is_legal_age_for_wine }) => is_legal_age_for_wine);
export const getUserId = createSelector(getUserState, (user) => user.id);
export const getUserEmail = createSelector(getUserState, (user) => user?.email);
export const isUserPaused = createSelector(getUserState, (user) => user && user.paused);
export const isUserDefunct = createSelector(getUserState, (user) => user && user.is_defunct);
export const getUserResumeWeek = createSelector(getUserState, (user) => user && user.resume_week);
export const getCredits = createSelector(getUserState, (user) => user.credits);
export const getCreditBalance = createSelector(rootSelector, getUserState, (state, user) => {
  const isTheseusCreditUIEnabled = hasFeature(state, 'theseus_credit_ui_enabled');
  if (isTheseusCreditUIEnabled) {
    return user.creditBalance;
  }
  return null;
});
export const getUserFirstName = createSelector(getUserState, (user) => user?.first_name);
export const isUserAuthenticated = createSelector(getUserState, (user) => !!user?.id && !user?.is_canceled);
export const isUserActive = createSelector(
  getUserState,
  isUserAuthenticated,
  (user, isAuthed) => isAuthed && user.is_active
);

export const isUserCancelled = createSelector(getUserState, ({ is_canceled: isCancelled } = {}) => isCancelled);
export const isUserPartial = createSelector(
  isUserActive,
  isUserCancelled,
  (isActive, isCancelled) => !isActive && !isCancelled
);

export const getSubscriptionId = createSelector(getUserState, ({ meal_plan: mealPlan, wine_plan: winePlan } = {}) => ({
  subscriptionId: (mealPlan && mealPlan.id) || (winePlan && winePlan.id)
}));

export const getUserData = createSelector(
  getUserState,
  getAccountInfo,
  isUserPartial,
  isUserCancelled,
  getSubscriptionId,
  (
    { id: userId, hashed_ids: hashedIds, coupon = '' } = {},
    { firstName, lastName, email },
    isPartial,
    isCancelled,
    { subscriptionId }
  ) => {
    let result = {};
    if (hashedIds) {
      const { id, id256, email: emailSha1, email256 } = camelize(hashedIds);
      result = {
        ...result,
        userIdSha1: id,
        userIdSha256: id256,
        emailSha1,
        emailSha256: email256
      };
    }
    if (userId) {
      result = {
        ...result,
        userId,
        firstName,
        lastName,
        email,
        isPartial,
        isCancelled,
        coupon,
        subscriptionId
      };
    }
    return { user: result };
  }
);

// Expiring Credits Selectors
export const isExpiringCreditsModalTreatmentOn = createSelector(rootSelector, (state) =>
  isUserInVariation(state, 'expiring_credits_modal_treatment')
);
export const getUserExpiringCredits = createSelector(getUserState, ({ expiringCredits }) => expiringCredits);
export const getUserEmployee = createSelector(getUserState, ({ employee }) => employee);
export const getCreditsAmounts = createSelector(getUserExpiringCredits, (expiringCredits = {}) =>
  Object.entries(expiringCredits).reduce(
    (acc, [subscription, value]) => {
      const creditsBalance = parseFloat(value);
      return { [`${subscription}CreditsBalance`]: creditsBalance, creditsAmount: acc.creditsAmount + creditsBalance };
    },
    { creditsAmount: 0 }
  )
);
export const getExpiringCreditsData = createSelector(
  getCreditsAmounts,
  getUserEmployee,
  isExpiringCreditsModalTreatmentOn,
  (creditsAmounts, isEmployee, expiringCreditsModalTreatment) => ({
    ...creditsAmounts,
    isModalEligible: isEmployee || expiringCreditsModalTreatment
  })
);

export const getNumberOfMealsPerDelivery = createSelector(
  getMealSubscription,
  (subscription) => subscription && subscription.products_per_delivery
);

export const getPlansSummary = createSelector(
  isMealsAndWine,
  getNumberOfMealsPerDelivery,
  getMealSubscription,
  getWineSubscription,
  getAccountInfo,
  (hasWinePlan, mealsPerDelivery, mealSubscription, wineSubscription, { firstName }) => ({
    firstName,
    hasWinePlan,
    mealsPerDelivery,
    planName: mealSubscription.plan.display_name,
    nextMealArrival: mealSubscription.next_order_arrival,
    dayEstimate: mealSubscription.window.day_estimate,
    winesPerDelivery: wineSubscription && wineSubscription.products_per_delivery,
    nextWineArrival: wineSubscription && wineSubscription.next_order_arrival,
    mealPlanId: mealSubscription.plan_id
  })
);

const hasActiveSubscription = (subscriptions, planType) => {
  const subscription = subscriptions.find((subscr) => subscr.plan.type === planType);
  return !!subscription && subscription.is_active;
};

const getShowAdditionalCreditsBoolean = (packagesById, planType, amount) => {
  if (packagesById) {
    const totalPackageCredits = Object.values(packagesById).reduce((acc, pkg) => {
      if (pkg.planType !== planType) return acc;
      if (pkg.status !== ORDER_STATUS.SCHEDULED) return acc;

      return acc + Number(pkg.prices.discount);
    }, 0);

    return parseFloat(amount, 10) - totalPackageCredits > 0;
  }
  return false;
};

const getItemizedCreditsFromPackages = (packagesById, planType) =>
  Object.values(packagesById).reduce((acc, pkg) => {
    if (pkg.planType !== planType) return acc;
    if (pkg.status !== ORDER_STATUS.SCHEDULED) return acc;
    if (parseInt(pkg.prices.discount, 10) === 0) return acc;

    return [
      ...acc,
      {
        date: moment.utc(pkg.arrival.time),
        price: pkg.prices.discount
      }
    ];
  }, []);

export const getEligibleCredits = createSelector(
  getCredits,
  getSubscriptions,
  packagesByIdSelector,
  (credits, subscriptions, packagesById) =>
    !credits || !credits.total || !subscriptions
      ? []
      : CREDIT_TYPES.reduce((acc, creditType) => {
          if (
            credits[creditType] &&
            parseFloat(credits[creditType], 10) > 0 &&
            hasActiveSubscription(subscriptions, SUBSCRIPTION_TYPE_BY_CREDIT_TYPE[creditType])
          ) {
            const expiringList = (credits.expiring && credits.expiring[creditType]) || [];
            return [
              ...acc,
              {
                type: creditType,
                total: credits[creditType],
                showAdditionalCreditsMessage: getShowAdditionalCreditsBoolean(
                  packagesById,
                  SUBSCRIPTION_TYPE_BY_CREDIT_TYPE[creditType],
                  credits[creditType]
                ),
                list: getItemizedCreditsFromPackages(packagesById, SUBSCRIPTION_TYPE_BY_CREDIT_TYPE[creditType]),
                expiring: expiringList.map((expiringItem) => ({
                  ...expiringItem,
                  date: moment.utc(expiringItem.date).endOf('day')
                }))
              }
            ];
          }
          return acc;
        }, [])
);

export const getActualTotalCredits = createSelector(getCredits, getSubscriptions, (credits, subscriptions) =>
  !credits || !credits.total || !subscriptions
    ? 0
    : CREDIT_TYPES.reduce((acc, creditType) => {
        if (
          credits[creditType] &&
          !hasActiveSubscription(subscriptions, SUBSCRIPTION_TYPE_BY_CREDIT_TYPE[creditType])
        ) {
          return acc - parseFloat(credits[creditType], 10);
        }
        return acc;
      }, parseFloat(credits.total, 10))
);
