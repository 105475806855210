import * as Cookie from 'js-cookie';

const ID_STORAGE_KEY = 'cart_id';
const TOKEN_STORAGE_KEY = 'cart_token';

export const getCartFromCookies = (context) => {
  const cartId = Cookie.get(`${context}_${ID_STORAGE_KEY}`);
  const cartToken = Cookie.get(`${context}_${TOKEN_STORAGE_KEY}`);

  return { id: cartId, token: cartToken };
};

export const setCartInCookies = (context, cartId, cartToken) => {
  Cookie.set(`${context}_${ID_STORAGE_KEY}`, cartId);
  Cookie.set(`${context}_${TOKEN_STORAGE_KEY}`, cartToken);
};

export const deleteCartCookies = (context) => {
  Cookie.remove(`${context}_${ID_STORAGE_KEY}`);
  Cookie.remove(`${context}_${TOKEN_STORAGE_KEY}`);
};
