import Cookies from 'js-cookie';
import { createSelector } from 'reselect';
import getParam from 'shared/utils/get-param/get-param';

const rootSelector = (state) => state;

const getConfigurationsState = createSelector(rootSelector, (state) => state.configurations);

export const getRegions = createSelector(getConfigurationsState, (configurations) => configurations.regions);

export const getPriceDisclaimerData = createSelector(getConfigurationsState, ({ price_disclaimer } = {}) => ({
  effective_date: price_disclaimer && price_disclaimer.show ? price_disclaimer.effective_date : null
}));

export const user = (state) => state.user;

export const hasFeature = (state, features, includes = 'every') => {
  const requestedFeatures = Array.isArray(features) ? features : [features];
  const appFeatures = (state.configurations && state.configurations.features) || [];

  return includes === 'every'
    ? requestedFeatures.every((feature) => appFeatures.includes(feature))
    : requestedFeatures.some((feature) => appFeatures.includes(feature));
};

export const showPlanFlexEdu = (state) => {
  if (!state.user.has_past_order) {
    Cookies.set('show_plan_flex_edu', false);
  }

  return Cookies.getJSON('show_plan_flex_edu') !== false;
};

export const showEditModeTooltip = () => {
  return Cookies.getJSON('show_3ds_tip');
};

export const quickViewVisibility = (state) => {
  return hasFeature(state, ['cwr-quickview-visibility-hidden-button']);
};

export const isCrossmenuEnabledForWeb = (state) => {
  return hasFeature(state, ['is_cross_menu_ordering_enabled']);
};

export const isEvergreenPromotionEnabled = (state) => {
  return hasFeature(state, ['evergreen_promotion_enabled']);
};

// TODO: If changes to this flow are made, fix the method to use Modules.HostOrigin
// Ideally we will no longer reference app source outside of using that service
// Changes were not made right now because out of scope for current project
// and did not want to introduce bugs
export const isMobile = () => {
  return getParam('mobile') === 'true' || Cookies.getJSON('mobile_view') || window.innerWidth < 962;
};

export const isUserInVariation = (state, variation) => {
  const { services = {} } = state.configurations;
  const siteTestVariations = services.site_test_variations || [];
  const stvIds = siteTestVariations.map((stv) => stv.variation_id);

  return (stvIds || []).includes(variation.toString());
};
