import isString from 'lodash/isString';

// returns capitalized string
export default function capitalize(s) {
  if (!isString(s)) {
    return '';
  }

  return s[0].toUpperCase() + s.substr(1).toLowerCase();
}
