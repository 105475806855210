import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const selectShippingRatesForAgoraCart = (cartId, selectedShippingRates, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client.mutate({
    mutation: gql`
      mutation SelectShippingRatesForMarketCart($input: SelectShippingRatesForCartInput!) {
        selectShippingRatesForCart(input: $input) {
          cart {
            ...MarketCartFields
          }
          clientErrors {
            message
          }
        }
      }
      ${MARKET_CART_FIELDS}
    `,
    variables: {
      input: {
        cartId,
        selectedShippingRates,
        ownerId
      }
    }
  })
  .then((result) => {
    const { data: { selectShippingRatesForCart } } = result;
    const { clientErrors, cart } = selectShippingRatesForCart;
    return { cart, clientErrors };
  });
  /* eslint-enable prettier/prettier */
};

export default selectShippingRatesForAgoraCart;
