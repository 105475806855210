import { cssTransition, toast } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import React from 'react';
import cx from 'classnames';
import Icon from 'components/common/icons/Icon';

export const defaultMessage = 'Recipe changes saved';

const SlideFade = cssTransition({
  enter: 'pom-fade-in-top',
  exit: 'pom-fade-out-top'
});

const CheckPath = () => {
  return (
    <g transform="translate(1, -1.5)">
      <path
        d="m1.5 7.8l2.6 2.8 5.5-8"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#fff"
        strokeWidth="2.3"
        fill="none"
      />
    </g>
  );
};

const SuccessIcon = () => {
  return (
    <div className={cx('pom-SuccessIcon__ellipse')}>
      <Icon stroke="#fff" height="20" width="20" containerClassNames="pom-SuccessIcon__check" viewBox="0 0 13 9">
        <CheckPath />
      </Icon>
    </div>
  );
};

const BASE_OPTIONS = {
  position: 'top-center',
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
  theme: 'colored',
  closeButton: false,
  className: 'pom-Toast',
  transition: SlideFade
};

const setToast = (message, type = 'success') => {
  injectStyle();
  const toastMessage = message ?? defaultMessage;

  const notify = () => {
    if (type === 'warn') {
      toast.warn(toastMessage, { ...BASE_OPTIONS });
    } else {
      toast.success(toastMessage, { ...BASE_OPTIONS, icon: SuccessIcon });
    }
  };
  return notify();
};

export default setToast;
