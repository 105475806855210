import gql from 'graphql-tag';

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    id
    deliveryName {
      full
    }
    city
    line1
    line2
    state
    zip
    phoneNumber
  }
`;
