const logEvent = (actionEventMap) => (store) => (next) => (action) => {
  const previousState = store.getState();
  const nextState = next(action);

  if (actionEventMap[action.type] && typeof actionEventMap[action.type] === 'function') {
    actionEventMap[action.type](previousState, nextState, action);
  }

  return nextState;
};

export default logEvent;
