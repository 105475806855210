import { createSelector } from 'reselect';

const rootSelector = (state) => state;

export const getUpcomingState = createSelector(rootSelector, (state) => state.upcoming);

export const getQuickViewProductId = createSelector(getUpcomingState, ({ quickViewProductId }) => quickViewProductId);

export const getProductDetails = createSelector(
  getUpcomingState,
  ({ cartProductDetailsById }) => cartProductDetailsById
);
