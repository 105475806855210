import fetchGraphQlProduct from 'services/api/graphql/product/get-product';
import { addProductDetails } from 'redux/reducers/upcoming';

export const loadVariantDetails = (product) => {
  return async (dispatch) => {
    const result = await fetchGraphQlProduct(product.sku);
    dispatch(
      addProductDetails({
        [result.sku]: result
      })
    );
  };
};
