import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { MARKET_CART_FIELDS } from 'services/api/graphql/fragments/market-cart-fields';

// eslint-disable-next-line import/prefer-default-export
const setGiftInfoForAgoraCart = (cartId, senderName, recipientName, recipientEmail, message, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client.mutate({
    mutation: gql`
      mutation SetGiftInfoForMarketCart($input: SetGiftInfoForCartInput!) {
        setGiftInfoForCart(input: $input) {
          cart {
            ...MarketCartFields
          }
          clientErrors {
            message
          }
        }
      }
      ${MARKET_CART_FIELDS}
    `,
    variables: {
      input: {
        cartId,
        giftInfo: {
          senderName,
          recipientName,
          recipientEmail,
          message
        },
        ownerId
      }
    }
  })
  .then((result) => {
    const { data: { setGiftInfoForCart } } = result;
    const { clientErrors, cart } = setGiftInfoForCart;
    return { cart, clientErrors };
  });
  /* eslint-enable prettier/prettier */
};

export default setGiftInfoForAgoraCart;
