import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';
import { ORDER_PRICE_FIELDS } from 'services/api/graphql/fragments/order-price-fields';

// eslint-disable-next-line import/prefer-default-export
const finalizeAgoraCart = (cartId, complianceInfo, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client.mutate({
    mutation: gql`
      mutation FinalizeMarketCart($input: FinalizeCartInput!) {
        finalizeCart(input: $input) {
          order {
            id
            guestToken
            price {
              ...OrderPriceFields
            }
            lineItems {
              quantity
              variant {
                displayPrice {
                  formattedPrice
                }
                displayPriority
                name {
                  full
                }
                sku
                variableValues {
                  displayValue
                  value
                  variable {
                    description
                    displayName
                    name
                    type
                  }
                }
              }
            }
          }
          clientErrors {
            message
          }
        }
      }
      ${ORDER_PRICE_FIELDS}
    `,
    variables: {
      input: {
        cartId,
        complianceInfo,
        ownerId
      }
    }
  })
  .then((result) => {
    const { data: { finalizeCart } } = result;
    const { clientErrors, order } = finalizeCart;
    return { order, clientErrors };
  });
  /* eslint-enable prettier/prettier */
};

export default finalizeAgoraCart;
