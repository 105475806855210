export const PLAN_IDS = {
  TWO_PERSON: 1,
  FAMILY_PLAN: 3,
  WINE: 4,
  WW: 5
};

export const PLAN_QUANTITIES = {
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5
};

export const AVAILABLE_PLANS_MENU_MAP = {
  two_person: '2-serving menu',
  family: '4-serving menu'
};

export const PLAN_SERVINGS = {
  two_person: 2,
  family: 4
};

export default { PLAN_IDS, PLAN_QUANTITIES };
