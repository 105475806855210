import size from 'lodash/size';
import isArray from 'lodash/isArray';
import head from 'lodash/head';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';

const parseError = (res) => {
  const response = {};
  let json;
  try {
    if (res.responseText && !isEmpty(res.responseText)) {
      json = JSON.parse(res.responseText);
    } else {
      json = res;
    }
  } catch (e) {
    json = res;
  }

  const errors = json.errors || json.error;
  const { warnings } = json;
  let message;
  let key;

  if (!size(errors)) {
    message = json.message ? json.message : 'Something has gone wrong...';
  } else if (errors.message) {
    // edge case, returned from Stripe API
    message = errors.message;
  } else if (isArray(errors)) {
    message = head(errors);
  } else {
    key = head(Object.keys(errors));
    const errorValue = errors[key];
    message = Array.isArray(errorValue) ? head(errorValue) : errorValue;
  }

  response.key = key;
  response.message = message;
  response.warnings = [];
  response.status = res.status;

  if (warnings) {
    each(warnings, (value, k) => {
      response.warnings.push(k);
    });
  }

  return response;
};

export default parseError;
