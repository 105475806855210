import { SET_COUPONS } from './coupons-actions';

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COUPONS: {
      return [...action.payload];
    }
    default: {
      return state;
    }
  }
};
