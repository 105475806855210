// @flow

import { fetch, apiPost } from 'services/fetch';

const apiVersion = 'v20161006';

export const fetchCoupons = (session, queryParams) => {
  return fetch('/api/users/coupons', session, apiVersion, queryParams).then((response) => response.coupons);
};

export const applyCoupons = (data) => {
  return apiPost(
    '/api/users/coupons',
    {
      code: data
    },
    apiVersion
  );
};

export const fetchCoupon = (couponCode, queryParams) => {
  return fetch(`/api/coupons/${couponCode}`, null, apiVersion, queryParams).then((response) => response.coupon);
};

export default { fetchCoupons, applyCoupons, fetchCoupon };
