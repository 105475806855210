import rollbarMiddleware from 'rollbar-redux-middleware';
import { config } from 'shared/constants/rollbarConfig';
import rollbar from 'rollbar';

const Rollbar = rollbar.init(config);

// Include any key paths we want to exclude from sending to rollbar
const keyPaths = [
  'address',
  'session',
  'subscription.food.address',
  'subscription.wine.address',
  'user.addresses',
  'user.card_country',
  'user.card_exp_month',
  'user.card_last_four',
  'user.card_name',
  'user.card_type',
  'user.display_name',
  'user.email',
  'user.family_name',
  'user.first_name',
  'user.given_name',
  'user.last_name',
  'user.subscriptions',
  'user.payment_info',
  'user.stripe_tokenization_method',
  'user.password'
];

export const rollbarRedux = rollbarMiddleware(Rollbar, keyPaths, true);

export default rollbarRedux;
