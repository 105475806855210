import { WINDOWS_BY_DAY_OF_WEEK } from 'shared/constants/windows';

import without from 'lodash/without';
import sortBy from 'lodash/sortBy';

import moment from 'moment';
import stateToSkipDaysOfWeekMap from './stateToSkipDaysOfWeekMap';

export const getFormattedWeekDay = (weekday) => {
  if (!weekday) return null;
  const convertWeekDay = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  };
  return convertWeekDay[weekday];
};

export const getDeliveryWindowsForDropdown = (deliveryWindows) => {
  // create new array because `sort` is not immutable method
  return [...deliveryWindows]
    .sort((a, b) => {
      return moment.utc(a.arrival_date).diff(moment.utc(b.arrival_date));
    })
    .reduce((acc, deliveryWindow) => {
      const delivery = moment(deliveryWindow.arrival_date).utc().format('dddd, MMM D');

      acc.push({
        label: delivery,
        value: deliveryWindow.id,
        window_id: deliveryWindow.window_id
      });

      return acc;
    }, []);
};

const getFormattedDeliveryWindows = (deliveryWindows) => {
  return deliveryWindows.map((deliveryWindow) => {
    return {
      arrival_date: deliveryWindow.arrival_date,
      id: deliveryWindow.id,
      window_id: deliveryWindow.window_id
    };
  });
};

export const getFormattedWindows = (windows) => {
  return windows.map((win) => {
    return {
      day_of_week: win.day_of_week,
      delivery_windows: getFormattedDeliveryWindows(win.delivery_windows),
      id: win.id
    };
  });
};

export const getSelectedWindow = (windowId, windows) => {
  const selectedWindow = windows.available.find(({ id }) => id === windowId);
  if (!selectedWindow) return {};
  const deliveryWindows = selectedWindow.delivery_windows;
  // When the default window id has no delivery windows set the earliest available delivery window as default
  if (!deliveryWindows.length) {
    const sortedDeliveryDates = getDeliveryWindowsForDropdown(
      windows.available.reduce((acc, available) => [...acc, ...available.delivery_windows], [])
    );
    return {
      id: sortedDeliveryDates && sortedDeliveryDates[0] && sortedDeliveryDates[0].window_id,
      firstDeliveryWindowId: sortedDeliveryDates && sortedDeliveryDates[0] && sortedDeliveryDates[0].value
    };
  }
  return {
    id: windowId,
    firstDeliveryWindowId: deliveryWindows && deliveryWindows[0] && deliveryWindows[0].id
  };
};

export const getWindowsForDropdown = (windows) => {
  return windows.reduce((acc, win) => {
    acc.push({
      label: `${WINDOWS_BY_DAY_OF_WEEK[win.day_of_week]}s`,
      value: win.id
    });

    return acc;
  }, []);
};

export const getFirstWindowForWeek = (week, windows) => {
  const selectedDeliveryWindow = windows.available
    .reduce((acc, curr) => [...acc, ...curr.delivery_windows], [])
    .find(({ arrival_date }) => moment.utc(arrival_date).week() === week);

  if (selectedDeliveryWindow) {
    return {
      id: selectedDeliveryWindow.window_id,
      firstDeliveryWindowId: selectedDeliveryWindow.id
    };
  }

  return {
    id: undefined,
    firstDeliveryWindowId: undefined
  };
};

const getFirstAvailableWindow = (windows) => {
  const availableWindow = windows.find((window) => {
    return window && window.delivery_windows && window.delivery_windows.length;
  });

  return availableWindow && availableWindow.delivery_windows[0];
};

/*
  getWindowByState
  iterates through the non-skippable days of week and finds first available.
  if no available windows found, iterates though skippable days of week and finds first available.
  if nothing was found, returns empty window.
 */
export const getWindowByState = (windows, selectedState) => {
  const daysToSkip = stateToSkipDaysOfWeekMap[selectedState] || [];
  const daysOfWeek = [1, 2, 3, 4, 5, 6, 0];
  const availableDaysOfWeek = without(daysOfWeek, ...daysToSkip);

  // sort available windows. Order goes from Monday to Sunday
  const windowsSortedByDayOfWeek = sortBy(windows.available, (window) => window.day_of_week || 7);
  const preferredWindows = windowsSortedByDayOfWeek.filter(({ day_of_week }) =>
    availableDaysOfWeek.includes(day_of_week)
  );

  let selectedDeliveryWindow = getFirstAvailableWindow(preferredWindows);

  if (!selectedDeliveryWindow) {
    const skippableWindows = without(windowsSortedByDayOfWeek, ...preferredWindows);

    selectedDeliveryWindow = getFirstAvailableWindow(skippableWindows);
  }

  if (selectedDeliveryWindow) {
    return {
      id: selectedDeliveryWindow.window_id,
      firstDeliveryWindowId: selectedDeliveryWindow.id
    };
  }

  return {
    id: undefined,
    firstDeliveryWindowId: undefined
  };
};

export default {
  getDeliveryWindowsForDropdown,
  getFormattedWindows,
  getSelectedWindow,
  getWindowsForDropdown
};
