import { gql } from '@apollo/client';
import { client } from 'shared/utils/graphql-helpers';

// eslint-disable-next-line import/prefer-default-export
const fetchActiveAgoraCarts = (cartContext, ownerId) => {
  /* eslint-disable prettier/prettier */
  return client
    .query({
      query: gql`
        query ActiveCarts($cartContext: CartContext!, $ownerId: CartOwnerIdInput) {
          activeCarts(cartContext: $cartContext, ownerId: $ownerId) {
            id
            checkoutState
            state
            guestToken
            price {
              discount {
                displayValue
              }
              estimated
              shipping {
                displayValue
              }
              subtotal {
                displayValue
              }
              tax {
                displayValue
              }
              total {
                displayValue
              }
            }
            lineItems {
              quantity
              variant {
                core
                description
                displayPrice {
                  formattedPrice
                }
                displayPriority
                name {
                  full
                }
                price {
                  original {
                    displayValue
                  }
                }
                sku
                variableValues {
                  displayValue
                  value
                  variable {
                    description
                    displayName
                    name
                    type
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        cartContext,
        ownerId
      },
      fetchPolicy: 'no-cache'
    })
    .then((result) => {
      return result.data.activeCarts;
    });
  /* eslint-enable prettier/prettier */
};

export default fetchActiveAgoraCarts;
