// PACKAGE, SUBSCRIPTION, PLAN, PRODUCT and ORDER types
export const FOOD = 'food';
export const MEAL = 'meal';
export const MEALS = 'meals';
export const WINE = 'wine';
export const WINES = 'wines';
export const RECIPE = 'recipe';
export const BOTH_PLANS = 'both';
export const MEAL_AND_WINE = 'meal_and_wine';
export const REACTIVATE_OPTIONS = [MEAL, WINE, BOTH_PLANS];
export const MEAL_KITS = 'Meal Kits';
export const MARKETPLACE = 'marketplace';

// MENU SET SLUGS
export const MENU_SET_WINE = 'wine';
export const MENU_SET_MEALS = 'meals';
export const MENU_SET_ADDONS = 'add_ons';

// MENU IDs
export const MENU_MEAL_PREP = 'meal_prep';

// PRODUCT types
export const PRODUCT_TYPE_RECIPE = 'recipes';
export const PRODUCT_TYPE_WINE = 'wines';
export const PRODUCT_TYPE_PRODUCT = 'products';

// PLAN names
// Note: This should be used instead of plan IDs.
export const TWO_SERVING_API_PLAN_NAME = '2-Person';
export const FOUR_SERVING_API_PLAN_NAME = 'Family';
export const WINE_API_PLAN_NAME = 'Wine';
export const PREPARED_AND_READY_API_PLAN_NAME = 'Prepped and Ready';

export const ACCOUNT = 'account';
export const PLAN = 'plan';
export const FAMILY = 'family';
export const PREPARED_AND_READY_PLAN = 'prepped_and_ready';
export const TWO_PERSON = 'two_person';
export const PREPARED_AND_READY_PRICING_PATH = '/prepared-and-ready';
export const MEAL_KIT_PRICING_PATH = '/meal-kits';
export const MEAL_KIT_DESCRIPTION = 'Ingredients and easy-to-follow recipes for home-cooked meals';
export const PREPARED_AND_READY_DESCRIPTION =
  'Pre-made meals delivered fresh and ready in as little as 2 minutes. Starting at just $9.99.';
export const CROSS_MENU_PREPARED_AND_READY_DESCRIPTION =
  'Pre-made meals delivered fresh and done in as little as 2 minutes.';

export const CANCELLATION_TYPE = {
  [ACCOUNT]: {
    name: 'account',
    page: 'Cancel Your Account Page'
  },
  [PLAN]: {
    name: 'plan',
    page: 'Cancellation Page'
  }
};

export const ORDER_STATUS = {
  SCHEDULED: 'scheduled',
  SHIPPED: 'shipped',
  HOLD: 'hold',
  DONATED: 'donated',
  DONATING: 'donating',
  REFUNDED: 'refunded',
  BACKORDERED: 'backordered',
  SOLD_OUT: 'sold_out',
  FAILED: 'failed',
  SUSPENDED: 'suspended',
  PREPARING: 'preparing',
  PAUSED: 'paused',
  CANCELED: 'canceled',
  PROCESSING: 'onHand',
  RETURNED: 'returned'
};

export const ORDER_STATUS_GRAPHQL = {
  CANCELED: 'Canceled',
  PENDING: 'Pending',
  PUBLISHED: 'Published',
  READY: 'Ready',
  SHIPPED: 'Shipped'
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  PAUSED: 'paused',
  CANCELED: 'canceled'
};

export const POST_CUTOFF_STATUSES = [ORDER_STATUS.PREPARING, ORDER_STATUS.SHIPPED];
export const SKIPPED_STATUSES = [
  ORDER_STATUS.SUSPENDED,
  ORDER_STATUS.REFUNDED,
  ORDER_STATUS.PAUSED,
  ORDER_STATUS.DONATED,
  ORDER_STATUS.DONATING
];

export const SETTINGS_LINK =
  "<a href='/account/settings' data-event-category='Delivery Schedule'>Resume deliveries!</a>";

export const WINE_SIGNATURE_REQUIRED = 'Adult signature (21 years or older) is required for wine delivery.';
export const WINE_RECOMMEND_BUSINESS_ADDRESS =
  'We recommend shipping to your business address to ensure successful delivery.';
export const INVALID_WINE_SHIPPING_ADDRESS_MESSAGE =
  'Your state has certain restrictions inhibiting us from completing this wine delivery.';

export const STATES = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

export const SUCCESS_MESSAGE = {
  ADDRESS: 'Delivery address changed',
  SKIP: 'Delivery skipped',
  RESCHEDULE: 'Delivery rescheduled',
  WINE_CHANGE: 'Wine changes saved'
};

export const LINK_FACEBOOK_PROFILES_URL = '/api/users/facebook_connect/link';
export const DEFAULT_FACEBOOK_LINKING_ERROR = 'Error on Facebook account linking';

// Uses Webpack's DefinePlugin
export const FACEBOOK_ID = 'FACEBOOK_APP_ID';

export const FACEBOOK_CONNECT_SUCCESS = {
  action: 'Log In With Facebook Button Click',
  category: 'Log In'
};

export const FACEBOOK_CONNECT = {
  category: 'Account Settings - Social Settings',
  action: 'FB Connect Click',
  exclusionList: ['GA']
};

export const COMMON_API_ERROR_MESSAGE = 'Oops, something went wrong. Click here to try again.';
export const COMMON_ERROR_MESSAGE = 'Oops, something went wrong.';

export const modalStyles = {
  overlay: {
    position: 'fixed',
    zIndex: 2000,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    margin: '0 auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'relative',
    borderRadius: '0',
    overflow: 'visible',
    padding: 0,
    border: 0,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, .1)'
  }
};

export const MODAL_KEYS = {
  CLEAR_SELECTIONS: 'CLEAR_SELECTIONS',
  GENERATE_BOX: 'GENERATE_BOX',
  PRICE_CHANGE: 'PRICE_CHANGE',
  RECIPE_CUSTOMIZATION: 'RECIPE_CUSTOMIZATION'
};

export const RECIPE_CUSTOMIZATION_SOURCES = {
  UPCOMING: 'UPCOMING',
  CURRENT: 'CURRENT',
  PRODUCT_SELECTION: 'PRODUCT_SELECTION',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL'
};

export const ORGANIC_COUPON_CODE_FOR_SIGN_UP_REDIRECT = '130off011723';

export const PROMO_BANNER_EXPERIMENT = {
  ORIGINAL: 'DEPT Test 17: Increase Promo Code Banner Visibility Original',
  VARIATION: 'DEPT Test 17: Increase Promo Code Banner Visibility Variation #1'
};

// Marketplace
export const MARKET_CONTEXT = 'MARKET';
export const FOOD_CART_CONTEXT = 'FOOD';
export const GIFT_CART_CONTEXT = 'GIFT_CARD';

export const CHECKOUT_ERROR_MSG = 'There was an error processing your order. Please try again.';
export const FETCH_CART_ERROR_MSG = 'There was an error retrieving your cart. Please refresh the page.';
export const INITIATE_CART_ERROR_MSG = 'There was an error beginning checkout process. Please try again.';
export const PROMO_CODE_ERROR_MSG = (promoCode) => `There was an error applying the promo code: ${promoCode}`;
export const SHIPPING_RATE_ERROR_MSG = 'There was an error selecting your shipping rate. Please try again.';
export const UPDATE_SHIPPING_ERROR_MSG = 'There was an error updating your shipping information. Please try again';
// export const INVALID_ZIP_CODE_ERROR_MSG = "Your card's zip code is invalid.";
export const AVAILABILITY_LEVELS = {
  available: 'AVAILABLE',
  discontinued: 'DISCONTINUED',
  backorderable: 'BACKORDERABLE',
  unavailable: 'UNAVAILABLE'
};

export const SHIPPING_RATE_TYPES = {
  SHIPPING: 'SHIPPING',
  COURIER: 'COURIER'
};

export const TESTS_ENABLED = {
  ZENDESK_LOGGED_OUT_ENABLED: 'live_chat_logged_out'
};

export const FREE_SHIP_CODES = ['2024-fs-621-t', '2024-fs-hp-621-t', '2024-fs-621-rt', '2024-fs-621-ect'];
