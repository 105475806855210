import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icons/Icon';

const CloseIcon = ({ classNames, fill, height, styles, viewBox, width }) => (
  <Icon height={height} viewBox={viewBox} width={width} svgClassNames={classNames} containerStyles={styles}>
    <path
      d="M27.4462425,11.3320323 C28.0673703,10.8490762 28.9654861,10.8929813 29.5362524,11.4637476 C30.1545825,12.0820777 30.1545825,13.0845889 29.5362524,13.7029191 L29.5362524,13.7029191 L22.739,20.5 L29.5362524,27.2970809 C30.1545825,27.9154111 30.1545825,28.9179223 29.5362524,29.5362524 C28.9654861,30.1070187 28.0673703,30.1509238 27.4462425,29.6679677 L27.2970809,29.5362524 L20.5,22.739 L13.7029191,29.5362524 L13.5537575,29.6679677 C12.9326297,30.1509238 12.0345139,30.1070187 11.4637476,29.5362524 C10.8454175,28.9179223 10.8454175,27.9154111 11.4637476,27.2970809 L11.4637476,27.2970809 L18.261,20.5 L11.4637476,13.7029191 C10.8454175,13.0845889 10.8454175,12.0820777 11.4637476,11.4637476 C12.0345139,10.8929813 12.9326297,10.8490762 13.5537575,11.3320323 L13.7029191,11.4637476 L20.5,18.261 L27.2970809,11.4637476 Z"
      transform="translate(-11 -11)"
      fill={fill}
    />
  </Icon>
);

CloseIcon.propTypes = {
  classNames: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.shape({}),
  viewBox: PropTypes.string,
  width: PropTypes.string
};

CloseIcon.defaultProps = {
  classNames: '',
  fill: '#737373',
  height: '19',
  styles: null,
  viewBox: '0 0 19 19',
  width: '19'
};
export default CloseIcon;
